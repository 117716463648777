import { Button, Message, Spacer } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useMutation } from "react-query";

import { Modal, ModalProps } from "../../../components";
import { useTranslationContext, useTransportContext } from "../../../hooks";
import { coreClient } from "../../../utils";
import { CoreViolation } from "@brenger/api-client";
import { getIdFromIri } from "@brenger/utils";

export const ConfirmPickupModal: React.FC<ModalProps> = (props) => {
  const { t } = useTranslationContext();
  const ctx = useTransportContext();

  const onConfirmSuccess = (): void => {
    // Refreshing the TR ensures that the overall status transitions on the main page.
    ctx.refresh();
    props.closeHandler();
  };
  const pickupId = getIdFromIri(ctx.pickup);
  const confirmPickup = useMutation(coreClient.pickups.confirmByCustomer, { onSuccess: onConfirmSuccess });
  const confirmPickupError = (confirmPickup.error as undefined | CoreViolation)?.message;
  return (
    <Modal {...props}>
      <h3>{t((d) => d.confirm_pickup_title)}</h3>
      <Spacer h={4} />
      <div>{t((d) => d.confirm_stop_desc)}</div>
      {confirmPickupError && (
        <Message type="error" className={cn("mt-2")}>
          {confirmPickupError}
        </Message>
      )}
      <Spacer h={4} />
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4")}>
        <Button buttonType="primary-outline" onClick={props.closeHandler}>
          {t((d) => d.no)}
        </Button>
        <Button
          loading={confirmPickup.isLoading}
          buttonType="secondary"
          onClick={() => pickupId && confirmPickup.mutate(pickupId)}
        >
          {t((d) => d.yes)}
        </Button>
      </div>
    </Modal>
  );
};
