import {
  FoParty,
  FoPartyIdParams,
  FoSelectService,
  FoServiceFields,
  FoServiceLevel,
  SourceFlow,
} from "@brenger/api-client";
import { useDebounce } from "@brenger/react";
import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { CacheKey, foClient } from "../../utils";
import { GenerateMpRoute } from "../routes";
import { useDraftTr } from "./useDraftTr";
import { FoPublicAvailableService, useExtraServiceOptions } from "./useExtraServiceOptions";
import { trackAndTag, trackPurchase } from "../../utils/eventTracking";
import { signUpToNewsletter } from "../Pages/Feasible/FeasiblePayment";

interface UseExtraService {
  /**
   * FIX ME: Partial service field comes from not knowing what defaults we save
   */
  selected?: Partial<FoServiceFields>;
  setService(args: Partial<FoServiceFields>): void;
  options: FoPublicAvailableService[];
  /**
   * Only on initial
   */
  isLoading: boolean;
  /**
   * Everytime resources are fetched
   */
  isFetching: boolean;
  /**
   * When select mutation is loading
   */
  isSubmitting: boolean;
  /**
   * Newsletter option
   */
  optIn: boolean;
  setOptIn(args: boolean): void;
  /**
   * The way we show a price diff and check if there is a redirect
   */
  outcome?: FoSelectService;
  scheduleComplete(): void;
}

export const S_KEY_SELECT_SERVICE_OUTCOME = "BRENGER_SELECT_SERVICE_OUTCOME";

export const useExtraService = ({ partyId }: FoPartyIdParams): UseExtraService => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [optIn, setOptIn] = React.useState(false);
  // Get the available options in
  const { options, isLoading: optionsIsLoading, isFetching: optionsIsFetching } = useExtraServiceOptions({ partyId });
  /**
   * Two main operations
   * - fetch prev selected service
   * - setup mutation to select service
   */
  const draftTr = useDraftTr({ partyId });
  const selectedService = draftTr.data?.selected_service;

  const select = useMutation([CacheKey.FO_SELECT_SERVICE, partyId], foClient.marktplaats.selectService, {
    /**
     * When the user refreshes in during the flow we loose query state
     * All selected services and date selects are synced to server
     * But, payment redirect and old/new price wil be lost, sess storage to the rescue
     */
    onSuccess: (data) => sessionStorage.setItem(S_KEY_SELECT_SERVICE_OUTCOME, JSON.stringify(data)),
  });

  /**
   * This is state only exists so selecting is direct, but we can debounce the value to the API
   */
  const [service, setService] = React.useState<Partial<FoServiceFields> | null>(null);
  /**
   * We debounce the service a little to prevent rapid / rage floor selection
   */
  const debouncedService = useDebounce(JSON.stringify(service), 250);

  /**
   * Act on debounced service value => set defaults and mutate
   */
  React.useEffect(() => {
    const s = JSON.parse(debouncedService) as FoServiceFields | null;
    if (!s) return;
    select.reset();
    select.mutate({
      partyId,
      ...s,
    });
  }, [debouncedService]);

  const storedOutcome = sessionStorage.getItem(S_KEY_SELECT_SERVICE_OUTCOME);
  const outcome = storedOutcome ? (JSON.parse(storedOutcome) as FoSelectService) : select.data;

  /**
   * Both complete and scheduleComplete will only be triggered when there is no payment redirect
   * - complete = taking care of moving forawrds when no payment is needed
   * - scheduleComplete = gets called on the summary page of schedule_buyer flow
   */
  const complete = useMutation(
    [CacheKey.FO_TRIGGER_COMPLETE, "schedule_buyer", partyId],
    foClient.marktplaats.schedulingComplete,
    {
      onSuccess: () =>
        history.push(
          GenerateMpRoute({
            id: partyId,
            page: { type: "thank_you", state: "SCHEDULE_SELLER" },
          })
        ),
    }
  );
  const scheduleComplete = async (): Promise<void> => {
    // Set submitting flag to show a loader
    setIsSubmitting(true);
    /**
     * Newsletter signup
     */
    if (optIn) {
      await signUpToNewsletter(partyId, "buyer");
    }
    /**
     * Outcome only exists if there was a mutation
     * if there is no outcome, it basically means that the user didn't change the service,
     * which also means there is no price to pay because everything is default
     * So we can call the schedule complete endpoint directly
     */
    if (outcome) {
      const s = {
        service_level_name: outcome?.service_level_name as FoServiceLevel,
        floor_level: outcome?.floor_level as number,
        elevator: outcome?.elevator as boolean,
      };
      trackAndTag({ event: "FO Interaction", type: "Service selected", data: s.service_level_name, partyId });
      /**
       * Short cut alert: we resubmit so we make sure that the payment link is fresh
       */
      select.reset();
      select.mutate(
        {
          partyId,
          ...s,
        },
        {
          onSuccess: (data) => {
            trackPurchase({
              // We use party id + state, to only track one purchase per session
              id: `SCHEDULE_BUYER_${partyId}`,
              type: "upsell",
              amount: outcome.price.value,
              flowType: SourceFlow.MARKTPLAATS,
              partyId,
            });

            if (!data.redirect_url) {
              complete.mutate({ party: draftTr.data?.party as FoParty, partyId });
              return;
            }
            window.location.assign(data.redirect_url);
          },
        }
      );
      return;
    }
    // No outcome means no service selected, so the default service is selected
    trackAndTag({ event: "FO Interaction", type: "Service selected", data: "farfetchd", partyId });
    complete.mutate({ party: draftTr.data?.party as FoParty, partyId });
  };

  return {
    selected: { ...(selectedService || {}), ...(outcome || {}), ...service },
    setService,
    options,
    isLoading: optionsIsLoading,
    isFetching: optionsIsFetching,
    isSubmitting: select.isLoading || isSubmitting,
    outcome,
    scheduleComplete,
    optIn,
    setOptIn,
  };
};
