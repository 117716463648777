import cn from "classnames";
import { Link } from "react-router-dom";

import { Notification } from "../../components";
import { useTranslationContext, useTransportContext, useTransportParams } from "../../hooks";
import { createAccessControl } from "../../utils";

// Note re canSeeNotification: this should align with perms for canEditContactDetails in UpdateStopDetailsCTA component.
const getPerms = createAccessControl({
  pickup: {
    canSeeNotification: [1, 2, 3],
  },
  delivery: {
    canSeeNotification: [1, 2, 3, 4],
  },
  customer: {
    canSeeNotification: [1, 2, 3, 4],
  },
});

export const NotificationContactDetails: React.FC = () => {
  const { t } = useTranslationContext();
  const params = useTransportParams();
  const { contactType, status, tr } = useTransportContext();

  const { perms } = getPerms(contactType, status);

  // NOTE: In addition to perms (see above) must also check current contact type against
  // the particular contact details that were skipped. This notification may not be relevant
  // for all contact types.
  let isNotificationRelevantToContactType = false;

  if (tr?.internal_attributes.contact_pickup_skipped) {
    if (contactType === "customer") isNotificationRelevantToContactType = true;
    if (contactType === "pickup") isNotificationRelevantToContactType = true;
  }

  if (tr?.internal_attributes.contact_delivery_skipped) {
    if (contactType === "customer") isNotificationRelevantToContactType = true;
    if (contactType === "delivery") isNotificationRelevantToContactType = true;
  }

  const isNotificationActive = perms?.canSeeNotification && isNotificationRelevantToContactType;

  if (isNotificationActive) {
    return (
      <Notification>
        <div>
          <Link to={`/${params.id}/edit`} className={cn("underline")}>
            {t((d) => d.click_here_cta)}
          </Link>{" "}
          <span>{t((d) => d.to_update_contact_details)}</span>
        </div>
      </Notification>
    );
  }

  return null;
};
