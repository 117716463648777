import React from "react";
import { MpCard } from ".";

export const EmailMessage: React.FC<{ text: string }> = ({ text }) => {
  return (
    <MpCard className="bg-blue-100 !border-blue-100 text-blue-600 flex flex-wrap items-center gap-4 !p-4">
      <svg width="25" height="17" viewBox="0 0 25 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.3068 0.576807C23.8833 0.207282 23.3169 0.000394733 22.7274 2.91344e-05H2.2726C1.68224 -0.0028271 1.11472 0.204431 0.69319 0.576807C0.252661 0.963468 0.00299314 1.49332 0 2.04743V14.9526C0 15.4957 0.23939 16.0164 0.665643 16.4004C1.0919 16.7842 1.66997 17 2.2726 17H22.7274C23.33 17 23.9081 16.7842 24.3344 16.4004C24.7606 16.0164 25 15.4957 25 14.9526V2.04743C24.9996 1.49295 24.7495 0.962239 24.3068 0.576807ZM16.7271 7.30565L23.9999 1.30663C24.0443 1.3659 24.0823 1.42874 24.1136 1.49443C24.115 1.50907 24.115 1.52389 24.1136 1.5387C24.142 1.60065 24.1648 1.66456 24.1818 1.72989V1.7947C24.1983 1.87449 24.2072 1.95554 24.2082 2.03694V14.9522C24.2044 15.1933 24.1285 15.429 23.9886 15.6347L16.7271 7.30565ZM22.727 0.682373C22.9775 0.685051 23.2237 0.743785 23.4428 0.853035L13.5223 9.04244C13.2433 9.27166 12.8791 9.39876 12.5016 9.39876C12.1239 9.39876 11.7598 9.27166 11.4806 9.04244L1.55658 0.853035C1.77655 0.745745 2.02228 0.688264 2.27235 0.685764L22.727 0.682373ZM0.976574 15.645C0.836668 15.4395 0.760769 15.2037 0.757005 14.9625V2.04724C0.757996 1.96602 0.766913 1.88497 0.783361 1.805V1.73681C0.800999 1.67272 0.823788 1.61006 0.851534 1.54901C0.850147 1.53437 0.850147 1.51955 0.851534 1.50473C0.882845 1.43904 0.920894 1.3762 0.965284 1.31711L8.27209 7.30562L0.976574 15.645ZM2.27199 16.3274C2.00486 16.3271 1.74287 16.261 1.51438 16.1364L8.83285 7.77655L10.9655 9.53385C11.3847 9.87999 11.9328 10.0721 12.5015 10.0721C13.0703 10.0721 13.6182 9.87998 14.0375 9.53385L16.1662 7.77655L23.4807 16.1264C23.2522 16.2512 22.9904 16.3172 22.723 16.3176L2.27199 16.3274Z" />
      </svg>
      <div style={{ width: "calc(100% - 41px)" }}>{text}</div>
    </MpCard>
  );
};
