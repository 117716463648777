import React from "react";
import { MpCard } from ".";
import { useTranslationContext } from "../../hooks";
import { IconCircleClock } from "@brenger/react";

export const TimeDisplay: React.FC = () => {
  const { t } = useTranslationContext();
  return (
    <MpCard className="!bg-[#F1FFFB] text-[#00BD84] !border-gray-100 mt-4 pt-4 flex items-center gap-3">
      <IconCircleClock className="shrink-0" />
      <div>{t((d) => d.fo.time_explain)}</div>
    </MpCard>
  );
};
