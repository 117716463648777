import { DateTimePeriodParams, FoParty, FoState, GeoDateTimePeriod, SourceFlow } from "@brenger/api-client";
import { foPriceToCents } from "../Marktplaats/utils";
import { clarityTag } from "./clarity";

export interface EtaEvent {
  event: "stopEta";
  stopId: string;
  eta: GeoDateTimePeriod;
  availableDtp: DateTimePeriodParams;
  commitedDtp: DateTimePeriodParams;
}

export interface PurchaseEvent {
  event: "purchase" | "upsell";
  transactionId: string;
  transactionAffiliation: string;
  transactionTotal: string;
  transactionProducts: [
    {
      sku: string;
      name: "Transport request" | "Upsell";
      price: string;
      quantity: number;
    },
  ];
}

export type FoInteraction =
  | "Purchase price range"
  | "Upsell price range"
  | "Price update"
  | "Not feasible after update"
  | "PS Category"
  | "Modal open - help"
  | "Modal open - item (tr details)"
  | "Started chat"
  | "More dates"
  | "More dates - reschedule"
  | "Modal open - contact details"
  | `${FoParty} update details`
  | "Service selected"
  | "Seller requested new dates"
  | "Seller sees expired dates";

export interface FoInteractionEvent {
  event: "FO Interaction";
  type: FoInteraction;
  data: string | number;
  partyId: string;
}

export interface FoStateEvent {
  event: "FO State";
  type: FoState;
  data: string;
  partyId: string;
}

export interface FoRelationEvent {
  event: "FO TR Relation" | "FO Stop Relation";
  /**
   * Party
   */
  type: string;
  /**
   * Tracking id
   */
  data: string;
  partyId: string;
}

export type GTMEvents = EtaEvent | PurchaseEvent | FoInteractionEvent | FoStateEvent | FoRelationEvent;

export const track = (event: GTMEvents): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.dataLayer.push(event);
};

export const trackAndTag = (event: Exclude<GTMEvents, EtaEvent | PurchaseEvent | FoRelationEvent>): void => {
  const tag = "type" in event ? event.type : "Fo State";
  clarityTag({ [tag]: event.data });
  track(event);
};

export const trackPurchase = ({
  id,
  partyId,
  type,
  flowType,
  amount,
}: {
  id: string | null;
  partyId: string;
  type: "purchase" | "upsell";
  flowType: SourceFlow;
  amount: string;
}): void => {
  const storageKey = "BRENGER_PURCHASES";
  const storage = sessionStorage.getItem(storageKey);
  const purchases: string[] = storage ? JSON.parse(storage) : [];
  // Prevent double tracking for this session
  if (!id || purchases.includes(id)) {
    return;
  }
  clarityTag({ [type]: getTrackingPriceRange(amount) });
  if (type === "purchase") track({ event: "FO Interaction", type: "Purchase price range", data: amount, partyId });
  if (type === "upsell") track({ event: "FO Interaction", type: "Upsell price range", data: amount, partyId });
  // Push purchase event
  track({
    event: type,
    transactionId: id,
    transactionAffiliation: flowType.toLowerCase(),
    transactionTotal: amount,
    transactionProducts: [
      {
        sku: flowType.toLowerCase(),
        name: type === "purchase" ? "Transport request" : "Upsell",
        price: amount,
        quantity: 1,
      },
    ],
  });
  // Flag as handled, this prevents extra events during the same session and will be reset when needed
  sessionStorage.setItem(storageKey, JSON.stringify(purchases));
};

export type TrackingPriceRange = "<50" | "50<75" | "75<100" | "100<125" | "125<150" | "150<";

export const getTrackingPriceRange = (amount: string): TrackingPriceRange => {
  const price = foPriceToCents(amount);
  if (price < 50) {
    return "<50";
  }
  if (price < 75) {
    return "50<75";
  }
  if (price < 100) {
    return "75<100";
  }
  if (price < 125) {
    return "100<125";
  }
  if (price < 150) {
    return "125<150";
  }
  return "150<";
};
