import { Button } from "@brenger/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslationContext } from "../../../hooks";
import { DotList, MpContainer, ThankYouLayout } from "../../components";
import { GenerateMpRoute } from "../../routes";

export const MpThankYouFeasible: React.FC<{ id: string }> = ({ id }) => {
  const history = useHistory();
  const { t } = useTranslationContext();

  return (
    <ThankYouLayout title={t((d) => d.fo.thank_you.feasible.title)}>
      <MpContainer>
        <DotList
          items={[
            t((d) => d.fo.thank_you.feasible.step_1),
            t((d) => d.fo.thank_you.feasible.step_2),
            t((d) => d.fo.thank_you.feasible.step_3),
          ]}
        />
        <Button className="w-full mt-4" buttonType="secondary" onClick={() => history.replace(GenerateMpRoute({ id }))}>
          {t((d) => d.fo.thank_you.feasible.button as string)}
        </Button>
      </MpContainer>
    </ThankYouLayout>
  );
};
