"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anonymizeCoord = exports.anonymizeLine1 = exports.anonymizeContact = void 0;
var houseNumber_1 = require("./houseNumber");
/**
 * Anonymize contact details by return static replacements
 */
var anonymizeContact = function (type) {
    if (type === 'email') {
        return 'email@domain.nl';
    }
    if (type === 'phone') {
        return '+31611111111';
    }
    if (type === 'last_name') {
        return 'Doe';
    }
    // type === 'first_name
    var rand = Math.random();
    if (rand < 0.45) {
        return 'John';
    }
    if (rand < 0.9) {
        return 'Jane';
    }
    return 'Zebra';
};
exports.anonymizeContact = anonymizeContact;
/**
 * Anonymize line1 by stripping out house number and suffix
 */
var anonymizeLine1 = function (input) {
    var houseNumber = (0, houseNumber_1.getHouseNumberIncludingSuffixFromLine1)(input);
    return input.replace(houseNumber, '').trim();
};
exports.anonymizeLine1 = anonymizeLine1;
/**
 * Anonymize coordinate by adding ditter. Results in a coord of radius 55m
 */
var anonymizeCoord = function (input) {
    var dither = 0.0005; // 55.5 meter
    return Number(input) + (Math.random() - 0.5) * dither;
};
exports.anonymizeCoord = anonymizeCoord;
