import { CoreLanguage, FoParty, SourceFlow } from "@brenger/api-client";
import { useEffect, useState } from "react";
import { useAuth, useInterval, useTranslationContext } from ".";
import { Config } from "../config";
import { isSandboxMode, localeNormalizer } from "../utils";
import { useTransportContext } from "./useTransportContext";
import { useLocation } from "react-router-dom";

interface InitParams {
  siteId: string;
  host: string;
  token: string;
  locale: CoreLanguage;
}

interface UserProperties {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  transport_short_id?: string | null;
  party?: FoParty | null;
  source?: SourceFlow;
}

interface UserParams {
  setLocale: (locale: CoreLanguage) => void;
  setProperties: (userProperties: UserProperties) => void;
  update: (userProperties: UserProperties) => void;
}

// Expose a much smaller interface to hook users.
// For more available methods, see docs: https://developers.freshchat.com/web-sdk/
interface UseFreshChat {
  user: UserParams;
  setTags(tags: string[]): void;
  isLoading: boolean;
  // Opens the Freshchat Widget
  open: () => void;
  /**
   * Removes the widget. Widget will have to be re-initalized again to be shown.
   */
  destroy: () => void;
}

interface FreshChatWidget extends UseFreshChat {
  init: (params: InitParams) => void;
  isInitialized: () => boolean;
  on(event: string, callback: () => void): void;
}

declare global {
  interface Window {
    fcWidget?: FreshChatWidget;
  }
}

export const useFreshChat = (): UseFreshChat | undefined => {
  const { i18n } = useTranslationContext();
  const [widget, setWidget] = useState(window.fcWidget);
  const trContext = useTransportContext();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const auth = useAuth();

  /**
   * To be called once
   */
  const id = "freshchat-lib";
  const init = (): void => {
    if (!Config.FRESH_CHAT_TOKEN || document.getElementById(id)) {
      return;
    }
    setIsLoading(true);
    if (document.getElementById(id) || window.fcWidget) return;
    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = "https://wchat.freshchat.com/js/widget.js";
    script.id = id;
    script.onload = (): void => {
      setWidget(window.fcWidget);

      (window.fcWidget as FreshChatWidget).init({
        // Our core language locales match-up with FreshChat's
        locale: localeNormalizer.parseLanguage(i18n.locale),
        siteId: "https://live.brenger.nl",
        token: Config.FRESH_CHAT_TOKEN,
        host: "https://wchat.freshchat.com",
      });

      (window.fcWidget as FreshChatWidget).on("widget:opened", () => {
        setIsLoading(false);
      });
    };
    document.head.appendChild(script);
  };

  const open = (): void => {
    if (widget?.isInitialized()) {
      widget.open();
      return;
    }
    init();
  };

  // Remove interval if: 1) widget is initialized or 2) node env is NOT production or 3) in sandbox mode
  const delay = isLoading || isSandboxMode() ? null : 5000;
  useInterval(init, delay);

  useEffect(() => {
    if (!isLoading && widget) {
      const trSourceFlow = `tr_source_${trContext.tr?.source_flow?.toLowerCase()}`;
      const tags = [trSourceFlow];

      if (location.pathname.includes("marktplaats/")) {
        tags.push("marktplaats_flow");
      } else {
        // set meta, which will be visible during chat, but not as tag
        widget?.user.update({
          transport_short_id: trContext.tr?.short_id,
        });
      }
      if (auth.user) {
        const sourceFlow: string = auth.account?.has_business_flow
          ? "logged_in_business_flow_customer"
          : "logged_in_general_flow_customer";
        tags.push(sourceFlow);
      }
      /**
       * ABOUT TAGS:
       * - You can auto assign chats based on tags
       * - You can influence the FAQs users see
       */
      widget.setTags(tags);
    }
  }, [widget, auth, isLoading, trContext, location]);

  return widget
    ? {
        ...widget,
        open,
        isLoading,
      }
    : undefined;
};
