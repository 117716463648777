import cn from "classnames";

import { Notification } from ".";
import { SANDBOX_BASENAME } from "../utils";

export const SandboxModeNotification: React.FC = () => {
  // Remove the sandbox basename from the current pathname so users can toggle back to "normal" mode
  // See Router's basename prop for more details.
  const normalModeUrl = window.location.pathname.replace(SANDBOX_BASENAME, "");

  return (
    <Notification>
      <div>
        {/* @TRANS @TODO */}
        <span>You are in sandbox mode</span>
        <span className={cn("mx-1")}>—</span>
        <a href={normalModeUrl} className={cn("underline")}>
          click here to turn off
        </a>
      </div>
    </Notification>
  );
};
