import React from "react";
import cn from "classnames";

import { PageContainer } from "../../../components";
import { useTransportContext } from "../../../hooks";
import { createAccessControl } from "../../../utils";

import { AcceptedDriver } from "./AcceptedDriver";
import { FeedbackButtons } from "./FeedbackButtons";
import { PendingDriverList } from "./PendingDriverList";

const getPerms = createAccessControl({
  pickup: {
    canViewSection: [2, 3, 4, 5, 6],
  },
  delivery: {
    canViewSection: [2, 3, 4, 5, 6],
  },
  customer: {
    canViewSection: [1, 2, 3, 4, 5, 6],
  },
});

export const Driver: React.FC = () => {
  const { acceptedTjal, pendingTjalList = [], status, contactType } = useTransportContext();

  const { perms } = getPerms(contactType, status);

  if (!perms?.canViewSection) return null;

  // NOTE: a driver has accepted the job and therefore the driver's info starts to become visible.
  if (acceptedTjal) {
    return (
      <PageContainer className={cn("py-6", "bg-gray-100")}>
        <AcceptedDriver />
        <FeedbackButtons />
      </PageContainer>
    );
  }

  // NOTE: in this scenario, the customer must select a TJAL to complete the transport.
  if (pendingTjalList.length > 0) {
    return (
      <PageContainer className={cn("py-6", "bg-gray-100")}>
        <PendingDriverList />
      </PageContainer>
    );
  }

  return null;
};
