import { Button, Spacer } from "@brenger/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "../../components";
import { useTranslationContext } from "../../hooks";
import { getCustomerAppUrl } from "../../utils";

export const PAYMENT_STATUS_PARAM = "payment_status";
// NOTE: it is a mollie status, they spell canceled American style, so single l
export const PAYMENT_STATUS_CANCELED = "canceled";

export const PaymentCancelledModal: React.FC = () => {
  const { t, i18n } = useTranslationContext();
  const [isActive, setIsActive] = React.useState(false);
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get(PAYMENT_STATUS_PARAM) === PAYMENT_STATUS_CANCELED) {
      setIsActive(true);
      history.replace(location.pathname);
    }
  }, []);

  return (
    <Modal isActive={isActive} closeHandler={() => setIsActive(false)}>
      <h3>{t((d) => d.payment_canceled.title)}</h3>
      <Spacer h={2} />
      <div
        className="text-with-links"
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __html: t((d, template) =>
            template(d.payment_canceled.text, { link: `${getCustomerAppUrl(i18n.locale)}/contact` })
          ),
        }}
      />
      <Spacer h={8} />
      <div>
        <Button buttonType="primary" onClick={() => setIsActive(false)}>
          {t((d) => d.payment_canceled.ok)}
        </Button>
      </div>
    </Modal>
  );
};
