import { Button, InputCheckbox, Label, Spacer, useForm } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { Modal, ModalProps } from "../../../components";
import { useTranslationContext } from "../../../hooks";
import { ReviewFirstPart, StepType } from "./ReviewModalRoot";

enum Field {
  MOVING_SUPPLIES = "moving_supplies",
  NEAT_VEHICLE = "neat_vehicle",
}

const initialState = {
  [Field.MOVING_SUPPLIES]: undefined as null | boolean | undefined,
  [Field.NEAT_VEHICLE]: undefined as null | boolean | undefined,
};

const validators = {
  [Field.MOVING_SUPPLIES]: (val: null | boolean | undefined) => typeof val === "undefined",
  [Field.NEAT_VEHICLE]: (val: null | boolean | undefined) => typeof val === "undefined",
};

interface Props extends ModalProps {
  setReviewPart: (reviewFirstPart: ReviewFirstPart) => void;
  setStep: (step: StepType) => void;
}

export const ReviewModalStep1: React.FC<Props> = (props) => {
  const { t } = useTranslationContext();

  const form = useForm({ initialState, validators });

  return (
    <Modal {...props}>
      <h3>{t((d) => d.public_review_title)}</h3>
      <Spacer h={4} />
      <div>{t((d) => d.public_review_desc)}</div>
      <Spacer h={6} />
      <>
        {[Field.MOVING_SUPPLIES, Field.NEAT_VEHICLE].map((field, i) => {
          return (
            <div key={i}>
              <div>{t((d) => d.review_questions[field])}</div>
              <Spacer h={2} />
              <div className={cn("flex", "flex-wrap")}>
                <Label position="right" text={t((d) => d.answers.yes)} className={cn("mr-6")}>
                  <InputCheckbox
                    checked={form.data[field].value === true}
                    onChange={() => {
                      form.set({
                        [field]: true,
                      });
                    }}
                  />
                </Label>
                <Label position="right" text={t((d) => d.answers.no)} className={cn("mr-6")}>
                  <InputCheckbox
                    checked={form.data[field].value === false}
                    onChange={() => {
                      form.set({
                        [field]: false,
                      });
                    }}
                  />
                </Label>
                <Label position="right" text={t((d) => d.not_applicable_abbr)} className={cn("mr-6")}>
                  <InputCheckbox
                    checked={form.data[field].value === null}
                    onChange={() => {
                      form.set({
                        [field]: null,
                      });
                    }}
                  />
                </Label>
              </div>
              <Spacer h={4} />
            </div>
          );
        })}
      </>
      <Spacer h={4} />
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-3", "mt-3")}>
        <Button buttonType="primary-outline" onClick={props.closeHandler}>
          {t((d) => d.no)}
        </Button>
        <Button
          disabled={form.hasErrors}
          onClick={() => {
            props.setReviewPart({
              neat_vehicle: form.data[Field.NEAT_VEHICLE].value as null | boolean,
              moving_supplies: form.data[Field.MOVING_SUPPLIES].value as null | boolean,
            });
            props.setStep(2);
          }}
        >
          {t((d) => d.submit)}
        </Button>
      </div>
    </Modal>
  );
};
