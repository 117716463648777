import cn from "classnames";
import React from "react";

export const DotList: React.FC<{ items: React.ReactNode[] }> = ({ items }) => {
  const firstItems = items.slice(0, items.length - 1);
  const lastItem = items.slice(-1);
  return (
    <div className="mb-10">
      <div className="relative">
        <div
          className="absolute border-l-[2px] border-white"
          style={{ top: "1.1rem", left: ".35rem", bottom: "-.5rem" }}
        />
        {firstItems.map((item, i) => {
          return (
            <div key={i} className={cn("relative flex flex-wrap gap-3 pb-4")}>
              <div
                className={cn("mt-2 rounded-full border-[2px]", {
                  "bg-green-400 border-green-400 animate-pulse": i === 0,
                  "bg-blue-600 border-white": i > 0,
                })}
                style={{
                  width: i === 0 ? "0.85rem" : "0.75rem",
                  height: i === 0 ? "0.85rem" : "0.75rem",
                }}
              />
              <div style={{ width: "calc(100% - 2.5rem)" }}>{item}</div>
            </div>
          );
        })}
      </div>
      <div className={cn("relative flex flex-wrap gap-3")}>
        <div
          className={cn("mt-2 rounded-full border-[2px] bg-blue-600 border-white")}
          style={{
            width: "0.75rem",
            height: "0.75rem",
          }}
        />
        <div style={{ width: "calc(100% - 2.5rem)" }}>{lastItem}</div>
      </div>
    </div>
  );
};
