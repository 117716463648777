import * as Sentry from "@sentry/react";
import { User, UserRole, Customer, Contact } from "@brenger/api-client";

import { Config } from "../config";
import { clarityTag } from "./clarity";

type Context = Record<string, Record<string, unknown>>;

class Logger {
  env: string;
  user: Partial<User> | undefined;

  constructor(env: string | undefined) {
    this.env = env || "development";
    this.user = undefined;
  }

  /**
   * Initialize Sentry
   */
  public init(): void {
    const envAllowList = ["staging", "production"];

    if (envAllowList.includes(this.env)) {
      Sentry.init({
        environment: Config.NODE_ENV,
        dsn: Config.SENTRY_DSN,
        release: Config.COMMIT_SHA,
        normalizeDepth: 8,
        allowUrls: [/https?:\/\/((tracking|live|www)\.)(brenger)(-transport)?\.(nl|be|com)/, "netlify.app"],
        denyUrls: [/safari-extension:/i, /maps\/api\/js/i, /maps-api-v3\/api\/js/i, "google-analytics.com"],
        ignoreErrors: [
          /TypeError: NetworkError when attempting to fetch resource|TypeError: Failed to fetch/i,
          /<unknown>/i,
          /SecurityError/i,
          // NOTE: this one was leading to insane number of errors for some users (200+ events in one session)
          // Could not track down exact src, nor could find a better ignore pattern.
          /UnhandledRejection/i,
          // NOTE: most likely also coming from maps - throws lots of errors.
          /o.lat/i,
          /ceCurrentVideo.currentTime/i,
          // handles "undefined is not an object (evaluating 'this.getPanes().overlayMouseTarget')"
          /google/i,
          /overlayMouseTarget/i,
          /_onBoundsChanged/i,
          /onAdd/i,
          /src\/helpers/i,
        ],
      });
    }
  }

  /**
   * Log a message to sentry AND console (depending on env + user role)
   */
  public message(message: string, context?: Context): string {
    // Print error to dev console
    this.dev("[logger.error]", message);
    const eventId = Sentry.captureMessage(message, { contexts: context });
    clarityTag({ crash: message });
    clarityTag({ crash_id: eventId });
    return eventId;
  }

  public exception(error: Error, context?: Context): string {
    const eventId = Sentry.captureException(error, { contexts: context });
    clarityTag({ crash: error.message });
    clarityTag({ crash_id: eventId });
    return eventId;
  }

  /**
   * Set Sentry context.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setContext(name: string, value: { [key: string]: any }): void {
    Sentry.setContext(name, value);
  }

  /**
   * Set user
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setUser(user: User): void {
    this.user = user;
    this.setContext("authenticated_user", user);
  }

  /**
   * Set customer
   */
  public setCustomer(customer: Customer | Contact | undefined): void {
    if (customer?.email) Sentry.setUser({ email: customer.email });
  }

  /**
   * The method that logs to the console.
   */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  public dev(...args: any): void {
    const envAllowList = ["development"];
    const roleAllowList: UserRole[] = ["ROLE_FRONTEND_DEBUGGER"];

    const isEnvAllowed = envAllowList.includes(this.env);
    const isRoleAllowed = roleAllowList.some((role) => this.user?.roles?.includes(role));

    if (isEnvAllowed || isRoleAllowed) {
      /* eslint-disable-next-line no-console */
      console.log("[DEV LOGGER]", ...args);
    }
  }
}

export const logger = new Logger(Config.NODE_ENV);
