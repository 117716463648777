import { FoDraftTrState, FoPartyIdParams } from "@brenger/api-client";
import { AxiosError } from "axios";
import { UseQueryResult, useQuery } from "react-query";
import { CacheKey, foClient } from "../../utils";
import { clarityTag } from "../../utils/clarity";
import { track, trackAndTag } from "../../utils/eventTracking";

/**
 * Simple wrap for mocking ease, and always the same caching strategy
 */
export const useDraftTr = ({ partyId }: FoPartyIdParams): UseQueryResult<FoDraftTrState, unknown> => {
  return useQuery(
    [CacheKey.FO_RETRIEVE_DRAFT_TR, partyId],
    () => foClient.marktplaats.retrieveDraftTr({ partyId: partyId as string }),
    {
      retry: (failureCount: number, error?: AxiosError) => {
        if (error?.response?.status === 404) {
          return false;
        }
        return failureCount < 3;
      },
      retryOnMount: false,
      // To prevent any casted types doing boo boo
      enabled: !!partyId,
      onSuccess: (data) => {
        clarityTag({ foParty: data.party });
        trackAndTag({
          event: "FO State",
          type: data.state,
          data: data.item.category,
          partyId,
        });
        if (data.party_tracking_id) {
          track({
            event: data.party === "buyer" ? "FO TR Relation" : "FO Stop Relation",
            type: data.party,
            data: data.party_tracking_id,
            partyId,
          });
        }
      },
    }
  );
};
