"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIdFromIri = void 0;
var getId = function (iri) { return iri.split('/')[2]; };
/**
 * This util accepts an IRI (eg: "/resource/1sdf-a1df-as1f-asd1") and returns just the ID from it (eg: "1sdf-a1df-as1f-asd1")
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
var getIdFromIri = function (iri) {
    if (typeof iri === 'string') {
        return getId(iri);
    }
    // If the incoming field is already an object (ie, it was expanded server-side),
    // check if the object has an "@id" key and and re-call this function to parse it.
    if (typeof (iri === null || iri === void 0 ? void 0 : iri['@id']) === 'string') {
        return getId(iri['@id']);
    }
    return undefined;
};
exports.getIdFromIri = getIdFromIri;
