import { BusinessOrderType } from "@brenger/api-client";
import { InputDate, InputRadio, InputText, Label, Select, Spacer, Strong } from "@brenger/react";
import cn from "classnames";
import React from "react";

import { StatusIcon } from "../../components";
import { useFormatDate, useTranslationContext, useTransportListContext } from "../../hooks";

export const Filters: React.FC = () => {
  const { t } = useTranslationContext();
  const ctx = useTransportListContext();

  const [depotAddressIri, setDepotAddressIri] = React.useState("");

  // NOTE + ASSUMPTION: only business accounts have an address list.
  // We show some extra filters for business accounts. See below for details.
  const isBusinessAccount = ctx.addressList.length > 0;
  const formatDatePricing = useFormatDate("api-date");

  return (
    <div className={cn("grid", "grid-cols-1", "gap-4")}>
      {/* FREE TEXT SEARCH  */}
      <div>
        <div>
          <Strong>{t((d) => d.filters)}</Strong>
        </div>
        <InputText
          placeholder={t((d) => d.search)}
          className={cn("w-full", "mt-2")}
          value={ctx.filters?.data.search?.value || ""}
          onChange={(val) => {
            ctx.filters?.set({ search: val || undefined, page: undefined });
          }}
        />
      </div>
      {/* STATUS */}
      <div>
        <div>
          <Strong>{t((d) => d.status_filter)}</Strong>
        </div>
        {/* STATUS ALL */}

        <Label text={t((d) => d.all)} position="right">
          <InputRadio
            checked={ctx.filters?.data.presentation?.value === undefined}
            onChange={() => {
              ctx.filters?.set({ presentation: undefined, page: undefined });
            }}
          />
        </Label>
        {/* STATUS PUBLISHED FOR CUSTOMER */}
        <Label
          text={
            <span className={cn("flex", "items-center")}>
              <span>{t((d) => d.published)}</span>
              <Spacer w={3} />
              <StatusIcon status={1} contactType="customer" />
            </span>
          }
          position="right"
        >
          <InputRadio
            checked={ctx.filters?.data.presentation?.value === "published_for_customer"}
            onChange={() => {
              ctx.filters?.set({ presentation: "published_for_customer", page: undefined });
            }}
          />
        </Label>
        {/* STATUS DRIVER CONFIRMED */}
        <Label
          text={
            <span className={cn("flex", "items-center")}>
              <span>{t((d) => d.driver_confirmed)}</span>
              <Spacer w={3} />
              <StatusIcon status={2} contactType="customer" />
            </span>
          }
          position="right"
        >
          <InputRadio
            checked={ctx.filters?.data.presentation?.value === "driver_confirmed"}
            onChange={() => {
              ctx.filters?.set({ presentation: "driver_confirmed", page: undefined });
            }}
          />
        </Label>
        {/* STATUS DELIVERED */}
        <Label
          text={
            <span className={cn("flex", "items-center")}>
              <span>{t((d) => d.delivered)}</span>
              <Spacer w={3} />
              <StatusIcon status={5} contactType="customer" />
            </span>
          }
          position="right"
        >
          <InputRadio
            checked={ctx.filters?.data.presentation?.value === "delivered"}
            onChange={() => {
              ctx.filters?.set({ presentation: "delivered", page: undefined });
            }}
          />
        </Label>
      </div>
      <div>
        <div>
          <Strong>{t((d) => d.date_filter)}</Strong>
        </div>
        <Label text={t((d) => d.all_days)} position="right">
          <InputRadio
            checked={ctx.filters?.data.delivery_day?.value === undefined}
            onChange={() => {
              // Sending "undefined" as a value eliminates the param from the API call and
              // therefore returns all business order types.
              ctx.filters?.set({ delivery_day: undefined, page: undefined });
            }}
          />
        </Label>
        <Label text={t((d) => d.select_a_day)} position="right">
          <InputRadio
            checked={!!ctx.filters?.data.delivery_day?.value}
            onChange={() => {
              // Sending "undefined" as a value eliminates the param from the API call and
              // therefore returns all business order types.
              ctx.filters?.set({
                delivery_day: formatDatePricing(new Date().toISOString()) || undefined,
                page: undefined,
              });
            }}
          />
        </Label>
        {/* SHOW THE DATE INPUT WHEN EXACT DAY RADIO BUTTON IS SELECTED */}
        {ctx.filters?.data.delivery_day?.value && (
          <InputDate
            className={cn("w-full", "mt-2")}
            value={ctx.filters?.data.delivery_day?.value || ""}
            onChange={(delivery_day) => {
              ctx.filters?.set({ delivery_day, page: undefined });
            }}
          />
        )}
        {/* SHOW ERROR MESSAGE - THIS IS MOSTLY FOR SAFARI WEB WHEN USER MUST FREE-FORM TEXT INPUT DATE */}
        {ctx.filters?.data.delivery_day?.error && <div className={cn("text-red-600", "mt-1")}>YYYY-MM-DD</div>}
      </div>
      {/* BUSINESS ORDER TYPE */}
      {isBusinessAccount && (
        <div>
          <div>
            <Strong>{t((d) => d.order_type_filter)}</Strong>
          </div>
          <Select
            value={ctx.filters?.data.presentation?.value}
            className={cn("w-full", "mt-2")}
            onChange={(status: unknown) => {
              // Create a type safe TransportRequestListPresentation status to pass to the API
              let typeSafeStatus = status as BusinessOrderType | undefined;

              // If an empty string arrives, it means the filter should be unset by passing 'undefined' to the API
              if (status === "") typeSafeStatus = undefined;

              ctx.filters?.set({ business_order_type: typeSafeStatus, page: undefined });
            }}
          >
            <option value="">{t((d) => d.all_orders)}</option>
            <option value="normal">{t((d) => d.normal_orders)}</option>
            <option value="return">{t((d) => d.return_orders)}</option>
          </Select>
        </div>
      )}
      <div>
        <div>
          <Strong>{t((d) => d.location_filter)}</Strong>
        </div>
        {/* FILTER BY WINKEL / MAGAZIJN LOCATIE (DEPOT LOCATION) */}
        {isBusinessAccount && (
          <Select
            className={cn("w-full", "my-2")}
            value={depotAddressIri}
            onChange={(addressIri) => {
              setDepotAddressIri(addressIri);
              const address = addressIri === "" ? undefined : ctx.addressList.find((a) => a["@id"] === addressIri);

              ctx.filters?.set({
                "stop_address[line1]": address?.line1,
                "stop_address[postal_code]": address?.postal_code,
                "stop_address[locality]": address?.locality,
                page: undefined,
              });
            }}
          >
            <option value="" key="default">
              {t((d) => d.all)}
            </option>
            <>
              {ctx.addressList.map((address) => {
                return (
                  <option key={address["@id"]} value={address["@id"]}>
                    {address.line1}, {address.postal_code}, {address.locality}
                  </option>
                );
              })}
            </>
          </Select>
        )}
        {/* LINE 1 */}
        <InputText
          placeholder={t((d) => d.address)}
          className={cn("w-full", "mt-2")}
          value={ctx.filters?.data["stop_address[line1]"]?.value || ""}
          onChange={(val) => {
            // When user begins to enter a custom stop address, we un-select the depot address
            setDepotAddressIri("");
            ctx.filters?.set({ "stop_address[line1]": val || undefined, page: undefined });
          }}
        />
        <Spacer h={4} />
        {/* POSTALCODE */}
        <InputText
          placeholder={t((d) => d.postal_code)}
          className={cn("w-full")}
          value={ctx.filters?.data["stop_address[postal_code]"]?.value || ""}
          onChange={(val) => {
            // When user begins to enter a custom stop address, we un-select the depot address
            setDepotAddressIri("");
            ctx.filters?.set({ "stop_address[postal_code]": val || undefined, page: undefined });
          }}
        />
        <Spacer h={4} />
        {/* LOCALITY */}
        <InputText
          placeholder={t((d) => d.locality)}
          className={cn("w-full")}
          value={ctx.filters?.data["stop_address[locality]"]?.value || ""}
          onChange={(val) => {
            // When user begins to enter a custom stop address, we un-select the depot address
            setDepotAddressIri("");
            ctx.filters?.set({ "stop_address[locality]": val || undefined, page: undefined });
          }}
        />
      </div>
    </div>
  );
};
