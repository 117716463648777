import React from "react";

export const IconTruckFilled: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 592 412.39"
      {...props}
    >
      <path d="M196.74,0Q266.36,0,336,0a53.67,53.67,0,0,1,53,45.66,96,96,0,0,1,.65,20.68c-.29,4.45-.2,4.54,4.18,4.44,18.41-.42,36.82-.32,55.23-.22a53.56,53.56,0,0,1,48.09,29.19c9.93,19.14,20.07,38.17,29.64,57.48,3.18,6.42,7.24,10.63,14.1,13.12,10.22,3.72,20.14,8.32,30,12.88,13.55,6.26,21,16.74,21,32q0,45.63.1,91.24c0,13-5.34,23.18-16.55,30a29,29,0,0,1-14.7,4.3q-8.25.17-16.5,0c-2.16,0-2.85.59-2.85,2.82,0,32.52-21.31,59.22-52.88,66.46-37.41,8.58-73.7-14.19-82.11-51.51a72,72,0,0,1-1.67-14.61c0-2.64-.94-3.15-3.34-3.14q-55.24.13-110.49.13c-21.5,0-43-.13-64.49.14-15.58.19-31.16.17-46.74.15-2.43,0-2.8.88-2.86,3-.33,14.11-3.66,27.39-12,39-11.77,16.43-27.63,26.31-47.81,28.59-23.48,2.65-43.61-4.36-59.65-22-10.92-12-16.34-26.32-17.2-42.42-.3-5.66-.19-5.64-6-5.69-11.47-.1-20.88-4.41-27.78-13.73A31.23,31.23,0,0,1,.14,309.14Q0,181.64,0,54.16C0,27.75,17.71,6.48,43.59,1.26A66,66,0,0,1,56.75.06q70,0,140,0ZM445.32,162.84v-.05h44.22c3,0,6-.14,9-.13,1.64,0,1.89-.61,1.13-2-.89-1.59-1.73-3.22-2.58-4.84-7.3-14-14.64-28-21.89-42.06-5.9-11.41-15.22-17-28-16.94-18.07.06-36.14.05-54.21.08-3.33,0-3.41.06-3.38,3.32.09,9.32.24,18.64.29,28,.05,10.41,0,20.82,0,31.23,0,3.38,0,3.39,3.48,3.39ZM150.65,344.51c.37-21.55-16.28-42-42.59-41.91-23.06.1-41.28,17.79-41.56,42-.26,22.91,18.79,41.74,42.34,41.59C134.11,386.08,150.82,366,150.65,344.51Zm322.49-42.24a41.73,41.73,0,0,0-41.85,41.19c-.47,22.49,17.14,42.17,42.13,42.46a41.65,41.65,0,0,0,42.06-41.85C515.27,319.35,495.81,302.25,473.14,302.27Z" />
    </svg>
  );
};
