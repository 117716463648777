import { FoDate, FoParty, FoPartyIdParams } from "@brenger/api-client";
import { parseApiDate } from "@brenger/utils";
import { addDays } from "date-fns";
import { uniqBy } from "lodash";
import React, { useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useFormatDate } from "../../hooks";
import { CacheKey, foClient } from "../../utils";
import { GenerateMpRoute } from "../routes";
import { useDraftTr } from "./useDraftTr";

interface UseExtraService {
  /**
   * Selected dates as strings
   */
  selected: string[];
  /**
   * Date options
   */
  options: FoDate[];
  /**
   * Set selected dates
   */
  toggleDate(d: string): void;
  /**
   * Get more available dates
   */
  getMore(): void;
  /**
   * Send selected dates to server
   */
  update(): void;
  /**
   * Only on initial
   */
  isLoading: boolean;
  /**
   * Everytime resources are fetched
   */
  isFetching: boolean;
  /**
   * When select service is loading
   */
  isSubmitting: boolean;
}

export const useBuyerDates = ({ partyId }: FoPartyIdParams): UseExtraService => {
  const draftTr = useDraftTr({ partyId });
  const party = draftTr.data?.party;
  const history = useHistory();
  const queryClient = useQueryClient();
  /**
   * Three main api operations
   * - fetch available dates, also triggered by more dates
   * - fetch prev selected
   * - setup mutation to update
   */
  const formatDate = useFormatDate("api-date");
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [start_date, setStartDate] = React.useState<string>(formatDate(new Date().toISOString()) as string);

  const [options, setOptions] = React.useState<FoDate[]>([]);

  const availableDates = useQuery(
    [CacheKey.FO_RETRIEVE_AVAILABLE_DATES, party, partyId, start_date],
    () =>
      foClient.marktplaats.retrieveAvailableDates({
        // Can be safely casted because is enabled dep
        party: party as FoParty,
        partyId,
        start_date,
      }),
    {
      enabled: !!party,
      refetchOnWindowFocus: false,
    }
  );

  React.useEffect(() => {
    if (!availableDates.data) return;
    setOptions(uniqBy([...options, ...availableDates.data.available_dates], "date"));
  }, [JSON.stringify(availableDates.data?.available_dates)]);

  const selectedDates = useQuery([CacheKey.FO_RETRIEVE_SELECTED_DATES, partyId], () =>
    foClient.marktplaats.retrieveSelectedDates({ partyId })
  );
  const [selected, setSelected] = React.useState<string[]>(selectedDates.data?.selected_dates.map((d) => d.date) || []);

  // Verify if selected dates are still available, and update selected if not
  // We want to do this once
  const checkedSelectedDates = useRef(false);
  React.useEffect(() => {
    // if there is no selected data or options || or we did check it, then bail
    if (!selectedDates.data || !options.length || checkedSelectedDates.current) return;
    // Filter out unavailable ones
    const filtered = (selectedDates.data.selected_dates || []).filter((s) => {
      const selectedDate = s.date;
      const option = options.find((o) => o.date === selectedDate);
      /**
       * There are two scenarios:
       * - We can not find the selected date in the current set of options, high unlikely that it isn't available then, so we keep it selected
       * - If the option is found, we return boolean based on availability
       */
      if (!option) return true;
      return option.kind !== "UNAVAILABLE";
    });
    checkedSelectedDates.current = true;
    setSelected(filtered.map((d) => d.date));
  }, [options, selectedDates.data]);

  const saveDates = useMutation(
    [CacheKey.FO_UPDATE_SELECTED_DATES, partyId],
    foClient.marktplaats.updateSelectedDates,
    {
      onSuccess: (data) => {
        queryClient.setQueryData([CacheKey.FO_RETRIEVE_SELECTED_DATES, partyId], data);
        if (draftTr.data?.state === "SCHEDULE_BUYER") {
          // continue with flow
          history.push(
            GenerateMpRoute({
              id: partyId,
              page: { type: "flows", state: "SCHEDULE_BUYER", progress: 2 },
            })
          );
          return;
        }
        // When we are schedule buyer we need to mark the flow complete
        rescheduleComplete.mutate({ party: "buyer", partyId });
      },
    }
  );

  const rescheduleComplete = useMutation(
    [CacheKey.FO_TRIGGER_COMPLETE, "reschedule_buyer", partyId],
    foClient.marktplaats.schedulingComplete,
    {
      onSuccess: () => {
        history.push(
          GenerateMpRoute({
            id: partyId,
            page: { type: "thank_you", state: "RESCHEDULE_BUYER" },
          })
        );
      },
    }
  );

  const update = (): void => {
    saveDates.reset();
    saveDates.mutate({ partyId, selected_dates: selected });
  };

  /**
   * Operations to show more available dates
   */
  const getMore = (): void => {
    /**
     * Get last date that is displayed, plus one day and then reformat as api-date
     */
    const lastDateOption = parseApiDate(options[options.length - 1].date);
    if (!lastDateOption) return;

    const moreStartDate = formatDate(addDays(lastDateOption, 1).toISOString()) as string;
    setStartDate(moreStartDate);
  };

  /**
   * Select / deselect a date
   */
  const toggleDate = (d: string): void => {
    // A set is a little easier to work with and has a performance benfit
    const selectedSet = new Set(selected);
    // Toggle date
    selectedSet.has(d) ? selectedSet.delete(d) : selectedSet.add(d);
    // Setup new array and update
    const newSelected = Array.from(selectedSet);
    setSelected(newSelected.sort());
  };

  return {
    selected,
    options,
    toggleDate,
    getMore,
    update,
    // Wait for party, so queries can be executed above
    isLoading: !party || selectedDates.isLoading || !options.length,
    isFetching: availableDates.isLoading || availableDates.isFetching,
    isSubmitting: saveDates.isLoading,
  };
};
