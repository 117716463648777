import { FoAddress } from "@brenger/api-client";
import React from "react";

export const Address: React.FC<{ address: FoAddress; inLine?: boolean }> = ({
  address: { locality, postal_code, street_name, house_number, house_number_addition },
  inLine,
}) => {
  /**
   * We only expose locality of the counter party for privacy reasons
   * Rest of the values will not be there or null
   */
  if (!postal_code && !street_name && !house_number && !house_number_addition) {
    return <div>{locality}</div>;
  }
  const n = [house_number, house_number_addition].filter(Boolean).join("");
  if (inLine) {
    return (
      <div>
        {street_name}, {postal_code} {locality}
      </div>
    );
  }
  return (
    <>
      <div>
        {street_name} {n}
      </div>
      <div>
        {postal_code} {locality}
      </div>
    </>
  );
};
