import { CoreViolation, DamageReportCreateParams } from "@brenger/api-client";
import {
  Button,
  Card,
  ExtraSmall,
  IconArrowLeft,
  IconCheck,
  IconChevronDown,
  IconChevronRight,
  InputCheckbox,
  InputDate,
  InputText,
  Label,
  Message,
  Strong,
  Textarea,
  useModalState,
  UseForm,
  useForm,
} from "@brenger/react";
import cn from "classnames";
import iban from "iban";
import React from "react";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { Modal, PageContainer } from "../../components";
import { useAuth, useTranslationContext, useTransportContext, useTransportParams } from "../../hooks";
import { coreClient } from "../../utils";
import { required } from "../../utils/validation";
import { FilePicker } from "./FilePicker";

type DamageForm = DamageReportCreateParams & {
  terms: boolean;
};

export const DamageClaimForm: React.FC = () => {
  const drawer = useModalState();
  const successMessage = useModalState();
  const repairQuoteModal = useModalState();
  const { t } = useTranslationContext();
  const ctx = useTransportContext();
  const params = useTransportParams();
  const auth = useAuth();
  const form = useForm<DamageForm>({
    initialState: {
      transport_request: "",
      before_damage_pictures: [],
      after_damage_pictures: [],
      proof_of_payment: "",
      repair_quote_price: "",
      reported_by_name: auth.account?.name || "",
      reported_by_email: auth.user?.email || "",
      reported_by_phone: auth.account?.phone || "",
      name_driver: ctx.acceptedTjal?.driver_user?.first_name || "",
      repair_quote: "",
      reported_by_address: "",
      reported_by_iban: "",
      date_of_damage: "",
      where: "",
      description: "",
      item_description: "",
      item_brand_name: "",
      terms: false,
    },
    validators: {
      before_damage_pictures: (val) => required(val),
      after_damage_pictures: (val) => required(val),
      proof_of_payment: (val) => required(val),
      reported_by_name: (val) => required(val),
      reported_by_email: (val) => required(val),
      reported_by_phone: (val) => required(val),
      reported_by_address: (val) => required(val),
      reported_by_iban: (val) => (val && iban.isValid(val) ? false : t((d) => d.damage.iban_valid)),
      date_of_damage: (val) => required(val),
      where: (val) => required(val),
      description: (val) => required(val),
      item_description: (val) => required(val),
      item_brand_name: (val) => required(val),
      terms: (val) => required(val),
      name_driver: (val) => required(val),
    },
  });

  // Create Damage Form
  const onCreateDamageClaimSuccess = (): void => {
    form.reset();
    successMessage.open();
  };
  const createDamageClaim = useMutation(coreClient.damageReports.create, { onSuccess: onCreateDamageClaimSuccess });

  const onSubmitDamageClaimForm = (): void => {
    // Check if driver name is changed in the form if not add last name
    const driverForm = form.data.name_driver.value;
    const driverTr = ctx.acceptedTjal?.driver_user;
    const driverName = driverForm === driverTr?.first_name ? driverForm + " " + driverTr?.last_name : driverForm;

    createDamageClaim.mutate({
      transport_request: ctx.tr?.["@id"] || "",
      before_damage_pictures: form.data.before_damage_pictures.value,
      after_damage_pictures: form.data.after_damage_pictures.value,
      proof_of_payment: form.data.proof_of_payment.value,
      repair_quote_price: form.data.repair_quote_price.value,
      reported_by_name: form.data.reported_by_name.value,
      reported_by_email: form.data.reported_by_email.value,
      reported_by_phone: form.data.reported_by_phone.value,
      reported_by_address: form.data.reported_by_address.value,
      reported_by_iban: form.data.reported_by_iban.value,
      date_of_damage: form.data.date_of_damage.value,
      where: form.data.where.value,
      description: form.data.description.value,
      item_description: form.data.item_description.value,
      item_brand_name: form.data.item_brand_name.value,
      name_driver: driverName,
      repair_quote: form.data.repair_quote?.value,
    });
  };

  // Show a success message AFTER damage claim was successfully submitted
  if (successMessage.isActive) {
    return (
      <PageContainer>
        <h3>{t((d) => d.damage.title)}</h3>
        {/* message */}
        <Message type="success" className={cn("my-4")}>
          {t((d) => d.damage.submit_success)}
        </Message>
        {/* go back button */}
        <Link to={`/${params.id}`}>
          <Button icon={<IconArrowLeft />} buttonType="primary-outline" className={cn("w-full")} disabled={false}>
            {t((d) => d.go_back)}
          </Button>
        </Link>
      </PageContainer>
    );
  }
  const complaintErr = (createDamageClaim.error as undefined | CoreViolation)?.message;
  return (
    <PageContainer>
      <h3>{t((d) => d.damage.title)}</h3>
      <div className={cn("my-2")}>{t((d) => d.damage.desc)}</div>
      <button onClick={drawer.isActive ? drawer.close : drawer.open}>
        <div className={cn("inline-flex", "items-center", "text-blue-600")}>
          <ExtraSmall>{drawer.isActive ? <IconChevronDown /> : <IconChevronRight />}</ExtraSmall>
          <Strong className={cn("ml-1")}>{t((d) => d.damage.additional_terms_title)}</Strong>
        </div>
      </button>
      {drawer.isActive && <div>{t((d) => d.damage.additional_terms)}</div>}
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4", "mt-4")}>
        <Label text="ID" isRequired={true}>
          <InputText disabled={true} value={ctx.tr?.short_id} />
        </Label>
        <TextField text={{ field: "reported_by_name", label: t((d) => d.name) }} form={form} />
        <TextField text={{ field: "reported_by_email", label: t((d) => d.email) }} form={form} />
        <TextField text={{ field: "reported_by_phone", label: t((d) => d.phone_number) }} form={form} />
        <TextField text={{ field: "name_driver", label: t((d) => d.damage.driver_name) }} form={form} />
        <TextField text={{ field: "reported_by_address", label: t((d) => d.address) }} form={form} />
        <TextField text={{ field: "reported_by_iban", label: "IBAN" }} form={form} />
        <Label text={t((d) => d.damage.date)} isRequired={true}>
          <InputDate
            value={form.data.date_of_damage.value}
            onChange={(date_of_damage) => form.set({ date_of_damage })}
          />
        </Label>
        <TextField text={{ field: "repair_quote_price", label: t((d) => d.damage.reparation_cost) }} form={form} />
      </div>
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4", "mt-4")}>
        <TextArea text={{ field: "where", label: t((d) => d.damage.where) }} form={form} />
        <TextArea text={{ field: "description", label: t((d) => d.damage.event_description) }} form={form} />
        <TextArea text={{ field: "item_description", label: t((d) => d.damage.item_description) }} form={form} />
        <TextArea text={{ field: "item_brand_name", label: t((d) => d.damage.item_brand_name) }} form={form} />
      </div>
      {/* IMAGES AND INVOICESS   */}
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4", "mt-4", "flex")}>
        <FilePicker
          client={coreClient.damageReports.createBeforeDamageImages}
          label={t((d) => d.damage.before_photo)}
          saveFile={(file) => form.set({ before_damage_pictures: file })}
          canSelectMultiple={true}
        />
        <FilePicker
          client={coreClient.damageReports.createAfterDamageImages}
          label={t((d) => d.damage.after_photo)}
          saveFile={(file) => form.set({ after_damage_pictures: file })}
          canSelectMultiple={true}
        />
        <FilePicker
          client={coreClient.damageReports.createDamageExternalDocuments}
          label={t((d) => d.damage.invoice_photo)}
          saveFile={(file) => form.set({ proof_of_payment: file[0] })}
        />

        <FilePicker
          client={coreClient.damageReports.createDamageExternalDocuments}
          label={t((d) => d.damage.repair_quote)}
          saveFile={(file) => form.set({ repair_quote: file[0] })}
          openHelpModal={repairQuoteModal.open}
        />
      </div>
      <div className={cn("grid", "sm:grid-cols-1", "grid-cols-1", "gap-4", "mt-4")}>
        <Card type="gray">
          <Label position="right" text={t((d) => d.damage.legal_declaration_title)} isRequired={true}>
            <InputCheckbox
              checked={form.data.terms.value}
              onChange={() => form.set({ terms: !form.data.terms.value })}
            />
          </Label>
          <div
            className={cn("flex-inline")}
            /* eslint-disable-next-line @typescript-eslint/naming-convention */
            dangerouslySetInnerHTML={{ __html: t((d) => d.damage.legal_declaration) }}
          />
        </Card>
      </div>
      {/* FORM BUTTONS */}
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4", "mt-6")}>
        <Link to={`/${params.id}`}>
          <Button icon={<IconArrowLeft />} buttonType="primary-outline" className={cn("w-full")} disabled={false}>
            {t((d) => d.go_back)}
          </Button>
        </Link>
        <Button
          buttonType="secondary"
          icon={<IconCheck />}
          disabled={form.hasErrors}
          loading={createDamageClaim.isLoading}
          onClick={onSubmitDamageClaimForm}
        >
          {t((d) => d.submit)}
        </Button>
      </div>
      {/* show potential error message */}
      {complaintErr && (
        <Message type="error" className={cn("mt-4")}>
          {complaintErr}
        </Message>
      )}
      <Modal closeHandler={repairQuoteModal.close} isActive={repairQuoteModal.isActive}>
        <div className={"help-content text--center"}>{t((d) => d.damage.repair_quote_help)}</div>
      </Modal>
    </PageContainer>
  );
};

type FieldProp = { text: { field: keyof DamageForm; label: string }; form: UseForm.Form<DamageForm> };
const TextField: React.FC<FieldProp> = ({ text, form }) => {
  return (
    <Label text={text.label} isRequired={true}>
      <InputText
        value={form.data[text.field]?.value as string}
        onChange={(value) => form.set({ [text.field]: value })}
        key={text.field + text.label}
      />
    </Label>
  );
};
const TextArea: React.FC<FieldProp> = ({ text, form }) => (
  <Label text={text.label} isRequired={true}>
    <Textarea
      key={text.field + text.label}
      value={form.data[text.field]?.value as string}
      onChange={(value) => form.set({ [text.field]: value })}
    />
  </Label>
);
