import React from "react";

export interface UseScrollToError {
  errorsVisible: boolean;
  setErrorsVisible(visible: boolean): void;
  errorScrollTo(): void;
  errorVisibleAndScrollTo(): void;
  errorAttr: {
    "data-scroll-to-error": boolean;
  };
}

export const useScrollToError = (): UseScrollToError => {
  const [errorsVisible, setErrorsVisible] = React.useState(false);
  const errorVisibleAndScrollTo = (): void => {
    const delay = errorsVisible ? 0 : 200;
    setErrorsVisible(true);
    setTimeout(errorScrollTo, delay);
  };
  const errorScrollTo = (): void => {
    const error = document.querySelector("[data-scroll-to-error]");
    if (error) {
      error.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return {
    errorsVisible,
    setErrorsVisible,
    errorScrollTo,
    errorVisibleAndScrollTo,
    errorAttr: {
      "data-scroll-to-error": true,
    },
  };
};
