import React from "react";
import cn from "classnames";
import { Small } from "@brenger/react";

import { MapProvider, useMapContext, useTranslationContext, useTransportContext } from "../../../hooks";
import { IconInfoCircle } from "../../../components";

const HEIGHT = "max(25vh, 300px)";

export const MapContainerRaw: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslationContext();
  const { status } = useTransportContext();
  const { stopsBeforeStop, driverLocation } = useMapContext();

  let message = undefined;
  const isPickupInProgress = status === 3;
  const isDeliveryInProgress = status === 4;

  // NOTE: if there are, in fact, stops before a stop according to the API, let's generate a message for users accordingly.
  if (stopsBeforeStop) {
    // NOTE: remaining stops have not been confirmed by driver
    const remainingStopList = stopsBeforeStop?.filter((s) => s.confirmed_by_driver_at === null);
    // NOTE: concat the remaining stop lat + lng in order to create a uniq set of remaining stops.
    // We do this because there are often cases where a driver has many stops at the same address/depot, which leads
    // to mis-leading number of stops before stop (ie, mis-match between markers on map and remaining stop number that customer is notified of).
    const remainingStopSet = new Set(remainingStopList.map((s) => s.lat + s.lng));

    // Generate correct message for stops before PICKUP
    if (isPickupInProgress) {
      if (remainingStopSet.size === 0) {
        message = t((d) => d.you_are_the_next_stop);
      } else if (remainingStopSet.size === 1) {
        message = t((d) => d.stops_before_pickup_singular);
      } else {
        message = t((d, template) => template(d.stops_before_pickup, { stop_count: remainingStopSet.size }));
      }
    }

    // Generate correct message for stops before DELIVERY
    if (isDeliveryInProgress) {
      if (remainingStopSet.size === 0) {
        message = t((d) => d.you_are_the_next_stop);
      } else if (remainingStopSet.size === 1) {
        message = t((d) => d.stops_before_delivery_singular);
      } else {
        message = t((d, template) => template(d.stops_before_delivery, { stop_count: remainingStopSet.size }));
      }
    }
  }

  // NOTE: in case our API has no data for the stops before a stop BUT the driver location is turned on,
  // it is nevertheless nice to show a warning message to customers indicating that the driver may not be
  // going directly to them quite yet. This minimizes phones calls if the drivers location doesn't directly
  // appear to be going towards the customer's location at a given moment.
  if (message === undefined && driverLocation) {
    message = t((d) => d.stops_before_stop_disclaimer);
  }

  return (
    <div className={cn("relative", "inset-0", "w-full")}>
      {message && (
        <div className={cn("py-3", "px-2", "flex", "items-center", "justify-center", "text-center")}>
          <IconInfoCircle className={cn("w-4", "h-4", "sm:w-5", "sm:h-5", "text-blue-600", "mr-2")} />
          <Small>{message}</Small>
        </div>
      )}
      <div style={{ height: HEIGHT }}>{children}</div>
      <div
        style={{ boxShadow: "inset 0 0px 6px 6px rgba(0, 0, 0, 0.1)" }}
        // shadow-inner is customized in tailwind config
        className={cn("pointer-events-none", "absolute", "z-20", "h-full", "w-full", "inset-0")}
      />
    </div>
  );
};

export const MapContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <MapProvider>
      <MapContainerRaw>{children}</MapContainerRaw>
    </MapProvider>
  );
};
