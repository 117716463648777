import React from "react";
import cn from "classnames";

export const PageContainer: React.FC<React.HTMLAttributes<HTMLElement>> = ({ children, ...rest }) => {
  return (
    <div {...rest}>
      <div className={cn("container", "mx-auto", "px-4")}>{children}</div>
    </div>
  );
};
