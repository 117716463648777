import { FoDraftTrState } from "@brenger/api-client";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { MpLayout } from "../../components";
import { MpRoutes } from "../../routes";
import { FeasiblePayment } from "./FeasiblePayment";

export const Feasible: React.FC<{ draftTr: FoDraftTrState }> = ({ draftTr }) => {
  return (
    <MpLayout>
      <Switch>
        <Route
          exact
          path={MpRoutes.FLOWS_FEASIBLE_1}
          render={(props) => <FeasiblePayment {...props} draftTr={draftTr} />}
        />
        <Redirect to={MpRoutes.FLOWS_FEASIBLE_1} />
      </Switch>
    </MpLayout>
  );
};
