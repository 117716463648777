import { FoBaseParams } from "@brenger/api-client";
import React from "react";
import { MpContainer, MpFooterThankYou, ThankYouLayout } from "../components/Layout";
import { Redirect, Route, Switch } from "react-router-dom";
import { GenerateMpRoute, MpRoutes } from "../routes";
import { useTranslationContext } from "../../hooks";
import Waiting from "../assets/mp_waiting.gif";
import { Config, LOCALE_TEMPLATE } from "../../config";

export const WaitingPage: React.FC<FoBaseParams> = (props) => {
  /**
   * This is switch is in place to clear prev routes and not cause confusing urls
   */
  return (
    <Switch>
      <Route path={MpRoutes.WAITING} render={() => <Page {...props} />} />
      <Redirect to={GenerateMpRoute({ id: props.partyId, page: { type: "waiting" } })} />
    </Switch>
  );
};

const Page: React.FC<FoBaseParams> = ({ party }) => {
  const { t, i18n } = useTranslationContext();
  return (
    <ThankYouLayout title={t((d) => d.fo.waiting[`${party}_title`])}>
      <MpContainer>{t((d) => d.fo.waiting[`${party}_text`])}</MpContainer>
      <div className="relative my-4">
        <div className="absolute right-0 left-1/2 border-t border-color-white mt-5" />
        <MpContainer className="relative">
          <div className="absolute right-4 left-8 border-t border-color-white mt-1" />
          <div className={"rounded-full w-3 h-3 bg-green-400 animate-pulse"} style={{ marginTop: "-.1rem" }} />
        </MpContainer>
      </div>
      <MpContainer fullWithBelowBreak="md">
        <img
          width={1000}
          height={1000}
          className={"max-w-full md:max-w-lg md:border md:border-white-1/2"}
          src={Waiting}
        />
      </MpContainer>
      <MpFooterThankYou>
        <MpContainer>
          <a
            target="_blank"
            rel="noreferrer"
            href={Config.CUSTOMER_FAQ_URL.replace(LOCALE_TEMPLATE, i18n.locale)}
            dangerouslySetInnerHTML={{ __html: t((d) => d.fo.waiting.faq_text) }}
          />
        </MpContainer>
      </MpFooterThankYou>
    </ThankYouLayout>
  );
};
