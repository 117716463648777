import React from "react";
import { Item } from "@brenger/api-client";
import { useTranslationContext } from "../hooks";

interface Props {
  item: Item;
}

export const ProductSelectionSpecs: React.FC<Props> = ({ item: { heavy, fragile } }) => {
  const { t } = useTranslationContext();
  if (!heavy && !fragile) {
    return null;
  }
  const specs = [heavy ? t((d) => d.item.heavy) : false, fragile ? t((d) => d.item.fragile) : false].filter(Boolean);
  return (
    <>
      {"("}
      {specs.join(" & ")}
      {")"}
    </>
  );
};
