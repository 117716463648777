import { SourceFlow } from "@brenger/api-client";
import { ContactType } from "./status";

interface ShowAppointmentDetails {
  showPickup: boolean;
  showDelivery: boolean;
  showPickupSummary: boolean;
  showDeliverySummary: boolean;
  showService: boolean;
}

export const getShowAppointmentDetails = ({
  type = "",
  source,
}: {
  type: ContactType | undefined | "";
  source: SourceFlow;
}): ShowAppointmentDetails => {
  const sp = ["pickup", "customer"].includes(type);
  const sd = ["delivery", "customer"].includes(type);
  // in case of MP:
  // - we show only one stop when customer, for privacy reasons
  // - We always show service
  // - Stop summary is the same is normal
  if (source === SourceFlow.MARKTPLAATS) {
    return {
      showPickup: ["pickup"].includes(type),
      showDelivery: ["delivery", "customer"].includes(type),
      showPickupSummary: sp,
      showDeliverySummary: sd,
      showService: true,
    };
  }
  // in other cases we show all details to the customer
  // except for service, which is MP only (for now)
  return {
    showPickup: sp,
    showDelivery: sd,
    showPickupSummary: sp,
    showDeliverySummary: sd,
    showService: false,
  };
};
