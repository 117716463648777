import { useQuery } from "react-query";
import { Redirect } from "react-router-dom";
import { CacheKey, foClient } from "../utils";
import { PAYMENT_STATUS_CANCELED, PAYMENT_STATUS_PARAM } from "../pages/TransportDetails/PaymentCancelledModal";
import { DefaultError } from "./components/DefaultError";
import { MpLoader } from "./components/Loader";
import { GenerateMpRoute, MpThankYouRouteComponentProps } from "./routes";
import { FoState } from "@brenger/api-client";

/**
 * This component is a redirect component were the FO refers to when a payment failed
 * Based on STATE we do different redirects or show an error:
 * - COMPLETED: We need a tracking page id, so we need fetch the draft tr first
 * - INIT | NOT FEASIBLE: More of a internal state, highly unlikely to have a failed payment, but show an error if so
 * - rest of the states: Generate a path to the last page of the flow, we will show an error message there
 */
export const PaymentCanceled: React.FC<MpThankYouRouteComponentProps> = (props) => {
  const partyId = props.match.params.id;
  // FO refers to a url were we can read the state from.
  // Maybe urls get normalized, all states should be uppercase, so let's be sure
  const state = props.match.params.state.toUpperCase() as FoState;

  const isCompleted = state === "COMPLETED";
  const draftTr = useQuery([CacheKey.FO_RETRIEVE_DRAFT_TR], () => foClient.marktplaats.retrieveDraftTr({ partyId }), {
    // Enable when state is completed
    enabled: isCompleted,
  });
  // Like described above, we need extra steps for state === COMPLETED
  if (isCompleted) {
    const url = new URL(`/${draftTr.data?.party_tracking_id}`, window.location.origin);
    url.searchParams.append(PAYMENT_STATUS_PARAM, PAYMENT_STATUS_CANCELED);
    return (
      <>
        <MpLoader partyId={partyId} />
        {draftTr.data && <Redirect to={url.pathname + url.search} />}
        {draftTr.isError && <DefaultError />}
      </>
    );
  }
  // These are the internal states, and highly unlikely to happen, but we need to catch them
  if (state === "INIT" || state === "NOT_FEASIBLE") {
    return <DefaultError />;
  }
  // State !== COMPLETED aka rest of the states:
  // We point to the last page in the flow for the state we are in,
  // In other words: the path were the customer left off to the payment provider.
  let path = GenerateMpRoute({ id: partyId, page: { type: "flows", state: "FEASIBLE", progress: 1 } });
  if (state === "SCHEDULE_BUYER") {
    path = GenerateMpRoute({ id: partyId, page: { type: "flows", state: "SCHEDULE_BUYER", progress: 4 } });
  }
  if (state === "RESCHEDULE_BUYER") {
    path = GenerateMpRoute({ id: partyId, page: { type: "flows", state: "RESCHEDULE_BUYER", progress: 1 } });
  }
  if (state === "SCHEDULE_SELLER") {
    path = GenerateMpRoute({ id: partyId, page: { type: "flows", state: "SCHEDULE_SELLER", progress: 3 } });
  }
  const url = new URL(path, window.location.origin);
  url.searchParams.append(PAYMENT_STATUS_PARAM, PAYMENT_STATUS_CANCELED);
  return <Redirect to={url.pathname + url.search} />;
};
