import { FoDraftTrState } from "@brenger/api-client";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { MpLayout } from "../../components/Layout";
import { MpRoutes } from "../../routes";
import { SharedBuyerDatesPage } from "../SharedBuyerDatesPage";

export const RescheduleBuyer: React.FC<{ draftTr: FoDraftTrState }> = () => {
  return (
    <MpLayout>
      <Switch>
        <Route exact path={MpRoutes.FLOWS_RESCHEDULE_BUYER_1} component={SharedBuyerDatesPage} />
        <Redirect to={MpRoutes.FLOWS_RESCHEDULE_BUYER_1} />
      </Switch>
    </MpLayout>
  );
};
