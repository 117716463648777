import { Button } from "@brenger/react";
import { createWhatsAppUrl } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { useClipboard } from "use-clipboard-copy";

import { IconClipboard, IconClipboardCheck, IconTelephone, IconWhatsapp, Modal, ModalProps } from "../../../components";
import { useTranslationContext } from "../../../hooks";

interface Props extends ModalProps {
  driverPhoneNumber: string;
}

const COPIED_TIMEOUT_MS = 600;

export const CallModal: React.FC<Props> = ({ driverPhoneNumber, ...props }) => {
  const { t } = useTranslationContext();
  const whatsAppUrl = createWhatsAppUrl(driverPhoneNumber);

  const clipboard = useClipboard({
    // NOTE: used to toggle clipboard icon upon successful copy.
    copiedTimeout: COPIED_TIMEOUT_MS,
  });

  return (
    <Modal {...props}>
      <h3 className={cn("tracking-wide")}>{driverPhoneNumber}</h3>
      <div className={cn("grid", "gap-4", "my-4")}>
        <Button
          icon={<IconTelephone />}
          buttonType="gray-outline"
          className={cn("text-left")}
          onClick={() => window.open(`tel:${driverPhoneNumber}`, "_blank")}
        >
          {t((d) => d.call)}
        </Button>
        <Button
          disabled={!whatsAppUrl}
          icon={<IconWhatsapp />}
          buttonType="gray-outline"
          className={cn("text-left")}
          onClick={() => window.open(whatsAppUrl)}
        >
          {t((d) => d.text_message)}
        </Button>
        <Button
          onClick={() => clipboard.copy(driverPhoneNumber)}
          icon={clipboard.copied ? <IconClipboardCheck /> : <IconClipboard />}
          buttonType={clipboard.copied ? "secondary-outline" : "gray-outline"}
          className={cn("text-left")}
          style={{ transition: `all ${COPIED_TIMEOUT_MS / 4}ms linear` }}
        >
          {t((d) => d.copy)}
        </Button>
      </div>
      <Button buttonType="primary-outline" className={cn("w-full")}>
        {t((d) => d.cancel)}
      </Button>
    </Modal>
  );
};
