import { SourceFlow } from "@brenger/api-client";
import React from "react";
import MarktplaatsLogo from "../Marktplaats/assets/marktplaats.svg";
import cn from "classnames";

export const PartnerLogo: React.FC<{ className?: string; source?: SourceFlow }> = ({ source, className }) => {
  if (source === SourceFlow.MARKTPLAATS) {
    return <img src={MarktplaatsLogo} className={cn("inline-block", className)} />;
  }
  return null;
};
