import React from "react";
import cn from "classnames";

import { Status, ContactType } from "../utils";

interface Props extends React.HTMLAttributes<HTMLElement> {
  status: Status;
  contactType: ContactType;
}

type Chart = {
  [contactType in ContactType]: {
    [status in Status]: 0 | 25 | 50 | 75 | 100;
  };
};

/**
 * Explanation: There are more Statuses than there are status bars.
 * Furthermore, different contact types map differently onto the stats bar.
 * Therefore, it is necessary to re-map the overall status to the FOUR available status bars in the UI.
 */
const chart: Chart = {
  pickup: {
    0: 0,
    1: 25,
    2: 50,
    3: 75,
    4: 100,
    5: 100,
    6: 100,
  },
  delivery: {
    0: 0,
    1: 25,
    2: 50,
    3: 50,
    4: 75,
    5: 100,
    6: 100,
  },
  customer: {
    0: 0,
    1: 25,
    2: 50,
    3: 75,
    4: 75,
    5: 100,
    6: 100,
  },
};

const cns = cn("h-2", "bg-gray-200", "rounded-sm");

export const StatusBar: React.FC<Props> = ({ status, contactType }) => {
  const progress = chart[contactType][status];

  return (
    <div className={cn("grid", "grid-cols-4", "gap-2", "my-4")}>
      {/* 25% */}
      <div className={cn(cns, { "bg-green-400": progress >= 25 })} />
      {/* 50% */}
      <div className={cn(cns, { "bg-green-400": progress >= 50 })} />
      {/* 75% */}
      <div className={cn(cns, { "bg-green-400": progress >= 75 })} />
      {/* 100% */}
      <div className={cn(cns, { "bg-green-400": progress >= 100 })} />
    </div>
  );
};
