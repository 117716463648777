import { FoDraftTrState } from "@brenger/api-client";
import { removeStoredLocale } from "@brenger/utils";
import React from "react";
import { Redirect } from "react-router-dom";
import { ErrorPage } from "./Pages/ErrorPage";
import { Feasible } from "./Pages/Feasible";
import { RescheduleBuyer } from "./Pages/ReScheduleBuyer";
import { ScheduleBuyer } from "./Pages/ScheduleBuyer";
import { ScheduleSeller } from "./Pages/ScheduleSeller";
import { WaitingPage } from "./Pages/WaitingPage";
import { MpLoader } from "./components/Loader";
import { useDraftTr } from "./hooks/useDraftTr";
import { GenerateMpRoute, MpRouteComponentProps } from "./routes";
import { isWaitingState } from "./utils";
import { ProcessingPage } from "./Pages/ProcessingPage";

export const Flow: React.FC<MpRouteComponentProps> = (props) => {
  const partyId = props.match.params.id;
  const draftTr = useDraftTr({ partyId });
  return (
    <>
      <MpLoader partyId={partyId} />
      {draftTr.data && <PartyStateGate draftTr={draftTr.data} partyId={props.match.params.id} />}
      {!draftTr.isLoading && !draftTr.data && <ErrorPage partyId={partyId} />}
    </>
  );
};

const PartyStateGate: React.FC<{ draftTr: FoDraftTrState; partyId: string }> = ({ draftTr, partyId }) => {
  const { state, party, party_tracking_id: partyTrId } = draftTr;

  /**
   * Do we need to show a waiting page?
   */
  if (isWaitingState({ party, state })) {
    return <WaitingPage party={party} partyId={partyId} />;
  }

  if (state === "PROCESSING" || state === "PROCESSING_ERROR") {
    return <ProcessingPage party={party} partyId={partyId} state={state} />;
  }

  if (state === "CANCELLED") return <Redirect to={GenerateMpRoute({ id: partyId, isCancelled: true })} />;
  /**
   * Exception: if we reached state === 'COMPLETED'
   * - then we want to remove the stored locale, because we want normal behaviour (and we have a lang picker there)
   * - We want to redirect to the party_tracking_id, which has to be present
   */
  if (state === "COMPLETED" && partyTrId) {
    removeStoredLocale();
    return <Redirect to={`/${partyTrId}`} />;
  }
  /**
   * Proceed to flows
   */
  if (state === "FEASIBLE" || state === "NOT_FEASIBLE") return <Feasible draftTr={draftTr} />;
  if (state === "SCHEDULE_BUYER") return <ScheduleBuyer />;
  if (state === "RESCHEDULE_BUYER") return <RescheduleBuyer draftTr={draftTr} />;
  if (state === "SCHEDULE_SELLER") return <ScheduleSeller draftTr={draftTr} />;
  /**
   * An unfortunate catch all
   * - state not handled: INIT, this is more an internal state
   * - COMPLETED state without party_tracking_id also ends up here (redirect is not possible without that id)
   */
  return <ErrorPage draftTr={draftTr} />;
};
