import * as React from "react";

export interface UsePersistedStateStorageOptions {
  storage: Storage;
  key: string;
  version?: number;
}

export interface UsePersistedStateOptions<S> extends UsePersistedStateStorageOptions {
  initialState?: S;
}

export interface UsePersistedStateData<S> {
  version: number;
  data: S;
}

function formatForStorage<S>(version: number, data: S): UsePersistedStateData<S> {
  return {
    version,
    data,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function usePersistedState<S>({ storage, key, initialState, version = 0.1 }: UsePersistedStateOptions<S>) {
  const storageKey = React.useMemo(() => `usePersistedState::${key}`, [key]);
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [state, setState] = React.useState(() => {
    try {
      const item = storage.getItem(storageKey);
      if (!item) return initialState;
      const { version: existingVersion, data } = JSON.parse(item) || {};
      const hasNewerVersion = parseFloat(existingVersion) !== version;
      if (hasNewerVersion) {
        // @TODO: add migration option
        storage.removeItem(storageKey);
        throw new Error(`Newer version for ${key} in persisted state. Over writing existing version.`);
      }
      return data;
    } catch (err) {
      // If err also return initialState
      // eslint-disable-next-line no-console
      console.error(err);
      return initialState;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const set = (value: S | ((prevState: S) => S)): void => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(state) : value;
      // Save state
      setState(valueToStore);
      // Save to local storage
      storage.setItem(storageKey, JSON.stringify(formatForStorage(version, valueToStore)));
    } catch (err) {
      // A more advanced implementation would handle the err case
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const remove = (): void => {
    try {
      setState(initialState);
      storage.removeItem(storageKey);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return [state, set, remove];
}
