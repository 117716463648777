import { Button, IconLoader, Message, Pill, Small, Spacer, Textarea, useForm } from "@brenger/react";
import cn from "classnames";
import isSameDay from "date-fns/isSameDay";
import React from "react";
import { useMutation, useQuery } from "react-query";

import { Modal, ModalProps } from "../../../components";
import { useFormatDate, useTranslationContext, useTransportContext } from "../../../hooks";
import { CacheKey, coreClient } from "../../../utils";
import { CoreViolation } from "@brenger/api-client";

interface Props extends ModalProps {
  tjalId: string;
  customerIri: string;
}

export const ChatModal: React.FC<Props> = (props) => {
  const formatDateShort = useFormatDate("date-short");
  const formatHourMinute = useFormatDate("hour-minute");
  const trContext = useTransportContext();

  const { t } = useTranslationContext();
  const form = useForm({
    initialState: { message: "" },
    validators: { message: (val) => !val },
  });

  const messages = useQuery(
    [CacheKey.RETRIEVE_MESSAGES, props.tjalId],
    () => coreClient.transportJobAccountLinks.listMessages({ id: props.tjalId || "" }),
    {
      enabled: !!props.tjalId && props.isActive,
    }
  );

  const totalItems = messages.data?.["hydra:totalItems"];

  // Scroll to bottom, every time the messages are updated
  const modalBottomRef = React.useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) node.scrollIntoView({ behavior: "smooth" });
    },
    [totalItems]
  );

  const createMessage = useMutation(coreClient.messages.createCustomerMessage, { onSuccess: () => messages.refetch() });

  // Note: Must useMemo in order to prevent any jitter from the "reverse" on each re-render.
  // Should only run whenever a new message enters the list. Should NOT run when user types something.
  const messageList = React.useMemo(() => {
    return (messages.data?.["hydra:member"] || []).reverse();
  }, [totalItems]);

  const onSubmit = (): void => {
    const value = form.data.message.value;

    // Prevent key press event from submitting empty text values
    if (!value) return;

    createMessage.reset();

    // When there is no previous messages use tr context
    let itemSetIri = trContext.tr?.item_sets[0]["@id"];
    let tjalIri = trContext.acceptedTjal?.["@id"];

    if (messageList.length > 0) {
      itemSetIri = messageList?.[0]?.item_set;
      tjalIri = messageList?.[0]?.transport_job_account_link["@id"];
    }

    if (!trContext.tr || !trContext.tr.customer || !tjalIri || !itemSetIri) {
      return;
    }
    createMessage.mutate({
      author: null,
      customer: props.customerIri || trContext.tr.customer["@id"],
      body: form.data.message.value,
      item_set: itemSetIri,
      transport_job_account_link: tjalIri,
    });
    form.reset();
  };

  const messageErr = (createMessage.error as undefined | CoreViolation)?.message;
  return (
    <Modal {...props}>
      <h3>{t((d) => d.chat)}</h3>
      <Spacer h={4} />
      {messageList.length === 0 && messages.isLoading && (
        <div className={cn("flex", "items-center", "justify-center", "h-48")}>
          <IconLoader className={cn("h-8", "w-8")} />
        </div>
      )}
      <div className={cn("grid", "grid-cols-4", "gap-4")}>
        {messageList.map((message, idx) => {
          const isMine = message["@type"] === "CustomerMessage";
          // Messages comes in sorted on latest last, so check against previous message, not the next one.
          const nextMessageDate = messageList?.[idx - 1]?.created_at;
          const printDate =
            !nextMessageDate || !isSameDay(new Date(message.created_at as string), new Date(nextMessageDate));

          return (
            <React.Fragment key={message["@id"]}>
              {printDate && (
                <div className={cn("col-span-4", "text-center")}>
                  <Pill type="gray-outline" className={cn("mx-2")}>
                    {isSameDay(new Date(), new Date(message.created_at as string))
                      ? t((d) => d.today)
                      : formatDateShort(message.created_at as string)}
                  </Pill>
                </div>
              )}
              <div
                className={cn("border", "border-gray-500", "rounded-md", "p-4", "col-span-3", {
                  "col-start-2": isMine,
                })}
              >
                {/* eslint-disable-next-line */}
                <div dangerouslySetInnerHTML={{ __html: message.body }} />
                <Small className={cn("text-right", "text-gray-500")}>
                  <div>{formatHourMinute(message.created_at as string)}</div>
                </Small>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <Spacer h={8} />
      <Textarea
        className={cn("w-full")}
        onChange={(message) => form.set({ message })}
        value={form.data.message.value}
        onKeyPress={(evt) => {
          // NOTE: allow users to enter messsage by simply hitting Enter
          if (evt.key === "Enter") {
            evt.preventDefault();
            onSubmit();
          }
        }}
      />
      {messageErr && <Message type="error">{messageErr}</Message>}
      <Spacer h={1} />
      <Button
        buttonType="secondary"
        className={cn("w-full")}
        disabled={form.hasErrors}
        loading={createMessage.isLoading}
        onClick={onSubmit}
      >
        {t((d) => d.submit)}
      </Button>
      {/* NOTE: nice little hack - use a div at bottom of modal as a ref to scroll into view! */}
      <div ref={modalBottomRef} />
    </Modal>
  );
};
