import React from "react";
import cn from "classnames";

import { Dialog } from ".";
import { useTranslationContext } from "../hooks";

export interface ModalProps {
  children?: React.ReactNode;
  isActive: boolean;
  closeHandler(): void;
  /**
   * Useful double-checking with user if they would TRULY like to close upon click away.
   * Common when user has already entered data in a form and then clicks away.
   */
  confirmClickAway?: boolean;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { t } = useTranslationContext();

  return (
    <Dialog isActive={props.isActive}>
      <div
        className={cn("relative", "w-screen", "h-screen")}
        style={{
          // This rgba value matches that of the PWA modal's own background opacity.
          // This ensures smooth/consistent UX when go between PWA elements and our own modals.
          backgroundColor: "rgba(0,0,0,0.32)",
        }}
      />
      <div
        className={cn("absolute", "inset-0", "overflow-y-auto")}
        onClick={() => {
          if (props.confirmClickAway) {
            const hasConfirmed = window.confirm(t((d) => d.agree));
            if (hasConfirmed) props.closeHandler();
          } else {
            props.closeHandler();
          }
        }}
      >
        <div className={cn("relative", "w-full", "h-full")}>
          <div
            /**
             * Stop propagating the click event from inside the modal's content.
             * If we don't do this, then any click inside the modal will also cause it to close.
             * Instead, we listen for a click event on the inset-0 parent (the area outside the modal)
             */
            onClick={(evt) => evt.stopPropagation()}
            style={{ left: "50%", transform: "translateX(-50%)", scrollMargin: "12px" }}
            className={cn(
              // must give text color here (in addition to div wrapping whole app) bc this is mounted via a reacat portal!
              "text-gray-800",
              "absolute",
              "overflow-y-scroll",
              "px-6",
              // A little extra top-padding to account for "sluiten" text in top-right of modal.
              "pt-8",
              "pb-6",
              "bg-white",
              "shadow-2xl",
              "max-w-2xl",
              "rounded",
              "w-full",
              "top-0",
              "h-full",
              "min-h-screen",
              // These are the responsive views for anything larger than a "sm" viewport.
              "sm:my-12",
              "sm:h-auto",
              "sm:min-h-0",
              "sm:w-10/12"
            )}
          >
            {/* On small viewports the modal becomes full width so we wantt to show a "close" button. */}
            <div className={cn("flex", "absolute", "right-0", "top-0")}>
              <button className={cn("mr-3", "mt-2", "underline", "text-blue-600")} onClick={props.closeHandler}>
                {t((d) => d.close)}
              </button>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
