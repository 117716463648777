import { Button, InputRange, Spacer, useForm } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { Modal, ModalProps } from "../../../components";
import { Config } from "../../../config";
import { useTranslationContext } from "../../../hooks";
import { coreClient } from "../../../utils";
import { StepType } from "./ReviewModalRoot";

enum Field {
  SERVICE_RECOMMENDATION_SCORE = "service_recommendation_score",
}

const initialState = {
  [Field.SERVICE_RECOMMENDATION_SCORE]: 0,
};

const validators = {
  [Field.SERVICE_RECOMMENDATION_SCORE]: (val: number) => !val,
};

interface Props extends ModalProps {
  reviewId: string;
  setStep: (step: StepType) => void;
}

export const ReviewModalStep3: React.FC<Props> = (props) => {
  const { t } = useTranslationContext();
  const form = useForm({ initialState, validators });

  const updateReview = useMutation(coreClient.reviews.update, {
    onSuccess: (updatedReview) => {
      // Amount of new/organic positive reviews was getting to low, so decided to bring this logic back and ask for google reviews only
      // Plus lowered the treshold when to ask for a review from >4 to >=4
      if (updatedReview.communication >= 4 && updatedReview.punctuality >= 4 && updatedReview.friendliness >= 4) {
        const reviewUrl = Math.random() < 0.5 ? Config.GOOGLE_REVIEW_URL : Config.TRUST_PILOT_REVIEW_URL;
        window.open(reviewUrl, "_blank", "menubar=1,resizable=1,width=600,height=728");
      }
      // Close the modal.
      props.closeHandler();
    },
  });

  return (
    <Modal {...props}>
      <h3>{t((d) => d.public_review_title)}</h3>
      <Spacer h={4} />
      <div>{t((d) => d.public_review_desc)}</div>
      <Spacer h={6} />
      <div>
        <h5>{t((d) => d.review_questions[Field.SERVICE_RECOMMENDATION_SCORE])}</h5>
        <InputRange
          withScale={true}
          min={1}
          max={10}
          value={form.data.service_recommendation_score.value}
          onChange={(val) => form.set({ service_recommendation_score: Number(val) })}
        />
      </div>
      <Spacer h={8} />
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-3", "mt-3")}>
        <Button buttonType="primary-outline" disabled={updateReview.isLoading} onClick={props.closeHandler}>
          {t((d) => d.no)}
        </Button>
        <Button
          disabled={form.hasErrors}
          loading={updateReview.isLoading}
          onClick={() => {
            updateReview.reset();
            updateReview.mutate({
              id: props.reviewId,
              service_recommendation_score: form.data[Field.SERVICE_RECOMMENDATION_SCORE].value,
            });
          }}
        >
          {t((d) => d.submit)}
        </Button>
      </div>
    </Modal>
  );
};
