"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHouseNumberIncludingSuffixFromLine1 = exports.getFloorLevelFromHouseNumberSuffix = void 0;
var getFloorLevelFromHouseNumberSuffix = function (houseNumber) {
    var suffix = houseNumber
        .trim()
        // filter out first part - the house number itself
        // example: 122-bis => filters out "122"
        .replace(/^\d+/, '')
        // filter out any special characters (hyphens, spaces etc)
        // example: leftover is -bis => filters out "-""
        .replace(/[^.a-zA-Z\d]/, '');
    // if we don't have a suffix return
    if (!suffix) {
        return;
    }
    // in some cases we deal with a numerical suffix
    var suffixAsNumber = Number(suffix);
    if (!isNaN(suffixAsNumber)) {
        // Assumption alert
        // if suffix is 4 or lower, we assume a floor level indication
        if (suffixAsNumber < 5) {
            return suffixAsNumber;
        }
        // Assumption alert
        // The number is 5 or higher, most likely to indicate a business unit or appartement number, but not nescessarily a floor level
        return;
    }
    var suffixAsLetters = suffix.toLowerCase();
    // We couldn't parse out any number, so we deal with letters as suffix, we try to match it with the most common ones, else we end up with undefined
    var floorLevelSuffixMap = {
        a: 1,
        i: 1,
        b: 1,
        bis: 1,
        ii: 2,
        c: 3,
        iii: 3,
        d: 4,
        iv: 4
    };
    return floorLevelSuffixMap[suffixAsLetters];
};
exports.getFloorLevelFromHouseNumberSuffix = getFloorLevelFromHouseNumberSuffix;
var getHouseNumberIncludingSuffixFromLine1 = function (line1) {
    var _a;
    // First check if it has numbers
    if (!new RegExp(/\d/).test(line1)) {
        return '';
    }
    // Filter out number including suffix
    // example: Werner helmichstraat 14 => 14
    // example: 28-a Werner helmichstraat => 28-a
    return (((_a = line1.match(/[\d]+[-\s]?([i]{0,3})?(\d+|iv|a|b|bis)?/gi)) === null || _a === void 0 ? void 0 : _a[0]) || '').trim();
};
exports.getHouseNumberIncludingSuffixFromLine1 = getHouseNumberIncludingSuffixFromLine1;
