import React from "react";
import { MpContainer, MpLayout } from "./Layout";
import { Message } from "@brenger/react";

export const DefaultError: React.FC = () => {
  return (
    <MpLayout>
      <MpContainer>
        <Message type="error">Something went wrong</Message>
      </MpContainer>
    </MpLayout>
  );
};
