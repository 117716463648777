import { Message } from "@brenger/react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslationContext } from "../../hooks";
import { PAYMENT_STATUS_CANCELED, PAYMENT_STATUS_PARAM } from "../../pages/TransportDetails/PaymentCancelledModal";
import { MpContainer } from "./Layout";

export const PaymentStateError: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = React.useState<null | string>(null);
  const { t } = useTranslationContext();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // See if we are dealing with a failed payment, set an error and replace the error so the search params gets removed
    if (searchParams.get(PAYMENT_STATUS_PARAM) === PAYMENT_STATUS_CANCELED) {
      setError(t((d) => d.fo.payment_flow.error_payment_cancelled));
      history.replace(location.pathname);
    }
  }, [location.search]);

  if (!error) return null;

  return (
    <MpContainer>
      <Message type="error">{error}</Message>
    </MpContainer>
  );
};
