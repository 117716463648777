import { SourceFlow } from "@brenger/api-client";
import { Button } from "@brenger/react";
import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useFreshChat, useTranslationContext } from "../../hooks";
import { Routes } from "../../utils";
import { trackAndTag } from "../../utils/eventTracking";
import { useDraftTr } from "../hooks/useDraftTr";
import "./ChatButton.scss";

interface Props {
  onClose?(): void;
}

export const CHAT_HIDDEN_CLASS = "fc-hidden";

export const ChatButton: React.FC<Props> = ({ onClose }) => {
  const fc = useFreshChat();
  const [isFcUserReady, setIsFcUserReady] = React.useState(false);
  const [isChatHidden, setIsChatHidden] = React.useState(document.body.classList.contains(CHAT_HIDDEN_CLASS));
  const { t } = useTranslationContext();
  const location = useLocation();
  const match = matchPath<{ id: string }>(location.pathname, {
    path: Routes.MARKTPLAATS,
    exact: false,
  });
  const partyId = match?.params?.id as string;
  const draftTr = useDraftTr({ partyId });
  const party = draftTr.data?.party;

  React.useEffect(() => {
    if (fc) {
      window.fcWidget?.on("widget:closed", () => {
        onClose?.();
      });
      window.fcWidget?.on("user:created", () => {
        setIsFcUserReady(true);
      });
    }
  }, [fc]);

  React.useEffect(() => {
    if (!party || !isFcUserReady) return;
    /**
     * NOTE: We use setProps and update at the same time, this is to enforce it when we are dealing with an existing user
     */
    window.fcWidget?.user?.setProperties?.({ source: SourceFlow.MARKTPLAATS, party });
    window.fcWidget?.user?.update?.({ source: SourceFlow.MARKTPLAATS, party });
  }, [isFcUserReady, party]);

  const toggleChat = (): void => {
    // handle visual state
    if (isChatHidden) {
      document.body.classList.remove(CHAT_HIDDEN_CLASS);
      fc?.open();
      trackAndTag({ event: "FO Interaction", type: "Started chat", data: party || "", partyId });
    } else {
      document.body.classList.add(CHAT_HIDDEN_CLASS);
    }
    // toggle chat state
    setIsChatHidden(!isChatHidden);
  };

  return (
    <Button loading={!fc} disabled={!fc} className={"w-full mt-8"} buttonType="secondary" onClick={toggleChat}>
      {isChatHidden ? t((d) => d.fo.help_modal.start_chat) : t((d) => d.fo.help_modal.hide_chat)}
    </Button>
  );
};
