import { IRI } from "@brenger/api-client";
import { Button, Card, IconInfoCircle, IconPlus } from "@brenger/react";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useTranslationContext, useFilePicker } from "../../hooks";
import { getImageData } from "../../utils";
import { useMutation } from "react-query";

interface FileResource extends IRI<string> {
  download_url: string;
}

export type PickFile = {
  label: string;
  saveFile: (imageId: string[]) => void;
  client: (args: { imageData: FormData }) => Promise<FileResource>;
  canSelectMultiple?: boolean;
  openHelpModal?: () => void;
};
export const FilePicker: React.FC<PickFile> = ({ label, client, saveFile, canSelectMultiple, openHelpModal }) => {
  const { t } = useTranslationContext();
  const filePicker = useFilePicker();
  const files = filePicker.files;
  const [fileIds, setFileIds] = useState<string[]>([]);
  const createFile = useMutation(client, {
    onSuccess: (file) => setFileIds((prevState) => prevState.concat(file["@id"])),
  });

  useEffect(() => {
    if (files) {
      files.map((file) => createFile.mutate({ imageData: getImageData(file) }));
    }
  }, [files]);

  useEffect(() => {
    if (fileIds.length > 0) {
      saveFile(fileIds);
    }
  }, [fileIds]);

  return (
    <Card type="primary">
      <div className={openHelpModal && cn("flex", "items-center")}>
        {openHelpModal && (
          <IconInfoCircle
            onClick={() => openHelpModal()}
            className={cn("w-6", "h-6", "sm:w-5", "sm:h-5", "mr-2", "text-blue-600", "cursor-pointer")}
          />
        )}
        {label}
      </div>
      <Button
        className={cn("w-full", "mt-4")}
        onClick={filePicker.onClick}
        loading={createFile.isLoading}
        icon={<IconPlus />}
      >
        {t((d) => d.add)}
      </Button>
      {files &&
        files.length > 0 &&
        files.map((e) => (
          <img
            key={URL.createObjectURL(e)}
            style={{ maxHeight: 250 / files.length }}
            className={cn("mt-4", "rounded")}
            src={URL.createObjectURL(e)}
          />
        ))}
      <filePicker.HiddenFileInput multiple={canSelectMultiple} />
    </Card>
  );
};
