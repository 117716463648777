import React from "react";

export const IconCheckCircle: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9.5 0C4.24535 0 0 4.24045 0 9.5C0 14.7595 4.24045 19 9.5 19C14.7595 19 19 14.7595 19 9.5C19 4.24045 14.7547 0 9.5 0ZM9.5 17.7298C4.94075 17.7298 1.27019 14.0592 1.27019 9.49996C1.27019 4.94071 4.94079 1.27015 9.5 1.27015C14.0592 1.27015 17.7298 4.94071 17.7298 9.49996C17.7298 14.0592 14.0592 17.7298 9.5 17.7298Z" />
      <path d="M14.0593 6.71331C13.8081 6.46211 13.4266 6.46211 13.1706 6.71327L8.29745 11.5865L5.82944 9.1185C5.57831 8.86737 5.19674 8.86737 4.94079 9.1185C4.68966 9.36963 4.68966 9.75116 4.94079 10.0072L7.85306 12.9194C7.97865 13.045 8.10419 13.1078 8.29741 13.1078C8.48576 13.1078 8.61617 13.045 8.74175 12.9194L14.0593 7.60196C14.3104 7.35084 14.3104 6.96934 14.0593 6.71331Z" />
    </svg>
  );
};
