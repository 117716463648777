import React from "react";
import MarktplaatsLogo from "../assets/marktplaats.svg";
import SpeedingVan from "../assets/speeding_van.svg";
import { BrengerLogo } from "./BrengerLogo";
import "./Loader.scss";
import { getSearchParam } from "@brenger/utils";

const STORAGE_KEY = "brenger_seen_branding";
const isEmailMedium = getSearchParam("utm_medium") === "email";

export const MpLoader: React.FC<{ partyId: string }> = ({ partyId }) => {
  const [showLoader, setShowLoader] = React.useState(true);

  const s = localStorage.getItem(STORAGE_KEY);
  const ids: string[] = s ? JSON.parse(s) : [];
  const dontShowLoader = ids.includes(partyId) || isEmailMedium;

  React.useEffect(() => {
    if (!ids.includes(partyId) && isEmailMedium) localStorage.setItem(STORAGE_KEY, JSON.stringify([...ids, partyId]));
    if (dontShowLoader) return;
    setTimeout(() => {
      setShowLoader(false);
      localStorage.setItem(STORAGE_KEY, JSON.stringify([...ids, partyId]));
    }, 3000);
  }, [partyId]);

  if (dontShowLoader || showLoader === false) {
    return null;
  }

  return (
    <div className="h-screen w-screen bg-blue-600 fixed z-50 flex flex-col items-center">
      <div style={{ width: "194px" }} className="grow flex flex-col items-center justify-center text-white">
        <BrengerLogo className="mb-6 mx-auto" width={147} height={40} />
      </div>
      <div className="mt-auto w-full">
        <div style={{ height: "48px" }} className="relative">
          <img src={SpeedingVan} className={"absolute van"} />
        </div>
        <div className="bg-white border-t-[10px] border-green-400 p-4 text-center">
          <div className="inline-block mx-auto">
            Partner van <img src={MarktplaatsLogo} className="inline-block" />
          </div>
        </div>
      </div>
    </div>
  );
};
