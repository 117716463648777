import React from "react";
import { useTranslationContext } from "../../hooks";

interface Props {
  elevator?: boolean;
  level?: number;
}

export const FloorText: React.FC<Props> = ({ elevator, level }) => {
  const { t } = useTranslationContext();
  // Display elevator
  if (elevator) return t((d) => d.fo.service.selected_elevator);
  // Any type of Floor level
  if (typeof level === "number") {
    if (level === -1) return t((d) => d.basement);
    if (level === 0) return t((d) => d.ground_floor);
    return t((d, template) => template(d.floor_level, { floor_level: level }));
  }
  // Default to null
  return null;
};
