import React from "react";
import { useQueryClient } from "react-query";
import { MpThankYouFeasible } from "./Pages/ThankYou/Feasible";
import { ThankYouRescheduleBuyer } from "./Pages/ThankYou/RescheduleBuyer";
import { ThankYouScheduleBuyer } from "./Pages/ThankYou/ScheduleBuyer";
import { ThankYouScheduleSeller } from "./Pages/ThankYou/ScheduleSeller";
import { ThankYouScheduleSellerNewDates } from "./Pages/ThankYou/ScheduleSellerNewDates";
import { S_KEY_SELECT_SERVICE_OUTCOME } from "./hooks/useExtraService";
import { MpThankYouRouteComponentProps, MpThankYouStates } from "./routes";

export const ThankYou: React.FC<MpThankYouRouteComponentProps> = (props) => {
  const queryClient = useQueryClient();
  /**
   * Every time a thank you page is loaded we want a clean slate.
   */
  React.useEffect(() => {
    // Force clear cache on queryclient so from here all FO data will so fresh and so clean
    queryClient.clear();
    // Removing form and or storage, so all forms start fresh and payment data is removed
    ["BRENGER_FORM", S_KEY_SELECT_SERVICE_OUTCOME].forEach((key) => {
      localStorage.removeItem(key);
      sessionStorage.removeItem(key);
    });
  }, []);

  // FO refers to a url were we can read the state from.
  // Maybe urls get normalized, all states should be uppercase, so let's be sure
  const state = props.match.params.state.toUpperCase() as MpThankYouStates;

  /**
   * FO returns after payment to a thank you page with current state, which is one state ahead of were the flow left of.
   * Two states need payment, so they need a render state correction:
   */
  let renderState: MpThankYouStates = state;
  /** Sent by FO */
  if (state === "SCHEDULE_BUYER") renderState = "FEASIBLE";
  if (state === "SCHEDULE_SELLER") renderState = "SCHEDULE_BUYER";
  /** Redirect from schedule seller flow, but because FO already sents SCHEDULE_SELLER we rebranded this to COMPLETED state */
  if (state === "COMPLETED") renderState = "SCHEDULE_SELLER";

  // Render thank you page based on render state
  if (renderState === "FEASIBLE") return <MpThankYouFeasible id={props.match.params.id} />;
  if (renderState === "SCHEDULE_BUYER") return <ThankYouScheduleBuyer id={props.match.params.id} />;
  if (renderState === "SCHEDULE_SELLER") return <ThankYouScheduleSeller trId={props.match.params.trId} />;
  if (renderState === "SCHEDULE_SELLER_NEW_DATES") return <ThankYouScheduleSellerNewDates id={props.match.params.id} />;
  if (renderState === "RESCHEDULE_BUYER") return <ThankYouRescheduleBuyer id={props.match.params.id} />;
  return null;
};
