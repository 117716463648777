import { IconLoader } from "@brenger/react";
import React from "react";

/**
 * Price coming from the external api is excluding VAT
 */
export const Price: React.FC<{ price?: string; isLoading?: boolean; isExVat?: boolean }> = ({ price, isLoading }) => {
  if (!price) return null;

  const [euros, cents] = price.split(".");

  const addCents = cents && cents !== "00";
  if (isLoading) {
    return <IconLoader />;
  }
  return (
    <span className="inline-block relative">
      &euro; {euros}
      {addCents && (
        <>
          {","}
          <sup className="relative" style={{ top: "-0.2em" }}>
            {cents}
          </sup>
        </>
      )}
    </span>
  );
};
