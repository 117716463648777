import * as React from "react";
import { ProductPaymentStatus } from "@brenger/api-client";
import { Pill, PillType } from "@brenger/react";

import { useTranslationContext } from "../hooks";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  status: ProductPaymentStatus;
}

export const ProductPaymentStatusPill: React.FC<Props> = ({ status, ...unused }) => {
  const { t } = useTranslationContext();

  let type: PillType = "green";

  switch (status) {
    case "profile_incomplete":
      type = "yellow";
      break;
    case "verification_pending":
      type = "yellow";
      break;
    case "verification_failed":
      type = "red";
      break;
    case "ready_for_payout":
      type = "blue";
      break;
    case "payout_released":
      type = "green";
      break;
    case "refunded":
      type = "green";
      break;
  }

  return (
    <Pill type={type} {...unused}>
      {t((d) => d.product_payment_status[status])}
    </Pill>
  );
};
