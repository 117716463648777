import { Button, useModalState } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useTranslationContext, useTransportContext } from "../../../hooks";
import { SUPPORTED_LOCALE_CUSTOMER_MAP } from "../../../utils";
import { ConfirmDeliveryModal } from "./ConfirmDeliveryModal";
import { ConfirmPickupModal } from "./ConfirmPickupModal";
import { ReviewModalRoot } from "./ReviewModalRoot";

export const FeedbackButtons: React.FC = () => {
  const { t, i18n } = useTranslationContext();
  const createReviewModal = useModalState();
  const confirmPickupModal = useModalState();
  const confirmDeliveryModal = useModalState();

  const supportedLocale = SUPPORTED_LOCALE_CUSTOMER_MAP.get(i18n.locale) || "en-nl";

  const complaintFormUrl = {
    nl: "https://docs.google.com/forms/d/e/1FAIpQLSdwOyp9EQWaurS0YYvI2b_n8bCSKKAbRbXY7ojnAPFSxk8AAw/viewform",
    "nl-be": "https://docs.google.com/forms/d/e/1FAIpQLSdwOyp9EQWaurS0YYvI2b_n8bCSKKAbRbXY7ojnAPFSxk8AAw/viewform",
    "en-nl": "https://docs.google.com/forms/d/e/1FAIpQLSczbzE2MPF_uYpisc6IgFp3LenQi8R-37v4iaJY2eW5yMAdjw/viewform",
    "de-de": "https://docs.google.com/forms/d/e/1FAIpQLSc8Gl2p7KXFH2Qwx5gZDImslD4Gt6OUBXuf25NhoHKra3BH3w/viewform",
  }[supportedLocale];

  const damageFormUrl = {
    nl: "https://docs.google.com/forms/d/e/1FAIpQLSeEZ0p-q9D1BeUr3RENYuCCnpeYIzUWyNjcME5PmzZzOjGUUg/viewform",
    "nl-be": "https://docs.google.com/forms/d/e/1FAIpQLSeEZ0p-q9D1BeUr3RENYuCCnpeYIzUWyNjcME5PmzZzOjGUUg/viewform",
    "en-nl": "https://docs.google.com/forms/d/e/1FAIpQLScD36vPzf1JsVh8IuCsYonUfhmE87ONbsijEp8VaZV7tFsUYQ/viewform",
    "de-de": "https://docs.google.com/forms/d/e/1FAIpQLSczLZ41WdrqF3zmQaYzdSDMjEND_XhJLoSXEykl0plSRRKF5A/viewform",
  }[supportedLocale];

  const { contactType, potentialAction } = useTransportContext();

  const canCreateReview = potentialAction.some((action) => {
    if (contactType === "customer") return action["@type"] === "CustomerAddReview";
    return action["@type"] === "DeliveryContactAddReview";
  });

  const canConfirmPickup = potentialAction.some((action) => action["@type"] === "CustomerPickupConfirmation");
  const canConfirmDelivery = potentialAction.some((action) => action["@type"] === "CustomerDeliveryConfirmation");
  const canLeaveComplaint = potentialAction.some((action) =>
    ["CustomerAddComplaint", "PickupContactAddComplaint", "DeliveryContactAddComplaint"].includes(action["@type"])
  );

  // Nothing to render when none of the CTAs can be rendered for this particular user.
  if (!canConfirmPickup && !canConfirmDelivery && !canCreateReview && !canLeaveComplaint) return null;

  return (
    <>
      <div className={cn("grid", "sm:grid-cols-2", "lg:grid-cols-4", "grid-cols-1", "gap-3", "mt-4")}>
        {canConfirmPickup && (
          <Button onClick={confirmPickupModal.open} buttonType="secondary">
            {t((d) => d.confirm_pickup_cta)}
          </Button>
        )}
        {canConfirmDelivery && (
          <Button onClick={confirmDeliveryModal.open} buttonType="secondary">
            {t((d) => d.confirm_delivery_cta)}
          </Button>
        )}
        {canCreateReview && <Button onClick={createReviewModal.open}>{t((d) => d.give_review_cta)}</Button>}
        {canLeaveComplaint && (
          <>
            <a href={damageFormUrl as string} rel="noreferrer" target="_blank">
              <Button className={"w-full"} buttonType="primary-outline">
                {t((d) => d.complaints.cta_damage)}
              </Button>
            </a>
            <a href={complaintFormUrl as string} rel="noreferrer" target="_blank">
              <Button className={"w-full"} buttonType="primary-outline">
                {t((d) => d.complaints.cta_other)}
              </Button>
            </a>
          </>
        )}
      </div>
      <ConfirmDeliveryModal isActive={confirmDeliveryModal.isActive} closeHandler={confirmDeliveryModal.close} />
      <ConfirmPickupModal isActive={confirmPickupModal.isActive} closeHandler={confirmPickupModal.close} />
      <ReviewModalRoot isActive={createReviewModal.isActive} closeHandler={createReviewModal.close} />
    </>
  );
};
