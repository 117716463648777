import { TransportJobAccountLink } from "@brenger/api-client";
import { Button, Spacer, useModalState } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { DriverDetails, IconEnvelope } from "../../../components";
import { useTranslationContext, useTransportContext } from "../../../hooks";
import { coreClient } from "../../../utils";
import { ChatModal } from "./ChatModal";

interface Props {
  tjal: TransportJobAccountLink;
}

const PendingDriverListItem: React.FC<Props> = ({ tjal }) => {
  const { t } = useTranslationContext();
  const chatModal = useModalState();
  const { tr, refreshTjalList } = useTransportContext();

  const reject = useMutation(coreClient.transportJobAccountLinks.reject, { onSuccess: refreshTjalList });

  const temporaryAccept = useMutation(coreClient.transportJobAccountLinks.accept, {
    onSuccess: ({ payment_redirect_url }) => {
      if (payment_redirect_url) {
        // when payment is needed the payment url is set
        window.location.assign(payment_redirect_url);
      } else {
        // When no payment is required, the driver offer gets accepted and we just need a fresh list
        refreshTjalList();
      }
    },
  });

  const tjalId = getIdFromIri(tjal["@id"]) || "";
  const customerIri = tr?.customer["@id"];

  return (
    <>
      <div className={cn("p-4", "border", "border-blue-600", "rounded")}>
        <div className={cn("flex", "justify-between", "flex-wrap")}>
          <div className={cn("mr-4", "mb-2")}>
            <DriverDetails driverUser={tjal.driver_user} />
          </div>
          <div>
            <div className={cn("relative")}>
              <span
                className={cn(
                  "animate-ping",
                  "absolute",
                  "w-3",
                  "h-3",
                  "top-0",
                  "right-0",
                  "rounded-full",
                  "bg-red-600",
                  "opacity-75"
                )}
              />
              <span className={cn("absolute", "w-3", "h-3", "top-0", "right-0", "rounded-full", "bg-red-600")} />
              <button
                className={cn(
                  "border",
                  "border-blue-600",
                  "text-blue-600",
                  "rounded-full",
                  "p-3",
                  "flex-shrink-0",
                  "disabled:opacity-50"
                )}
                onClick={chatModal.open}
              >
                <IconEnvelope className={cn("w-5", "h-5")} />
              </button>
            </div>
          </div>
        </div>
        <div className={cn("flex", "mt-4")}>
          <Button
            disabled={reject.isLoading}
            loading={temporaryAccept.isLoading}
            onClick={() => temporaryAccept.mutate({ id: tjalId })}
          >
            {t((d) => d.confirm)}
          </Button>
          <Spacer w={2} h={2} />
          <Button
            disabled={temporaryAccept.isLoading}
            loading={reject.isLoading}
            buttonType="primary-outline"
            onClick={() => reject.mutate({ id: tjalId || "" })}
          >
            {t((d) => d.reject)}
          </Button>
        </div>
      </div>
      {tjalId && customerIri && (
        <ChatModal
          isActive={chatModal.isActive}
          closeHandler={chatModal.close}
          customerIri={customerIri}
          tjalId={tjalId}
        />
      )}
    </>
  );
};

export const PendingDriverList: React.FC = () => {
  const { pendingTjalList = [] } = useTransportContext();

  return (
    <>
      <div className={cn("grid", "grid-cols-1", "lg:grid-cols-2", "gap-4")}>
        {pendingTjalList.map((tjal, idx) => {
          return <PendingDriverListItem key={idx} tjal={tjal} />;
        })}
      </div>
    </>
  );
};
