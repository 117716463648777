import { Button, IconLoader, InputToggle, Label } from "@brenger/react";
import { parseApiDate } from "@brenger/utils";
import React from "react";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { CheckmarkList } from "../../../components";
import { useFormatDate, useTranslationContext } from "../../../hooks";
import { CacheKey, foClient } from "../../../utils";
import { EmailMessage, PageHead, TrDetails } from "../../components";
import { MpContainer, MpFooter } from "../../components/Layout";
import { useContact } from "../../hooks/useContact";
import { useDraftTr } from "../../hooks/useDraftTr";
import { useSellerDates } from "../../hooks/useSellerDates";
import { GenerateMpRoute, MpRouteComponentProps } from "../../routes";
import { signUpToNewsletter } from "../Feasible/FeasiblePayment";

export const ScheduleSellerSummary: React.FC<MpRouteComponentProps> = (props) => {
  const { t } = useTranslationContext();
  const history = useHistory();
  const partyId = props.match.params.id;
  const draftTr = useDraftTr({ partyId });
  const dates = useSellerDates({ partyId });
  const contact = useContact({ partyId });
  const formatDate = useFormatDate("weekday-month-long");

  const [optIn, setOptIn] = React.useState(false);

  const complete = useMutation(
    [CacheKey.FO_TRIGGER_COMPLETE, "schedule_seller", partyId],
    foClient.marktplaats.schedulingComplete,
    {
      onSuccess: async () => {
        if (optIn) {
          await signUpToNewsletter(partyId, "seller");
        }
        history.push(
          GenerateMpRoute({
            id: props.match.params.id,
            page: { type: "thank_you", state: "COMPLETED", trId: draftTr.data?.party_tracking_id as string },
          })
        );
      },
    }
  );

  const isLoading = draftTr.isLoading || dates.isLoading || contact.isLoading;
  const editText = t((d) => d.fo.summary.edit).toLowerCase();
  return (
    <>
      <PageHead
        title={t((d) => d.fo.schedule_seller.summary.title)}
        sub={t((d) => d.fo.schedule_seller.summary.sub_title)}
      />
      {isLoading && (
        <MpContainer>
          <IconLoader />
        </MpContainer>
      )}
      {!isLoading && (
        <>
          {dates.selected && (
            <MpContainer>
              <h5 className="pb-3 flex justify-between">
                {t((d) => d.fo.schedule_seller.summary.date)}
                <Link
                  to={GenerateMpRoute({ id: partyId, page: { type: "flows", state: "SCHEDULE_SELLER", progress: 1 } })}
                >
                  <small className="text-blue-600 br-font">{editText}</small>
                </Link>
              </h5>
              <div className="first-letter:uppercase">{formatDate(parseApiDate(dates.selected))}</div>
            </MpContainer>
          )}
          <MpContainer>
            <h5 className="pb-3 flex justify-between">
              {t((d) => d.fo.schedule_buyer.summary.contact_title)}{" "}
              <Link
                to={GenerateMpRoute({ id: partyId, page: { type: "flows", state: "SCHEDULE_SELLER", progress: 2 } })}
              >
                <small className="text-blue-600 br-font">{editText}</small>
              </Link>
            </h5>
            <div>{contact.data?.phone_number}</div>
            <div>{contact.data?.email}</div>
            {contact.data?.instructions && <div>{contact.data?.instructions}</div>}
          </MpContainer>
          <MpContainer>
            <h5 className="pb-3">{t((d) => d.fo.schedule_seller.summary.service)} </h5>
            <div className="mb-2">
              {t((d) => d.fo.service.standard)} {t((d) => d.fo.service.farfetchd.name)}
            </div>
            <CheckmarkList
              items={[
                t((d) => d.fo.schedule_seller.summary.service_text_1),
                t((d) => d.fo.schedule_seller.summary.service_text_2),
              ]}
            />
          </MpContainer>
          <MpContainer>
            <TrDetails partyId={partyId} />
          </MpContainer>
          {draftTr.data?.phase === "bike" && (
            <MpContainer>
              <div className="mb-2">
                <Label text={t((d) => d.fo.terms.news_letter_signup)} position={"right"}>
                  <InputToggle checked={optIn} onChange={() => setOptIn(!optIn)} />
                </Label>
              </div>
            </MpContainer>
          )}
          <MpContainer>
            <EmailMessage text={t((d) => d.fo.schedule_seller.summary.email_message)} />
          </MpContainer>
        </>
      )}
      <MpFooter>
        <MpContainer>
          <Button
            disabled={complete.isLoading}
            loading={complete.isLoading}
            className="w-full"
            buttonType="secondary"
            onClick={() => {
              complete.reset();
              complete.mutate({
                party: "seller",
                partyId,
              });
            }}
          >
            {t((d) => d.fo.schedule_seller.summary.button)}
          </Button>
        </MpContainer>
      </MpFooter>
    </>
  );
};
