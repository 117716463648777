/**
 * COPIED FROM: https://usehooks.com/useEventListener/
 */

import { useEffect, useRef } from "react";

// NOTE: to use this hook for new events, add the event name here.
type EventName = "visibilitychange";

export function useEventListener(eventName: EventName, handler: () => void, element = window): void {
  // Create a ref that stores handler
  const savedHandler = useRef<() => void>();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref
      const eventListener = (): void => {
        savedHandler.current?.();
      };
      // Add event listener
      element.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    // Re-run if eventName or element changes
    [eventName, element]
  );
}
