import "@brenger/react/index.css";
import "@brenger/react/fontClasses.css";
import "@brenger/react/fontFace.css";
import "babel-polyfill";
import { createRoot } from "react-dom/client";
import { fontLoader } from "@brenger/react";
import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, matchPath } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { App } from "./App";
import { ErrorBoundary } from "./components";
import { TranslationProvider } from "./hooks";
import { Routes, SANDBOX_BASENAME, isSandboxMode, logger } from "./utils";
import { removeStoredLocale } from "@brenger/utils";
import { Config } from "./config";

logger.init();
fontLoader();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000,
    },
  },
});

const Root: React.FC = () => {
  const defaultTitle = "Live | Brenger";
  const titleTemplate = "%s | Brenger";
  const basename = isSandboxMode() ? SANDBOX_BASENAME : undefined;

  // Remove stored and enforced locale
  // Best to do this before translation context
  React.useEffect(() => {
    if (!matchPath(window.location.pathname, { path: Routes.MARKTPLAATS as string })) {
      removeStoredLocale();
    }
  }, []);

  return (
    <>
      <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate} />
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          {Config.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
          <TranslationProvider>
            <Router basename={basename}>
              <App />
            </Router>
          </TranslationProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
};

const container = document.getElementById("app-root");
const root = createRoot(container!);
root.render(<Root />);
