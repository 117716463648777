import { FoDraftTrState, FoEditableItemFields, FoParty, SourceFlow } from "@brenger/api-client";
import {
  Button,
  IconClose,
  IconInfoCircle,
  IconPencil,
  InputCheckbox,
  Label,
  useForm,
  useModalState,
} from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { CheckmarkList, WordPressContent } from "../../../components";
import { useTranslationContext } from "../../../hooks";
import { CacheKey, coreClient, foClient, logger } from "../../../utils";
import CreditCardIcon from "../../assets/payment-icons/creditcard.svg";
import IdealIcon from "../../assets/payment-icons/ideal.svg";
import PaypalIcon from "../../assets/payment-icons/paypal.svg";
import { Address, Modal, MpCard, MpContainer, MpFooter, MpFooterCta, PageHead, Price } from "../../components";
import { DeliveryDateModal } from "../../components/modals/DeliveryDateModal";
import { EditItemModal } from "../../components/modals/EditItemModal";
import { NotFeasibleModal } from "../../components/modals/NotFeasibleModal";
import { ServiceModal } from "../../components/modals/ServiceModal";
import { MpRouteComponentProps } from "../../routes";
import { getTrackingPriceRange, trackAndTag, trackPurchase } from "../../../utils/eventTracking";
import { useScrollToError } from "../../hooks/useScrollToError";

export const FeasiblePayment: React.FC<MpRouteComponentProps & { draftTr: FoDraftTrState }> = ({ draftTr, match }) => {
  const partyId = match.params.id;

  const { t } = useTranslationContext();
  const form = useForm({
    persist: {
      type: "session",
      key: CacheKey.FORM_FEASIBLE as string,
    },
    initialState: {
      general_terms: false,
      privacy_terms: false,
      signup: false,
    },
    validators: {
      general_terms: (v) => !v,
      privacy_terms: (v) => !v,
    },
  });
  const { errorsVisible, errorVisibleAndScrollTo, errorAttr } = useScrollToError();
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const serviceModal = useModalState();
  const deliverModal = useModalState();
  const generalTermsModal = useModalState();
  const privacyTermsModal = useModalState();
  const transportTermsModal = useModalState();
  const editModal = useModalState();

  const [editableFields, setEditableFields] = React.useState<FoEditableItemFields>({
    length: draftTr.item.length,
    width: draftTr.item.width,
    height: draftTr.item.height,
    count: draftTr.item.count,
  });
  const updateItem = useMutation(foClient.marktplaats.updateItem, {
    onSuccess: (data) => {
      trackAndTag({
        event: "FO Interaction",
        type: "Price update",
        data: getTrackingPriceRange(data.price.incl_vat.value),
        partyId,
      });
      if (data.state === "NOT_FEASIBLE") {
        trackAndTag({
          event: "FO Interaction",
          type: "Not feasible after update",
          data: getTrackingPriceRange(data.price.incl_vat.value),
          partyId,
        });
      }
    },
  });

  const price = updateItem.data?.price || draftTr.price;

  const payShipment = useMutation(foClient.marktplaats.payShipment, {
    onSuccess({ redirect_url }) {
      setIsRedirecting(true);
      trackPurchase({
        // We use party id + state, to only track one purchase per session
        id: `FEASIBLE_${partyId}`,
        type: "purchase",
        amount: price.incl_vat.value,
        flowType: SourceFlow.MARKTPLAATS,
        partyId,
      });
      onSuccessPay({ redirect_url, signUpPartyId: form.data.signup.value ? partyId : undefined });
    },
  });

  const updateFields = (update: FoEditableItemFields): void => {
    const updatedFields = { ...editableFields, ...update };
    setEditableFields(updatedFields);
    updateItem.reset();
    updateItem.mutate({
      partyId,
      item: updatedFields,
    });
  };
  const isNotFeasible = (updateItem.data?.state || draftTr.state) === "NOT_FEASIBLE";
  const isNotFeasibleReasons = updateItem.data?.not_feasible_reasons || draftTr.not_feasible_reasons;
  const hasError = isNotFeasible || form.hasErrors;

  return (
    <>
      <PageHead title={t((d) => d.fo.payment_flow.title)} sub={t((d) => d.fo.payment_flow.sub_title)} />
      <MpContainer>
        <MpCard className={cn("!mb-0", "-mx-2", { "border-red-400": isNotFeasible })}>
          <div className="flex justify-between gap-2">
            <div>
              <h5>{draftTr.item.title}</h5>
              <div className="flex gap-2 items-center pt-2">
                <button className="bg-transparent flex items-center" type="button" onClick={editModal.open}>
                  {editableFields.length}
                  <IconClose className="ml-1" style={{ fontSize: ".5em" }} />
                  {editableFields.width}
                  <IconClose className="ml-1" style={{ fontSize: ".5em" }} />
                  {editableFields.height} cm
                  <IconPencil className="ml-1" style={{ marginTop: ".35em", fontSize: ".65em" }} />
                </button>
              </div>
            </div>
            {draftTr.item.images[0] && (
              <img src={draftTr.item.images[0]} className="w-16 h-16 rounded-lg object-cover" />
            )}
          </div>
          <hr className="border-gray-400 my-4" />
          <div className="flex justify-between">
            {t((d) => d.fo.payment_flow.fields.count.label)}
            <div className="flex items-center border border-gray-400 rounded-full py-1 px-2">
              <button
                disabled={editableFields.count === 1}
                onClick={() => updateFields({ count: editableFields.count ? editableFields.count - 1 : 1 })}
                type={"button"}
                className="w-6 h-6 rounded-full disabled:opacity-50"
              >
                <div className="border-t-2 border-blue-600 w-1/2 mx-auto"></div>
              </button>
              <div className="w-8 text-center">{editableFields.count || 1}</div>
              <button
                onClick={() => updateFields({ count: editableFields.count ? editableFields.count + 1 : 1 })}
                type={"button"}
                className="w-6 h-6 rounded-full relative disabled:opacity-50"
              >
                <div className="border-t-2 border-blue-600 w-1/2 mx-auto" />
                <div className="border-l-2 border-blue-600 h-1/2 mx-auto absolute top-1/4 left-[calc(50%-1px)]" />
              </button>
            </div>
          </div>
        </MpCard>
        {isNotFeasible && (
          <div {...errorAttr} className="text-red-400">
            {t((d) => d.fo.payment_flow.error_not_feasible)}
          </div>
        )}
      </MpContainer>
      <MpContainer>
        <h6>{t((d) => d.fo.stop.pickup.label)}</h6>
        <Address address={draftTr.pickup.address} />
      </MpContainer>
      <MpContainer>
        <h6>{t((d) => d.fo.stop.delivery.label)}</h6>
        <Address address={draftTr.delivery.address} />
      </MpContainer>
      <MpContainer>
        <h6>{t((d) => d.fo.payment_flow.delivery_date.label)}</h6>
        <button type="button" className="bg-transparent flex items-center gap-2" onClick={deliverModal.open}>
          <span dangerouslySetInnerHTML={{ __html: t((d) => d.fo.payment_flow.delivery_date.explain) }} />
          <IconInfoCircle />
        </button>
      </MpContainer>
      <MpContainer>
        <h6>{t((d) => d.fo.payment_flow.service.label)}</h6>
        <button
          type="button"
          className="bg-transparent flex items-center gap-2 mb-2 underline"
          onClick={serviceModal.open}
        >
          {t((d) => d.fo.service.standard)} {t((d) => d.fo.service.farfetchd.name)}
          <IconInfoCircle />
        </button>
        <CheckmarkList items={[t((d) => d.fo.service.farfetchd.text_1), t((d) => d.fo.service.farfetchd.text_2)]} />
      </MpContainer>
      <MpContainer>
        <h4 className="flex justify-between">
          <div>{t((d) => d.fo.price.total)}</div>
          <Price price={price.incl_vat.value} isLoading={updateItem.isLoading} />
        </h4>
      </MpContainer>
      <MpContainer>
        <div className="mb-2">
          <Label
            text={
              <>
                <small>
                  {t((d) => d.fo.terms.agree_to_x)}{" "}
                  <button className={"underline text-blue-600"} onClick={generalTermsModal.open}>
                    {t((d) => d.fo.terms.general_terms)}
                  </button>
                  *
                </small>
              </>
            }
            position={"right"}
          >
            <InputCheckbox
              checked={form.data.general_terms.value}
              onChange={() => {
                form.set({ general_terms: !form.data.general_terms.value });
              }}
            />
          </Label>
          {!form.data.general_terms.value && errorsVisible && (
            <small {...errorAttr} className="text-red-400 ml-6">
              {t((d) => d.fo.fields.required)}
            </small>
          )}
        </div>
        <div className="mb-2">
          <Label
            text={
              <small>
                {t((d) => d.fo.terms.agree_to_x)}{" "}
                <button className={"underline text-blue-600"} onClick={privacyTermsModal.open}>
                  {t((d) => d.fo.terms.privacy_statement)}
                </button>
                *
              </small>
            }
            position={"right"}
          >
            <InputCheckbox
              checked={form.data.privacy_terms.value}
              onChange={() => {
                form.set({ privacy_terms: !form.data.privacy_terms.value });
              }}
            />
          </Label>
          {!form.data.privacy_terms.value && errorsVisible && (
            <small {...errorAttr} className="text-red-400 ml-6">
              {t((d) => d.fo.fields.required)}
            </small>
          )}
        </div>
        <div className="mb-2">
          <Label text={<small>{t((d) => d.fo.terms.news_letter_signup)}</small>} position={"right"}>
            <InputCheckbox
              checked={form.data.signup.value}
              onChange={() => {
                form.set({ signup: !form.data.signup.value });
              }}
            />
          </Label>
        </div>
        <div>
          <small>
            {t((d) => d.fo.terms.by_using_agree_to_x)}{" "}
            <button className={"underline text-blue-600"} onClick={transportTermsModal.open}>
              {t((d) => d.fo.terms.transport_agreement)}
            </button>
          </small>
          .
        </div>
      </MpContainer>
      <MpContainer className="flex items-center gap-2">
        <img src={IdealIcon} />
        <img src={CreditCardIcon} />
        <img src={PaypalIcon} />
      </MpContainer>
      <MpContainer>
        <MpCard theme="primary-light" className={"cursor-pointer !pt-4"} onClick={serviceModal.open}>
          <div className="flex items-center gap-4">
            <svg
              className="mt-2"
              width="24"
              height="27"
              viewBox="0 0 24 27"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.4228 4.63208C10.3204 4.63208 10.2223 4.67275 10.1499 4.74514C10.0775 4.81754 10.0368 4.91572 10.0368 5.0181C10.0368 5.12048 10.0775 5.21867 10.1499 5.29106C10.2223 5.36345 10.3204 5.40412 10.4228 5.40412H17.3712C17.4736 5.40412 17.5718 5.36345 17.6442 5.29106C17.7165 5.21867 17.7572 5.12048 17.7572 5.0181C17.7572 4.91572 17.7165 4.81754 17.6442 4.74514C17.5718 4.67275 17.4736 4.63208 17.3712 4.63208H10.4228Z" />
              <path d="M17.7572 10.4226C17.7572 10.3203 17.7165 10.2221 17.6442 10.1497C17.5718 10.0773 17.4736 10.0366 17.3712 10.0366H10.4228C10.3204 10.0366 10.2223 10.0773 10.1499 10.1497C10.0775 10.2221 10.0368 10.3203 10.0368 10.4226C10.0368 10.525 10.0775 10.6232 10.1499 10.6956C10.2223 10.768 10.3204 10.8087 10.4228 10.8087H17.3712C17.4736 10.8087 17.5718 10.768 17.6442 10.6956C17.7165 10.6232 17.7572 10.525 17.7572 10.4226Z" />
              <path d="M7.9446 2.7947C7.90607 2.76175 7.86143 2.7367 7.81323 2.721C7.76502 2.7053 7.7142 2.69925 7.66366 2.7032C7.61311 2.70714 7.56384 2.721 7.51866 2.74398C7.47347 2.76697 7.43326 2.79863 7.40031 2.83716L4.98768 5.65125L3.51308 4.54337C3.43118 4.48194 3.32823 4.45557 3.22688 4.47005C3.12553 4.48453 3.03408 4.53867 2.97265 4.62058C2.91123 4.70248 2.88485 4.80543 2.89933 4.90678C2.91381 5.00813 2.96795 5.09958 3.04986 5.161L4.81397 6.48505C4.88079 6.53517 4.96206 6.56226 5.04558 6.56226C5.10134 6.56226 5.15644 6.55019 5.20708 6.52686C5.25773 6.50354 5.30272 6.46952 5.33896 6.42715L7.98706 3.33899C8.02001 3.30046 8.04505 3.25582 8.06075 3.20762C8.07645 3.15941 8.0825 3.10859 8.07856 3.05805C8.07462 3.00751 8.06076 2.95823 8.03777 2.91305C8.01479 2.86786 7.98313 2.82765 7.9446 2.7947Z" />
              <path d="M7.9446 8.199C7.90607 8.16604 7.86143 8.141 7.81323 8.1253C7.76502 8.1096 7.7142 8.10355 7.66366 8.10749C7.61311 8.11144 7.56384 8.1253 7.51866 8.14828C7.47347 8.17127 7.43326 8.20293 7.40031 8.24146L4.98768 11.0555L3.51308 9.94767C3.43118 9.88624 3.32823 9.85987 3.22688 9.87434C3.12553 9.88882 3.03408 9.94297 2.97265 10.0249C2.91123 10.1068 2.88485 10.2097 2.89933 10.3111C2.91381 10.4124 2.96795 10.5039 3.04986 10.5653L4.81397 11.8894C4.88079 11.9395 4.96206 11.9666 5.04558 11.9666C5.10134 11.9666 5.15644 11.9545 5.20708 11.9312C5.25773 11.9078 5.30272 11.8738 5.33896 11.8314L7.98706 8.74328C8.02001 8.70476 8.04505 8.66012 8.06075 8.61192C8.07645 8.56371 8.0825 8.51289 8.07856 8.46235C8.07462 8.4118 8.06076 8.36253 8.03777 8.31735C8.01479 8.27216 7.98313 8.23194 7.9446 8.199Z" />
              <path d="M7.40031 14.0319L4.98768 16.8459L3.51308 15.7381C3.43118 15.6766 3.32823 15.6503 3.22688 15.6647C3.12553 15.6792 3.03408 15.7334 2.97265 15.8153C2.91123 15.8972 2.88485 16.0001 2.89933 16.1015C2.91381 16.2028 2.96795 16.2943 3.04986 16.3557L4.81397 17.6797C4.88079 17.7299 4.96206 17.757 5.04558 17.757C5.10134 17.757 5.15644 17.7449 5.20708 17.7216C5.25773 17.6982 5.30272 17.6642 5.33896 17.6218L7.98706 14.5337C8.05361 14.4559 8.08652 14.3548 8.07855 14.2527C8.07059 14.1507 8.02241 14.0559 7.9446 13.9894C7.86679 13.9228 7.76573 13.8899 7.66366 13.8979C7.56158 13.9059 7.46685 13.954 7.40031 14.0319Z" />
              <path d="M10.0365 20.0731H4.70173C3.65951 20.0731 2.65998 19.659 1.92302 18.9221C1.18606 18.1851 0.772041 17.1856 0.772041 16.1434V4.70173C0.772041 3.65951 1.18606 2.65998 1.92302 1.92302C2.65998 1.18606 3.65951 0.772041 4.70173 0.772041H16.1472C17.1888 0.773064 18.1873 1.18754 18.9235 1.92438C19.6596 2.66123 20.0731 3.66018 20.0731 4.70173V12.7387C20.0731 12.8411 20.1137 12.9392 20.1861 13.0116C20.2585 13.084 20.3567 13.1247 20.4591 13.1247C20.5615 13.1247 20.6597 13.084 20.732 13.0116C20.8044 12.9392 20.8451 12.8411 20.8451 12.7387V4.70173C20.8431 3.45538 20.347 2.26066 19.4657 1.37936C18.5844 0.498056 17.3897 0.00204153 16.1434 0L4.70173 0C3.45538 0.00204153 2.26066 0.498056 1.37936 1.37936C0.498056 2.26066 0.00204153 3.45538 0 4.70173L0 16.1472C0.00204387 17.3932 0.498183 18.5875 1.3796 19.4682C2.26101 20.3489 3.45573 20.8441 4.70173 20.8451H10.0365C10.1389 20.8451 10.2371 20.8044 10.3095 20.732C10.3819 20.6597 10.4226 20.5615 10.4226 20.4591C10.4226 20.3567 10.3819 20.2585 10.3095 20.1861C10.2371 20.1137 10.1389 20.0731 10.0365 20.0731Z" />
              <path d="M11.906 13.6419C11.808 13.7325 11.7374 13.8488 11.7022 13.9775C11.667 14.1063 11.6686 14.2423 11.7069 14.3702L14.7505 24.4331C14.792 24.57 14.8739 24.6911 14.9854 24.7806C15.097 24.8701 15.233 24.9238 15.3756 24.9346C15.5182 24.9455 15.6608 24.9129 15.7846 24.8413C15.9084 24.7697 16.0076 24.6624 16.0694 24.5334L17.4006 21.7532L20.0011 24.5867C20.0714 24.6623 20.1687 24.7069 20.2718 24.7109C20.3749 24.7149 20.4754 24.6778 20.5512 24.6078L21.9652 23.3159C22.003 23.2814 22.0337 23.2398 22.0555 23.1934C22.0772 23.1471 22.0896 23.0969 22.092 23.0458C22.0943 22.9946 22.0866 22.9435 22.0692 22.8954C22.0518 22.8473 22.0251 22.803 21.9906 22.7652L19.3594 19.8871L22.3833 18.7582C22.5169 18.7078 22.6322 18.6183 22.7141 18.5013C22.796 18.3843 22.8407 18.2453 22.8424 18.1026C22.844 17.9598 22.8025 17.8198 22.7233 17.701C22.6441 17.5822 22.5309 17.49 22.3985 17.4365L12.6497 13.5062C12.5255 13.4569 12.3899 13.4435 12.2584 13.4675C12.1269 13.4914 12.0048 13.5519 11.906 13.6419ZM21.9397 18.0937L18.5432 19.3628C18.4833 19.3851 18.4298 19.4218 18.3874 19.4696C18.345 19.5175 18.3151 19.575 18.3002 19.6372C18.2853 19.6993 18.2859 19.7642 18.3019 19.826C18.318 19.8879 18.349 19.9449 18.3922 19.9919L21.1495 23.0067L20.3115 23.7734L17.5892 20.7902C17.5463 20.742 17.4921 20.7052 17.4314 20.6831C17.3708 20.661 17.3056 20.6543 17.2417 20.6636C17.1779 20.6729 17.1173 20.6979 17.0655 20.7364C17.0137 20.7749 16.9722 20.8257 16.9449 20.8842L15.4438 24.0335L12.4942 14.2833L21.9397 18.0937Z" />
            </svg>
            <div dangerouslySetInnerHTML={{ __html: t((d) => d.fo.payment_flow.service_banner.text) }} />
          </div>
        </MpCard>
      </MpContainer>
      <MpFooter>
        <MpContainer>
          <MpFooterCta
            loading={isRedirecting || payShipment.isLoading}
            disabled={hasError || isRedirecting || payShipment.isLoading || updateItem.isLoading}
            onClickDisabled={errorVisibleAndScrollTo}
            onClick={() => {
              payShipment.reset();
              payShipment.mutate({ partyId });
            }}
          >
            <div className="flex items-center gap-2">
              {t((d) => d.fo.price.pay)} <Price price={price.incl_vat.value} isLoading={updateItem.isLoading} />
            </div>
          </MpFooterCta>
        </MpContainer>
      </MpFooter>
      <NotFeasibleModal isNotFeasible={isNotFeasible} reasons={isNotFeasibleReasons} />
      <ServiceModal partyId={partyId} isOpen={serviceModal.isActive} closeHandler={serviceModal.close} />
      <DeliveryDateModal isOpen={deliverModal.isActive} closeHandler={deliverModal.close} />
      <EditItemModal
        isOpen={editModal.isActive}
        closeHandler={editModal.close}
        dims={{
          width: draftTr.item.width,
          length: draftTr.item.length,
          height: draftTr.item.height,
        }}
        update={updateFields}
      />
      <Modal
        isOpen={generalTermsModal.isActive}
        closeHandler={generalTermsModal.close}
        footer={
          <Button
            buttonType="secondary"
            className="w-full"
            onClick={() => {
              generalTermsModal.close();
              form.set({ general_terms: true });
            }}
          >
            {t((d) => d.agree)}
          </Button>
        }
      >
        <WordPressContent startFetching={generalTermsModal.isActive} content="terms-general" />
      </Modal>
      <Modal
        isOpen={privacyTermsModal.isActive}
        closeHandler={privacyTermsModal.close}
        footer={
          <Button
            buttonType="secondary"
            className="w-full"
            onClick={() => {
              privacyTermsModal.close();
              form.set({ privacy_terms: true });
            }}
          >
            {t((d) => d.agree)}
          </Button>
        }
      >
        <WordPressContent startFetching={privacyTermsModal.isActive} content="terms-privacy" />
      </Modal>
      <Modal
        isOpen={transportTermsModal.isActive}
        closeHandler={transportTermsModal.close}
        footer={
          <Button buttonType="secondary" className="w-full" onClick={transportTermsModal.close}>
            {t((d) => d.agree)}
          </Button>
        }
      >
        <WordPressContent startFetching={transportTermsModal.isActive} content="terms-transport" />
      </Modal>
    </>
  );
};

const onSuccessPay = async ({
  redirect_url,
  signUpPartyId,
}: {
  redirect_url: string;
  signUpPartyId?: string;
}): Promise<void> => {
  if (signUpPartyId) {
    await signUpToNewsletter(signUpPartyId, "buyer");
  }
  window.location.assign(redirect_url);
};

export const signUpToNewsletter = async (partyId: string, party: FoParty): Promise<void> => {
  try {
    const contact = await foClient.marktplaats.retrieveContact({
      party,
      partyId,
    });

    await coreClient.newsletter.create({
      first_name: contact.first_name,
      last_name: contact.last_name,
      email: contact.email,
    });
  } catch (e) {
    // Fail silent, no need to make a fuzz from newsletter sign ups
    logger.dev(e);
  }
};
