import React from "react";
import cn from "classnames";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  header: React.ReactElement;
  body: React.ReactElement;
  footer?: React.ReactElement;
}

export const Card: React.FC<Props> = ({ header, body, footer }) => {
  return (
    <div className={cn("border", "border-gray-500", "rounded-md")}>
      <div className={cn("p-4", "rounded-md", "bg-gray-100")}>{header}</div>
      <div className={cn("p-4")}>{body}</div>
      {footer && <div className={cn("p-4", "rounded-md", "bg-gray-100")}>{footer}</div>}
    </div>
  );
};
