import { Button, IconArrowLeft, IconArrowRight } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import { BrengerLogo } from "./BrengerLogo";
import { ChatModal } from "./modals/ChatModal";
import { PaymentStateError } from "./PaymentStateError";
import { Progress } from "./Progress";

export const MpLayout: React.FC<{
  hasBack?: boolean;
  children: React.ReactNode;
  progress?: { current: number; total: number };
}> = ({ hasBack, children, progress }) => {
  const history = useHistory();

  return (
    <div className="max-h-screen flex flex-col">
      <MpContainer
        className={cn("text-blue-600 flex justify-center items-center", { "mb-4": !progress, "mb-2": !!progress })}
      >
        <div className="w-12 text-xl mr-auto">
          {hasBack && (
            <button type="button" className="bg-transparent" onClick={history.goBack}>
              <IconArrowLeft />
            </button>
          )}
        </div>
        <BrengerLogo className="my-2" />
        <div className="ml-auto">
          <ChatModal />
        </div>
      </MpContainer>
      <div className="grow overflow-y-auto">
        {progress && <Progress {...progress} />}
        <PaymentStateError />
        <div className="pb-24">{children}</div>
      </div>
    </div>
  );
};

export const MpContainer: React.FC<{
  className?: string;
  children: React.ReactNode;
  fullWithBelowBreak?: "md" | "lg";
}> = ({ className, children, fullWithBelowBreak }) => {
  return (
    <div
      className={cn(
        {
          "container max-w-3xl py-4 px-6": !fullWithBelowBreak,
          "md:container md:max-w-3xl md:px-6": fullWithBelowBreak === "md",
          "lg:container lg:max-w-3xl lg:px-6": fullWithBelowBreak === "lg",
        },
        "mx-auto",
        className
      )}
    >
      {children}
    </div>
  );
};

export const MpFooter: React.FC<{ className?: string; children: React.ReactNode }> = ({ className, children }) => {
  return (
    <div className={cn("fixed", "inset-0", "top-auto", "bg-white", "border-t", "border-gray-300", className)}>
      {children}
    </div>
  );
};

export const MpFooterCta: React.FC<{
  onClick?(): void;
  onClickDisabled?(): void;
  loading?: boolean;
  disabled: boolean;
  children: React.ReactNode;
}> = ({ children, ...props }) => {
  return (
    <Button className="w-full" buttonType="secondary" {...props}>
      <div className="flex items-center justify-between">
        {children} <IconArrowRight />
      </div>
    </Button>
  );
};

export const MpCard: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    theme?: "white" | "primary" | "primary-light";
    className?: string;
    children: React.ReactNode;
    isLoading?: boolean;
  }
> = ({ theme = "white", className, children, isLoading, ...rest }) => {
  return (
    <MpSimpleLoader isLoading={isLoading}>
      <div
        className={cn(
          "border",
          { "border-gray-400": theme === "white" },
          "rounded-lg",
          "p-6 pb-4",
          "mb-4",
          "relative",
          { "border-blue-600 bg-blue-600 text-white": theme === "primary" },
          { "border-blue-100 bg-blue-100 text-blue-600": theme === "primary-light" },
          className
        )}
        {...rest}
      >
        {children}
      </div>
    </MpSimpleLoader>
  );
};

export const MpSimpleLoader: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { isLoading?: boolean; children: React.ReactNode }
> = ({ isLoading, className, children, ...rest }) => {
  return (
    <div className={cn("relative", className)} {...rest}>
      {children}
      {isLoading && <div className="absolute inset-0 bg-white/50" />}
    </div>
  );
};

export const ThankYouLayout: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => {
  return (
    <div className="bg-blue-600 text-white min-h-screen pb-24">
      <MpContainer className="!pb-2">
        <div className="mb-20 lg:mb-28">
          <BrengerLogo className="mt-2 mb-6 mx-auto" />
        </div>
        <h1>{title}</h1>
      </MpContainer>
      {children}
    </div>
  );
};

export const MpFooterThankYou: React.FC<{ className?: string; children: React.ReactNode }> = ({
  className,
  children,
}) => {
  return <div className={cn("fixed", "inset-0", "top-auto", "bg-blue-600", "text-white", className)}>{children}</div>;
};
