import React from "react";
import { MpContainer, MpLayout } from "../../components/Layout";
import { PageHead } from "../../components";
import { useTranslationContext } from "../../../hooks";

export const SellerCancelPage: React.FC = () => {
  const { t } = useTranslationContext();
  return (
    <MpLayout>
      <PageHead title={t((d) => d.fo.seller_cancel.title)} />
      <MpContainer>
        <div>{t((d) => d.fo.seller_cancel.text)}</div>
      </MpContainer>
    </MpLayout>
  );
};
