import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import template from "lodash/template";
import { Locale, SupportedTimeZones } from "@brenger/api-client";
import { getLocale } from "@brenger/utils";

import { En } from "../../i18n/en";
import { De } from "../../i18n/de";
import { Nl } from "../../i18n/nl";

import { Config } from "../config";
import { CacheKey, CacheTTL, logger, localeNormalizer, coreClient, priceClient, routePlannerClient } from "../utils";
import { usePersistedState } from "./usePersistedState";

// Set up an i18n axios instance so that we can specify interceptors (see below).
const i18nInstance = axios.create({ baseURL: Config.I18N_URL });

i18nInstance.interceptors.response.use(undefined, (error) => {
  return Promise.reject(error);
});

type TranslationData = En | De | Nl;

const i18nClient = {
  async retrieve(locale: Locale): Promise<TranslationData | undefined> {
    // TECH DEBT ALERT: This is a hack bc of the way we decided to do languages in core for now.
    // Eventually, we'll want to migrate to fetching languages by sending full locales to core when fetching translation files.
    const language = localeNormalizer.parseLanguage(locale);
    const { data } = await i18nInstance.get(language);
    // Only return the "tracking" key so we don't cache too much.
    return data.tracking;
  },
};

type WithTemplate = (trans: string, data?: { [key: string]: string | number | undefined | null }) => string;
type TranslationCallback = (d: TranslationData, t: WithTemplate) => string;
interface UseTranslationConfig {
  locale: Locale;
  changeLocale?: (locale: Locale) => void;
  timeZone: SupportedTimeZones;
}

interface Context {
  t: (cb: TranslationCallback) => string;
  i18n: UseTranslationConfig;
}

const initialLocale = localeNormalizer.parseLocale(getLocale());
const initialTimeZOne = localeNormalizer.parseTimeZone(initialLocale);

const initialState = {
  i18n: {
    locale: initialLocale,
    changeLocale: undefined,
    timeZone: initialTimeZOne,
  },
  t: () => "",
};

const TranslationContext = React.createContext<Context>(initialState);

export const TranslationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [locale, setLocale] = usePersistedState({
    initialState: initialState.i18n.locale,
    storage: window.localStorage,
    version: 1,
    key: "BRENGER_TRACKING_PREFERRED_LOCALE",
  });
  const [timeZone, setTimeZone] = useState(initialState.i18n.timeZone);

  const translation = useQuery([CacheKey.TRANSLATIONS, locale], () => i18nClient.retrieve(locale), {
    enabled: !!locale,
    retry: 2,
    cacheTime: CacheTTL.XXL * 1000,
  });

  // Re-set the locale header on all subsequent requests for all clients whenever it changes.
  React.useEffect(() => {
    coreClient.users.setLocale(locale);
    priceClient.quotes.setLocale(locale);
    routePlannerClient.geo.setLocale(locale);
    setTimeZone(localeNormalizer.parseTimeZone(locale));
  }, [locale]);

  const withTemplate = React.useCallback(
    (trans: string, data?: { [key: string]: string | number | undefined | null }): string => {
      const compiled = template(trans);
      return compiled(data);
    },
    []
  );

  const context = {
    t: (callback: TranslationCallback): string => {
      /**
       * Coerce translation.result to one of the two translations
       * Does not matter that it may be undefined, because wrapped in a try/catch!
       */
      try {
        return callback(translation.data as TranslationData, withTemplate);
      } catch (err) {
        return "";
      }
    },
    i18n: {
      locale,
      timeZone,
      changeLocale: setLocale,
    },
  };

  logger.dev("useTranslationContext", context);

  return <TranslationContext.Provider value={context}>{children}</TranslationContext.Provider>;
};

export const useTranslationContext = (): Context => {
  return React.useContext(TranslationContext);
};
