import { getCO2SavingsForKilometers } from "@brenger/utils";
import cn from "classnames";
import React from "react";

import { PageContainer } from "../../components";
import { useTranslationContext, useTransportContext } from "../../hooks";

export const BannerCO2: React.FC = () => {
  const { t } = useTranslationContext();
  const ctx = useTransportContext();

  // No use in showing C02 savings when the transport has been cancelled.
  if (ctx.status === undefined || ctx.status < 1) return null;

  const distance = ctx.tr?.internal_attributes.route?.distance;

  if (!distance) return null;

  const savings = getCO2SavingsForKilometers(distance);

  return (
    <PageContainer className={cn("py-6", "bg-gray-100")}>
      <div className={cn("text-blue-600")}>
        {t((d, template) =>
          template(d.co2_savings, { kg: savings.inKg, hamburgers: savings.inKg, showers: savings.inShowerTurns })
        )}
      </div>
    </PageContainer>
  );
};
