import { FoPartyIdParams } from "@brenger/api-client";
import { IconCaretRight, IconChat, IconClose } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useDraftTr } from "../hooks/useDraftTr";
import { Address, Modal, MpCard, MpModalContentHead } from "./";
import { useTranslationContext } from "../../hooks";
import { ChatButton } from "./ChatButton";
import { trackAndTag } from "../../utils/eventTracking";

export const TrDetails: React.FC<FoPartyIdParams> = ({ partyId }) => {
  const { t } = useTranslationContext();
  const draftTr = useDraftTr({ partyId });
  const [isOpen, setIsOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <>
      <button type="button" className="flex items-center text-blue-600" onClick={() => setIsOpen(!isOpen)}>
        {t((d) => d.fo.summary.transport_details)}{" "}
        <IconCaretRight
          className={cn("transition-transform", "-rotate-90", { "rotate-90": isOpen })}
          style={{ fontSize: "1.2em" }}
        />
      </button>
      <MpCard
        style={{ boxShadow: "0px 8px 24px 0px rgba(124, 144, 168, 0.20)" }}
        className={cn("!border-white mt-4", { hidden: !isOpen })}
        onClick={() => {
          trackAndTag({
            event: "FO Interaction",
            type: "Modal open - item (tr details)",
            data: draftTr.data?.party || "",
            partyId,
          });
          setIsModalOpen(true);
        }}
      >
        <div className="md:flex">
          {draftTr.data?.item.images[0] && (
            <div className="-mt-6 -mx-6 pb-4 md:pb-0 md:pr-6 md:mr-0 md:w-1/2 md:-mb-4">
              <img
                src={draftTr.data.item.images[0]}
                className="w-full h-auto max-md:rounded-t-lg object-cover md:rounded-l-lg"
              />
            </div>
          )}
          <div className="md:p-2 md:pb-4">
            <h5>{draftTr.data?.item.title}</h5>
            <div className="flex gap-1 items-center pt-1 pb-2">
              {draftTr.data?.item.length}
              <IconClose className="ml-1" style={{ fontSize: ".5em" }} />
              {draftTr.data?.item.width}
              <IconClose className="ml-1" style={{ fontSize: ".5em" }} />
              {draftTr.data?.item.height} cm
            </div>

            <div className="flex gap-2">
              <div className="rounded-full w-[9px] h-[9px] mt-2 bg-blue-600" />
              {draftTr.data?.pickup.address && (
                <small>
                  <Address address={draftTr.data.pickup.address} inLine={true} />
                </small>
              )}
            </div>
            <div className="h-2 relative">
              <div className="absolute -top-2 -bottom-2 left-[3px] border-l-[3px] border-blue-600" />
            </div>
            <div className="flex gap-2">
              <div className="rounded-full w-[9px] h-[9px] mt-2 bg-blue-600 shrink-0" />
              {draftTr.data?.delivery.address && (
                <small>
                  <Address address={draftTr.data.delivery.address} inLine={true} />
                </small>
              )}
            </div>
          </div>
        </div>
      </MpCard>
      <Modal
        theme="primary"
        isOpen={isModalOpen}
        closeHandler={() => setIsModalOpen(false)}
        header={
          <small className="inline-flex items-center gap-2">
            <IconChat />
            {t((d) => d.fo.help_modal.button)}
          </small>
        }
      >
        <MpModalContentHead
          title={t((d) => d.fo.item_help_modal.header)}
          sub={t((d) => d.fo.item_help_modal.sub_header)}
        />
        <ChatButton onClose={() => setIsModalOpen(false)} />
      </Modal>
    </>
  );
};
