import * as React from "react";
import { useQueryClient } from "react-query";

import { Page } from "../../../components";
import { Routes, coreClient } from "../../../utils";
import { useHistory } from "react-router-dom";

export const Logout: React.FC = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const logout = async (): Promise<void> => {
    await coreClient.users.logout();
    queryClient.clear();
    history.push(Routes.ROOT);
  };

  React.useEffect(() => {
    logout();
  }, []);

  return <Page loading={true} />;
};
