"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSearchParam = void 0;
// NOTE: in certain runtimes (eg: nextjs) search params is not available.
var getSearchParam = function (param) {
    if (typeof URLSearchParams === 'function') {
        var params = new URLSearchParams(window.location.search);
        return params.get(param);
    }
    return null;
};
exports.getSearchParam = getSearchParam;
