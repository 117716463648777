import { Button } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";

import { IconArrowRight, PageContainer } from "../../components";
import { useTranslationContext, useTransportContext } from "../../hooks";
import { createAccessControl } from "../../utils";

// NOTE re canEditContactDetails: this should align with perms in NotificationContactDetails
const getPerms = createAccessControl({
  pickup: {
    canAddOrEditServices: undefined,
    canEditContactDetails: [1, 2, 3],
  },
  delivery: {
    canAddOrEditServices: undefined,
    canEditContactDetails: [1, 2, 3, 4],
  },
  customer: {
    canAddOrEditServices: [1, 2, 3, 4],
    canEditContactDetails: [1, 2, 3, 4],
  },
});

interface Props {
  header: string;
  body?: string;
  to?: string;
}

// NOTE: a re-usable container to display the various prompts to different types of users.
const Container: React.FC<Props> = ({ header, body }) => {
  const { t } = useTranslationContext();
  const history = useHistory();
  return (
    <PageContainer
      className={cn("py-6", "border-t-4", "border-b-4")}
      // @TODO add border color to tailwind config
      style={{ borderColor: "#D1E4FD" }}
    >
      <h6>{header}</h6>
      {body && (
        <>
          <div className={cn("mt-1")}>{body}</div>
        </>
      )}
      <Button
        className={cn("mt-2")}
        iconPos="right"
        type="button"
        onClick={() => history.push(`${history.location.pathname}/edit`)}
      >
        <span className="flex justify-between items-center">
          {t((d) => d.update_transport_cta)} <IconArrowRight className="ml-8" />
        </span>
      </Button>
    </PageContainer>
  );
};

export const UpdateStopDetailsCTA: React.FC = () => {
  const { t } = useTranslationContext();
  const { contactType, status, openForUpsells: upsells } = useTransportContext();

  const { perms } = getPerms(contactType, status);

  // Nothing to see here when user has no perms
  if (!perms) return null;

  const { canAddOrEditServices, canEditContactDetails } = perms;

  const canEditCarryingHelp = upsells?.carrying_help?.editable;
  const canEditElevatorOrFloorLevel = upsells?.elevator?.editable || upsells?.floor_level?.editable;

  if (canAddOrEditServices && canEditCarryingHelp && canEditElevatorOrFloorLevel) {
    return <Container header={t((d) => d.update_transport_header)} body={t((d) => d.update_carrying_help)} />;
  }

  if (canAddOrEditServices && canEditElevatorOrFloorLevel) {
    return <Container header={t((d) => d.update_transport_header)} body={t((d) => d.update_floor_level)} />;
  }

  if (canEditContactDetails) {
    return <Container header={t((d) => d.update_contact_details_header)} />;
  }

  return null;
};
