import { Button, Spacer } from "@brenger/react";
import { formatPrice, getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import React, { useEffect } from "react";
import { useQuery } from "react-query";

import { PageContainer, ProductPaymentStatusPill } from "../../components";
import { useTranslationContext, useTransportContext } from "../../hooks";
import { CacheKey, coreClient, createAccessControl, logger } from "../../utils";

const getPerms = createAccessControl({
  // NOTE: in the context of a product payment, the pickup contact is the seller.
  pickup: {
    canSeeSellerDetails: [0, 1, 2, 3, 4, 5, 6],
  },
  delivery: {
    canSeeSellerDetails: undefined,
  },
  customer: {
    canSeeSellerDetails: undefined,
  },
});

export const ProductPayment: React.FC = () => {
  const { t, i18n } = useTranslationContext();
  const ctx = useTransportContext();
  const { perms } = getPerms(ctx.contactType, ctx.status);

  const [productPaymentIri, ...rest] = ctx.tr?.item_sets.flatMap((itemSet) => itemSet.product_payments) || [];

  useEffect(() => {
    // NOTE: If we have more than one product payment, notify Sentry.
    // The product payment model does technically support many, but
    // practically speaking we're operating under the assumption that there is only one.
    if (rest.length > 0) {
      logger.message("More than one product payment found.", {
        extra: { additionalProductPayments: JSON.stringify(rest) },
      });
    }
  }, rest);

  const productPayment = useQuery(
    [CacheKey.RETRIEVE_PRODUCT_PAYMENT, productPaymentIri],
    () => coreClient.productPayments.retrieve({ id: getIdFromIri(productPaymentIri) || "" }),
    {
      enabled: !!productPaymentIri,
    }
  );

  // NOTE: Do not show section if there are no product payment IRIs
  if (!productPayment.data) return null;

  const { amount, iban, status, merchant } = productPayment.data;
  const isProfileIncomplete = status === "profile_incomplete";

  return (
    <PageContainer
      className={cn("py-6")}
      // NOTE: provide id so emails can anchor users at product payment section.
      id="product_payment"
    >
      <div className={cn("flex", "justify-between", "items-center")}>
        <h6>{t((d) => d.product_payment)}</h6>
        <ProductPaymentStatusPill status={status} />
      </div>
      <Spacer h={2} />
      <div className={cn("grid", "grid-cols-4")}>
        <div className={cn("col-span-3")}>{t((d) => d.total)}</div>
        <div className={cn("col-span-1", "text-right")}>
          {/* @TODO ADD CURRENCY WHEN PRODUCT PAYMENT HAS IT AVAILABLE */}
          {formatPrice({ amount, locale: i18n.locale })}
        </div>
        {perms?.canSeeSellerDetails && (
          <>
            <div className={cn("col-span-3")}>IBAN</div>
            <div className={cn("col-span-1", "text-right")}>{iban || "–"}</div>
          </>
        )}
      </div>
      {perms?.canSeeSellerDetails && isProfileIncomplete && (
        <div className={cn("mt-4")}>
          <a href={merchant.compliance_overview_url}>
            <Button className={cn("w-full")} buttonType="secondary">
              {t((d) => d.verify_payment_details)}
            </Button>
          </a>
        </div>
      )}
    </PageContainer>
  );
};
