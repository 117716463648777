import cn from "classnames";
import React from "react";

interface Props {
  /**
   * IMPORTANT NOTE:
   * Must pass lat/lng to the marker so that google-react-maps can clone + utilize it.
   * Without these two props, the marker will not appear and you will pull your hair out.
   */
  lat: number;
  lng: number;
  /**
   * This should be the icon to represent this marker.
   */
  children: React.ReactNode;
  text?: string;
  isConfirmed?: boolean;
}

export const MapLocationMarker: React.FC<Props> = (props) => {
  return (
    <div className={cn("absolute")} style={{ transform: "translate(-50%, -50%)" }}>
      <div className={cn("relative", "flex", "justify-center")}>
        {props.text ? (
          <div style={{ position: "absolute", transform: "translateY(calc(-100% - 3px))" }}>
            <div
              className={cn("capitalize", "px-2", "border-2", "rounded-full", "bg-white", {
                "border-green-400": props.isConfirmed,
                "border-gray-600": !props.isConfirmed,
              })}
            >
              {props.text}
            </div>
          </div>
        ) : null}
      </div>
      {props.children}
    </div>
  );
};
