import { InstructionSuspicion } from "@brenger/api-client";
import { Button, H2, H3, Spacer, Strong } from "@brenger/react";
import React from "react";
import { Modal, ModalProps } from "../../components";
import { useTranslationContext } from "../../hooks";
import cn from "classnames";

interface Props extends ModalProps {
  suspicions: { pickup: InstructionSuspicion[]; delivery: InstructionSuspicion[] };
  closeOnConfirm: () => void;
  isFloorLevelServiceAvailable: boolean;
}

export const UpdateInstructionModal: React.FC<Props> = ({
  suspicions,
  closeHandler,
  isActive,
  closeOnConfirm,
  isFloorLevelServiceAvailable,
}) => {
  const { t } = useTranslationContext();

  return (
    <Modal isActive={isActive} closeHandler={closeHandler}>
      <H2>{t((d) => d.request_flow.instruction_suspicion.modal_title)}</H2>
      <div>{t((d) => d.request_flow.instruction_suspicion.modal_body)}</div>
      <Spacer h={4} />
      {suspicions.pickup.length > 0 && (
        <div className={cn("flex", "flex-wrap", "items-center")}>
          <H3 className={cn("pb-2")}>
            <Strong>{t((d) => d.pickup)}</Strong>
          </H3>
          <ul className={cn("list-disc")}>
            {suspicions.pickup.map((suspicion, i) => {
              if (suspicion === "floor_level_suspected" && !isFloorLevelServiceAvailable) {
                return (
                  <li key={i} className={cn("pb-2", "ml-6")}>
                    {t((d) => d.request_flow.instruction_suspicion.floor_level_suspected_no_service)}
                  </li>
                );
              }
              return (
                <li key={i} className={cn("pb-2", "ml-6")}>
                  {t((d) => d.request_flow.instruction_suspicion[suspicion])}
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {suspicions.delivery.length > 0 && (
        <div>
          <H3 className={cn("pb-2", "pt-2")}>
            <Strong>{t((d) => d.delivery)}</Strong>
          </H3>
          <ul className={cn("list-disc")}>
            {suspicions.delivery.map((suspicion, i) => {
              if (suspicion === "floor_level_suspected" && !isFloorLevelServiceAvailable) {
                return (
                  <li key={i} className={cn("pb-2", "ml-6")}>
                    {t((d) => d.request_flow.instruction_suspicion.floor_level_suspected_no_service)}
                  </li>
                );
              }
              return (
                <li key={i} className={cn("pb-2", "ml-6")}>
                  {t((d) => d.request_flow.instruction_suspicion[suspicion])}
                </li>
              );
            })}
          </ul>
        </div>
      )}

      <Spacer h={4} />
      <div>{t((d) => d.request_flow.instruction_suspicion.modal_footer)}</div>
      <Spacer h={4} />

      <div className={cn("grid", "grid-cols-2", "gap-4")}>
        <Button buttonType="primary-outline" onClick={closeHandler}>
          {t((d) => d.go_back)}
        </Button>
        <Button buttonType="secondary" onClick={closeOnConfirm}>
          {t((d) => d.request_flow.instruction_suspicion.button)}
        </Button>
      </div>
    </Modal>
  );
};
