import { DriverUser } from "@brenger/api-client";
import { StarRating, Strong } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import { useState } from "react";

import { userDataClient } from "../utils";

interface Props {
  driverUser: DriverUser;
}

const cns = cn("h-12", "w-12");

export const DriverDetails: React.FC<Props> = ({ driverUser }) => {
  const [showDriverInitials, setShowDriverInitials] = useState(false);
  const driverId = getIdFromIri(driverUser["@id"]);
  const profileImageUrl = userDataClient.getProfileImageUrl(driverId || "");
  const driverInitials = `${driverUser.first_name[0]}`;

  return (
    <div className={cn("flex", "items-center")}>
      <div className={cn(cns, "rounded-full", "bg-gray-400")}>
        {showDriverInitials ? (
          <div
            className={cn(
              cns,
              // Hard-set font size here because circular container is not responsive.
              "text-2xl",
              "flex",
              "justify-center",
              "items-center",
              "rounded-full",
              "text-gray-100"
            )}
          >
            <Strong>{driverInitials}</Strong>
          </div>
        ) : (
          Boolean(driverId) && (
            <img
              className={cn(cns, "rounded-full", "object-cover")}
              src={profileImageUrl}
              // If there is no available driver image, provide a fallback.
              // See driverInitials for more details.
              onError={() => setShowDriverInitials(true)}
            />
          )
        )}
      </div>
      <div className={cn("ml-4")}>
        <div className={cn("text-blue-600")}>
          <Strong>
            {driverUser.first_name} {driverUser.last_name}
          </Strong>
        </div>
        <StarRating rating={driverUser.rating} />
      </div>
    </div>
  );
};
