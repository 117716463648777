import { useParams } from "react-router-dom";

export interface RootParams {
  id: string;
}
export const useRootParams = (): RootParams => useParams<RootParams>();

export interface StopParams {
  contact_id: string;
}

export const useStopParams = (): StopParams => useParams<StopParams>();

export interface TransportParams {
  /**
   * Could be the TR id or stop contact id.
   */
  id: string;
}

export const useTransportParams = (): TransportParams => useParams<TransportParams>();
