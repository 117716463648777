import React from "react";
import cn from "classnames";
import { IconLoader } from "@brenger/react";

import { ScrollToTop, Footer, Header } from ".";

interface Props {
  children?: React.ReactNode;
  loading?: boolean;
}

export const Page: React.FC<Props> = ({ loading, children }) => {
  return (
    <ScrollToTop>
      <Header />
      <div className={cn("relative", "flex", "flex-col", "flex-grow")}>
        <div className={cn("flex", "flex-col", "flex-grow", "mt-2")}>
          {loading && (
            <div className={cn("flex", "justify-center", "items-center", "flex-grow")}>
              <IconLoader className={cn("w-8", "h-8")} />
            </div>
          )}
          {!loading && children}
        </div>
      </div>
      <Footer />
    </ScrollToTop>
  );
};
