/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Account, User } from "@brenger/api-client";
import { useQuery } from "react-query";

import { CacheKey, CacheTTL, coreClient, logger } from "../utils";

// NOTE: combine the two user methods into a single method for better ergonomics usage with `useQuery`
const fetchUser = async (): Promise<User> => {
  try {
    await coreClient.users.isUserLoggedIn();
    const user = await coreClient.users.retrieveCurrentUser();
    logger.setUser(user);
    return user;
  } catch (err) {
    throw new Error(err as string);
  }
};

export const useAuth = () => {
  const user = useQuery([CacheKey.RETRIEVE_USER], () => fetchUser(), {
    retry: 1,
    cacheTime: CacheTTL.LG * 1000,
  });

  const userData = user.data;
  const accountData = userData?.account as Account | undefined;

  const businessSlug = accountData?.has_business_flow && accountData.business_name_slug;

  // NOTE: is_suspended is only relevant to drivers.
  // const isActive = !userData?.is_suspended;
  const isActive = true;
  const isCustomer = Boolean(userData?.roles?.includes("ROLE_USER"));

  const isPermitted = isActive && isCustomer;

  return {
    businessSlug,
    user: isPermitted ? userData : null,
    account: isPermitted ? accountData : null,
    loading: user.isLoading,
  };
};
