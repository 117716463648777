import { FoState } from "@brenger/api-client";
import { RouteComponentProps } from "react-router-dom";

// Always need to be prefixed with MP
export enum MpRoutes {
  FLOWS = "/marktplaats/:id/flows",
  FLOWS_FEASIBLE_1 = "/marktplaats/:id/flows/feasible/1",
  FLOWS_SCHEDULE_BUYER = "/marktplaats/:id/flows/schedule_buyer/:page",
  FLOWS_SCHEDULE_BUYER_0 = "/marktplaats/:id/flows/schedule_buyer/0",
  FLOWS_SCHEDULE_BUYER_1 = "/marktplaats/:id/flows/schedule_buyer/1",
  FLOWS_SCHEDULE_BUYER_2 = "/marktplaats/:id/flows/schedule_buyer/2",
  FLOWS_SCHEDULE_BUYER_3 = "/marktplaats/:id/flows/schedule_buyer/3",
  FLOWS_SCHEDULE_BUYER_4 = "/marktplaats/:id/flows/schedule_buyer/4",
  FLOWS_RESCHEDULE_BUYER_1 = "/marktplaats/:id/flows/reschedule_buyer/1",
  FLOWS_SCHEDULE_SELLER = "/marktplaats/:id/flows/schedule_seller/:page",
  FLOWS_SCHEDULE_SELLER_1 = "/marktplaats/:id/flows/schedule_seller/1",
  FLOWS_SCHEDULE_SELLER_2 = "/marktplaats/:id/flows/schedule_seller/2",
  FLOWS_SCHEDULE_SELLER_3 = "/marktplaats/:id/flows/schedule_seller/3",
  FLOWS_SCHEDULE_SELLER_EXPIRED = "/marktplaats/:id/flows/schedule_seller/expired",
  THANK_YOU = "/marktplaats/:id/thank_you/:state/:trId?",
  CANCEL = "/marktplaats/:id/cancel",
  IS_CANCELLED = "/marktplaats/:id/is_cancelled",
  WAITING = "/marktplaats/:id/flows/waiting",
  PROCESSING = "/marktplaats/:id/flows/:processing_state",
  PAYMENT_CANCELED = "/marktplaats/:id/payment_cancelled/:state",
}

interface MpRoutesFeasible {
  type: "flows";
  state: "FEASIBLE";
  progress: 1;
}

interface MpRoutesScheduleBuyer {
  type: "flows";
  state: "SCHEDULE_BUYER";
  progress: 0 | 1 | 2 | 3 | 4;
}

interface MpRoutesScheduleSeller {
  type: "flows";
  state: "SCHEDULE_SELLER";
  progress: 1 | 2 | 3;
}

interface MpRoutesRescheduleBuyer {
  type: "flows";
  state: "RESCHEDULE_BUYER";
  progress: 1;
}

export type MpThankYouStatesWithoutTr =
  | Exclude<FoState, "COMPLETED">
  | "SCHEDULE_SELLER_NEW_DATES"
  | "SCHEDULE_BUYER_EXPLAIN";
export type MpThankYouStatesWithTr = "COMPLETED";
export type MpThankYouStates = MpThankYouStatesWithoutTr | MpThankYouStatesWithTr;
interface MpRoutesThankYou {
  type: "thank_you";
  state: MpThankYouStatesWithoutTr;
}
interface MpRoutesThankYouTrId {
  type: "thank_you";
  state: MpThankYouStatesWithTr;
  trId: string;
}

export type MpRoutesSimpleType = "waiting" | "processing" | "processing_error";
interface MpRoutesSimple {
  type: MpRoutesSimpleType;
}

export type MpRoutePages =
  | MpRoutesFeasible
  | MpRoutesScheduleBuyer
  | MpRoutesRescheduleBuyer
  | MpRoutesScheduleSeller
  | MpRoutesThankYou
  | MpRoutesThankYouTrId
  | MpRoutesSimple;
interface MpRoute {
  id: string;
  page?: MpRoutePages;
  cancel?: boolean;
  isCancelled?: boolean;
}

export type MpRouteComponentProps = RouteComponentProps<{
  id: string;
}>;

export type MpThankYouRouteComponentProps = RouteComponentProps<{
  id: string;
  state: string;
  trId?: string;
}>;

export const GenerateMpRoute = ({ id, page, cancel, isCancelled }: MpRoute): string => {
  if (cancel) {
    return `/marktplaats/${id}/cancel`;
  }
  if (isCancelled) {
    return `/marktplaats/${id}/is_cancelled`;
  }
  if (page) {
    if (page.type === "waiting" || page.type === "processing" || page.type === "processing_error") {
      return `/marktplaats/${id}/flows/${page.type}`;
    }
    if ("progress" in page) {
      return `/marktplaats/${id}/${page.type}/${page.state.toLowerCase()}/${page.progress}`;
    } else if ("trId" in page) {
      return `/marktplaats/${id}/${page.type}/${page.state.toLowerCase()}/${page.trId}`;
    } else if ("state" in page) {
      return `/marktplaats/${id}/${page.type}/${page.state.toLowerCase()}`;
    }
  }
  return `/marktplaats/${id}`;
};
