export enum Routes {
  ROOT = "/",
  TRANSPORT_DETAILS = "/:id",
  TRANSPORT_DETAILS_EDIT = "/:id/edit",
  TRANSPORT_DETAILS_DAMAGE_CLAIM = "/:id/damage",
  MARKTPLAATS = "/marktplaats/:id",
  NOT_FOUND = "/404",
  TRANSPORT_LIST = "/transports",
  LOGIN = "/login",
  LOGOUT = "/logout",
}
