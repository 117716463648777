import React from "react";
import { useTranslationContext } from "../../../hooks";
import { BrengerLogo } from "../../components";
import { MpContainer } from "../../components/Layout";

export const IsCancelledPage: React.FC = () => {
  const { t } = useTranslationContext();
  return (
    <div className="bg-blue-600 text-white h-screen relative">
      <MpContainer>
        <div className="mb-8">
          <BrengerLogo className="mt-2 mb-6 mx-auto" />
        </div>
        <h1 className="mb-2">{t((d) => d.fo.is_cancelled.title)}</h1>
      </MpContainer>
      <MpContainer>{t((d) => d.fo.is_cancelled.text)}</MpContainer>
      <div className="absolute bottom-0 left-0 right-0 border-b-[10px] border-green-400">
        <MpContainer className="!pb-0">
          <div className="bg-white py-3 px-6 mb-3 inline-block rounded-3xl text-blue-600 relative left-16">
            <b>{"Tuut tuut!"}</b>
          </div>
          <svg width="97" height="48" viewBox="0 0 97 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M81.9675 2.64068L91.4823 22.0309L49.71 22.6531L50.5142 6.55249L64.8972 1.88281L81.9675 2.64068Z"
              fill="#FEFEFE"
            />
            <path
              d="M91.5679 21.8459H64.5592C64.0589 21.845 63.5792 21.6461 63.2251 21.2926C62.871 20.9391 62.6713 20.4598 62.6695 19.9595V4.42809C62.6721 3.92801 62.8722 3.44921 63.2261 3.09591C63.58 2.74261 64.0591 2.54342 64.5592 2.54168H81.8811C81.3515 1.61171 80.4249 0.774414 78.2075 0.774414H2.6785C2.21857 0.774414 1.77748 0.95712 1.45226 1.28234C1.12704 1.60756 0.944336 2.04865 0.944336 2.50858V37.2251C0.948712 38.1877 1.33418 39.1094 2.01642 39.7885C2.69866 40.4677 3.62213 40.849 4.58477 40.8489H92.8785C93.5903 40.8489 94.273 40.5662 94.7763 40.0628C95.2797 39.5595 95.5625 38.8768 95.5625 38.165L96.3998 27.803C95.5294 26.9193 93.504 24.2982 91.5679 21.8459Z"
              fill="#005FCE"
            />
            <path
              d="M77.0624 43.9965C76.4399 43.9965 75.8314 43.812 75.3138 43.4661C74.7962 43.1203 74.3928 42.6287 74.1546 42.0536C73.9164 41.4786 73.8541 40.8457 73.9755 40.2352C74.097 39.6247 74.3967 39.0639 74.8369 38.6237C75.277 38.1836 75.8378 37.8838 76.4483 37.7624C77.0589 37.6409 77.6917 37.7033 78.2668 37.9415C78.8419 38.1797 79.3334 38.5831 79.6793 39.1007C80.0251 39.6182 80.2097 40.2267 80.2097 40.8492C80.2079 41.6834 79.8758 42.4829 79.2859 43.0728C78.6961 43.6626 77.8965 43.9948 77.0624 43.9965ZM77.0624 37.7913C76.4576 37.7913 75.8663 37.9706 75.3634 38.3066C74.8606 38.6426 74.4686 39.1202 74.2372 39.679C74.0057 40.2378 73.9452 40.8526 74.0632 41.4458C74.1811 42.039 74.4724 42.5839 74.9001 43.0115C75.3277 43.4392 75.8726 43.7304 76.4658 43.8484C77.059 43.9664 77.6738 43.9059 78.2326 43.6744C78.7914 43.443 79.2689 43.051 79.605 42.5481C79.941 42.0453 80.1203 41.454 80.1203 40.8492C80.1194 40.0382 79.7971 39.2605 79.2239 38.6867C78.6507 38.1129 77.8734 37.7897 77.0624 37.788V37.7913Z"
              fill="black"
            />
            <path
              d="M77.062 47.7628C75.6946 47.7628 74.3579 47.3574 73.221 46.5977C72.0841 45.838 71.198 44.7583 70.6747 43.495C70.1514 42.2317 70.0145 40.8416 70.2813 39.5005C70.548 38.1595 71.2065 36.9276 72.1734 35.9607C73.1402 34.9938 74.3721 34.3354 75.7132 34.0686C77.0543 33.8019 78.4444 33.9388 79.7076 34.4621C80.9709 34.9853 82.0507 35.8714 82.8103 37.0084C83.57 38.1453 83.9755 39.4819 83.9755 40.8493C83.9737 42.6823 83.2448 44.4398 81.9486 45.736C80.6524 47.0321 78.895 47.7611 77.062 47.7628ZM77.062 34.0252C75.7123 34.0252 74.3929 34.4254 73.2707 35.1752C72.1484 35.9251 71.2738 36.9909 70.7573 38.2378C70.2408 39.4848 70.1056 40.8569 70.3689 42.1806C70.6322 43.5044 71.2822 44.7203 72.2365 45.6747C73.1909 46.6291 74.4069 47.279 75.7306 47.5423C77.0544 47.8056 78.4265 47.6705 79.6734 47.154C80.9204 46.6375 81.9862 45.7628 82.736 44.6406C83.4859 43.5184 83.8861 42.199 83.8861 40.8493C83.8852 39.0397 83.166 37.3045 81.8864 36.0249C80.6068 34.7453 78.8716 34.026 77.062 34.0252Z"
              fill="black"
            />
            <path
              d="M18.1074 43.9499C17.6999 43.9503 17.2963 43.8704 16.9197 43.7148C16.5431 43.5591 16.2008 43.3308 15.9125 43.0428C15.6242 42.7548 15.3955 42.4128 15.2395 42.0363C15.0834 41.6599 15.0031 41.2564 15.0031 40.8489H11.2402C11.2402 42.6702 11.9637 44.4169 13.2516 45.7047C14.5394 46.9925 16.2861 47.7161 18.1074 47.7161C19.9287 47.7161 21.6754 46.9925 22.9632 45.7047C24.2511 44.4169 24.9746 42.6702 24.9746 40.8489H21.2084C21.2088 41.2562 21.1289 41.6597 20.9732 42.0361C20.8176 42.4125 20.5892 42.7545 20.3011 43.0426C20.0131 43.3306 19.671 43.559 19.2946 43.7147C18.9182 43.8704 18.5148 43.9503 18.1074 43.9499Z"
              fill="#013B3D"
            />
            <path
              d="M18.1074 33.9819C16.2861 33.9819 14.5394 34.7054 13.2516 35.9933C11.9637 37.2811 11.2402 39.0278 11.2402 40.8491H15.0031C15.0294 40.0438 15.3678 39.2802 15.9468 38.7198C16.5258 38.1595 17.3 37.8462 18.1058 37.8462C18.9115 37.8462 19.6857 38.1595 20.2647 38.7198C20.8437 39.2802 21.1821 40.0438 21.2084 40.8491H24.9746C24.9746 39.0278 24.2511 37.2811 22.9632 35.9933C21.6754 34.7054 19.9287 33.9819 18.1074 33.9819Z"
              fill="#FEFEFE"
            />
            <path
              d="M18.1072 40.2863C18.1809 40.2863 18.254 40.3009 18.322 40.3292C18.3901 40.3575 18.4519 40.399 18.5039 40.4513C18.5559 40.5036 18.597 40.5656 18.6249 40.6338C18.6528 40.7021 18.667 40.7752 18.6665 40.8489H21.2082C21.1819 40.0436 20.8435 39.28 20.2645 38.7196C19.6855 38.1592 18.9113 37.8459 18.1056 37.8459C17.2998 37.8459 16.5256 38.1592 15.9466 38.7196C15.3676 39.28 15.0292 40.0436 15.0029 40.8489H17.5446C17.5446 40.6997 17.6039 40.5566 17.7094 40.4511C17.8149 40.3456 17.958 40.2863 18.1072 40.2863Z"
              fill="#00EC9D"
            />
            <path
              d="M18.1066 41.4279C18.1803 41.4279 18.2533 41.4133 18.3214 41.385C18.3894 41.3566 18.4512 41.3152 18.5032 41.2629C18.5552 41.2106 18.5963 41.1485 18.6242 41.0803C18.6521 41.012 18.6663 40.939 18.6659 40.8652H17.5439C17.5439 41.0144 17.6032 41.1576 17.7087 41.2631C17.8142 41.3686 17.9573 41.4279 18.1066 41.4279Z"
              fill="#005FCE"
            />
            <path
              d="M18.1066 40.2856C17.9573 40.2856 17.8142 40.3449 17.7087 40.4504C17.6032 40.5559 17.5439 40.699 17.5439 40.8483H18.6659C18.6663 40.7745 18.6521 40.7014 18.6242 40.6332C18.5963 40.565 18.5552 40.5029 18.5032 40.4506C18.4512 40.3983 18.3894 40.3569 18.3214 40.3285C18.2533 40.3002 18.1803 40.2856 18.1066 40.2856Z"
              fill="#005FCE"
            />
            <path
              d="M77.0625 43.9499C76.2401 43.9499 75.4513 43.6231 74.8698 43.0416C74.2882 42.4601 73.9615 41.6713 73.9615 40.8489H70.1953C70.1953 42.6702 70.9188 44.4169 72.2067 45.7047C73.4945 46.9925 75.2412 47.7161 77.0625 47.7161C78.8838 47.7161 80.6305 46.9925 81.9183 45.7047C83.2062 44.4169 83.9297 42.6702 83.9297 40.8489H80.1535C80.1535 41.2564 80.0732 41.6599 79.9172 42.0363C79.7611 42.4128 79.5324 42.7548 79.2441 43.0428C78.9558 43.3308 78.6136 43.5591 78.237 43.7148C77.8603 43.8704 77.4568 43.9503 77.0492 43.9499"
              fill="#013B3D"
            />
            <path
              d="M77.0625 33.9819C75.2412 33.9819 73.4945 34.7054 72.2067 35.9933C70.9188 37.2811 70.1953 39.0278 70.1953 40.8491H73.9615C73.9878 40.0438 74.3262 39.2802 74.9052 38.7198C75.4842 38.1595 76.2584 37.8462 77.0641 37.8462C77.8699 37.8462 78.6441 38.1595 79.2231 38.7198C79.8021 39.2802 80.1405 40.0438 80.1668 40.8491H83.9297C83.9297 39.0278 83.2062 37.2811 81.9183 35.9933C80.6305 34.7054 78.8838 33.9819 77.0625 33.9819Z"
              fill="#FEFEFE"
            />
            <path
              d="M77.0625 40.2863C77.2117 40.2863 77.3548 40.3456 77.4603 40.4511C77.5658 40.5566 77.6251 40.6997 77.6251 40.8489H80.1535C80.1272 40.0436 79.7888 39.28 79.2098 38.7196C78.6308 38.1592 77.8566 37.8459 77.0509 37.8459C76.2451 37.8459 75.4709 38.1592 74.8919 38.7196C74.313 39.28 73.9745 40.0436 73.9482 40.8489H76.5131C76.5131 40.7003 76.5719 40.5577 76.6767 40.4522C76.7815 40.3468 76.9238 40.2872 77.0724 40.2863"
              fill="#00EC9D"
            />
            <path
              d="M77.063 41.4279C77.2123 41.4279 77.3554 41.3686 77.4609 41.2631C77.5664 41.1576 77.6257 41.0144 77.6257 40.8652H76.5137C76.5137 41.0139 76.5725 41.1565 76.6773 41.2619C76.7821 41.3673 76.9243 41.427 77.073 41.4279"
              fill="#005FCE"
            />
            <path
              d="M77.0613 40.2856C76.9126 40.2865 76.7704 40.3462 76.6656 40.4516C76.5608 40.557 76.502 40.6996 76.502 40.8483H77.6239C77.6239 40.699 77.5646 40.5559 77.4591 40.4504C77.3536 40.3449 77.2105 40.2856 77.0613 40.2856Z"
              fill="#005FCE"
            />
            <g opacity="0.7">
              <path
                opacity="0.7"
                d="M18.1084 47.7163C19.9245 47.7163 21.6667 46.9969 22.9537 45.7155C24.2407 44.4341 24.9677 42.695 24.9756 40.8789H11.2412C11.2491 42.695 11.9761 44.4341 13.2631 45.7155C14.5501 46.9969 16.2922 47.7163 18.1084 47.7163Z"
                fill="#333333"
              />
            </g>
            <path d="M18.7928 40.8789H17.416V41.4746H18.7928V40.8789Z" fill="#005FCE" />
            <path
              d="M77.0619 43.9499C77.4694 43.9503 77.873 43.8704 78.2496 43.7148C78.6262 43.5591 78.9685 43.3308 79.2568 43.0428C79.5451 42.7548 79.7738 42.4128 79.9299 42.0363C80.0859 41.6599 80.1662 41.2564 80.1662 40.8489H77.6245C77.6305 40.9262 77.6204 41.0038 77.5949 41.0771C77.5694 41.1503 77.529 41.2174 77.4764 41.2743C77.4237 41.3312 77.3598 41.3765 77.2888 41.4076C77.2178 41.4386 77.1411 41.4546 77.0636 41.4546C76.9861 41.4546 76.9094 41.4386 76.8383 41.4076C76.7673 41.3765 76.7035 41.3312 76.6508 41.2743C76.5981 41.2174 76.5578 41.1503 76.5323 41.0771C76.5068 41.0038 76.4967 40.9262 76.5026 40.8489H73.9609C73.9609 41.6713 74.2876 42.4601 74.8692 43.0416C75.4507 43.6231 76.2395 43.9499 77.0619 43.9499Z"
              fill="#B9FFE6"
            />
            <path
              d="M18.6628 40.8789C18.652 41.0194 18.5886 41.1506 18.4852 41.2463C18.3818 41.342 18.2461 41.3952 18.1052 41.3952C17.9643 41.3952 17.8286 41.342 17.7252 41.2463C17.6218 41.1506 17.5584 41.0194 17.5475 40.8789H15.0059C15.0059 41.7013 15.3326 42.4901 15.9141 43.0716C16.4957 43.6532 17.2844 43.9799 18.1068 43.9799C18.9293 43.9799 19.718 43.6532 20.2996 43.0716C20.8811 42.4901 21.2078 41.7013 21.2078 40.8789H18.6628Z"
              fill="#B9FFE6"
            />
            <path
              d="M67.2461 23.8089H66.6173C66.5992 23.8083 66.5815 23.8139 66.5671 23.8247C66.5526 23.8356 66.5423 23.851 66.5378 23.8684C66.5037 24.0551 66.4052 24.2238 66.2594 24.3453C66.1137 24.4668 65.93 24.5334 65.7403 24.5334C65.5505 24.5334 65.3668 24.4668 65.2211 24.3453C65.0753 24.2238 64.9768 24.0551 64.9427 23.8684C64.9382 23.851 64.9278 23.8356 64.9134 23.8247C64.899 23.8139 64.8813 23.8083 64.8632 23.8089H64.2477C64.2367 23.8088 64.2258 23.8111 64.2157 23.8155C64.2056 23.8199 64.1965 23.8263 64.189 23.8344C64.1816 23.8425 64.1759 23.8521 64.1723 23.8625C64.1687 23.8729 64.1673 23.8839 64.1682 23.8949C64.2117 24.2814 64.3961 24.6383 64.6861 24.8975C64.976 25.1566 65.3513 25.2999 65.7403 25.2999C66.1292 25.2999 66.5044 25.1566 66.7944 24.8975C67.0844 24.6383 67.2688 24.2814 67.3123 23.8949C67.3142 23.8749 67.3084 23.8548 67.2961 23.8388C67.2839 23.8228 67.266 23.8121 67.2461 23.8089Z"
              fill="#FEFEFE"
            />
            <path
              d="M94.3768 31.1053C94.5524 29.409 94.3366 27.9969 93.8948 27.9511C93.453 27.9054 92.9525 29.2434 92.7769 30.9397C92.6013 32.6359 92.8171 34.0481 93.2589 34.0938C93.7007 34.1395 94.2012 32.8015 94.3768 31.1053Z"
              fill="#FEFEFE"
            />
            <path
              d="M65.9458 2.5419H39.8737C39.3739 2.54364 38.8951 2.74295 38.5418 3.09634C38.1884 3.44973 37.989 3.92854 37.9873 4.4283V36.7553C37.989 37.2551 38.1884 37.7339 38.5418 38.0873C38.8951 38.4407 39.3739 38.64 39.8737 38.6417H65.9458C66.4459 38.6409 66.9252 38.4418 67.2787 38.0883C67.6323 37.7347 67.8313 37.2554 67.8322 36.7553V2.59816C67.8322 2.48564 66.985 2.5419 65.9458 2.5419Z"
              fill="#00C8FF"
            />
            <path
              d="M64.4213 23.8089H63.8057C63.7877 23.8083 63.77 23.8139 63.7556 23.8247C63.7411 23.8356 63.7308 23.851 63.7263 23.8684C63.6922 24.0551 63.5936 24.2238 63.4479 24.3453C63.3022 24.4668 63.1185 24.5334 62.9287 24.5334C62.739 24.5334 62.5553 24.4668 62.4095 24.3453C62.2638 24.2238 62.1653 24.0551 62.1311 23.8684C62.1266 23.851 62.1163 23.8356 62.1019 23.8247C62.0874 23.8139 62.0697 23.8083 62.0517 23.8089H61.4361C61.4251 23.8088 61.4142 23.8111 61.4041 23.8155C61.394 23.8199 61.385 23.8263 61.3775 23.8344C61.37 23.8425 61.3643 23.8521 61.3608 23.8625C61.3572 23.8729 61.3558 23.8839 61.3567 23.8949C61.4002 24.2814 61.5846 24.6383 61.8745 24.8975C62.1645 25.1566 62.5398 25.2999 62.9287 25.2999C63.3176 25.2999 63.6929 25.1566 63.9829 24.8975C64.2729 24.6383 64.4572 24.2814 64.5007 23.8949C64.5016 23.8839 64.5003 23.8729 64.4967 23.8625C64.4931 23.8521 64.4874 23.8425 64.4799 23.8344C64.4725 23.8263 64.4634 23.8199 64.4533 23.8155C64.4432 23.8111 64.4323 23.8088 64.4213 23.8089Z"
              fill="#FEFEFE"
            />
            <path
              d="M72.9418 23.8089H72.3262C72.3082 23.8083 72.2905 23.8139 72.2761 23.8247C72.2616 23.8356 72.2513 23.851 72.2468 23.8684C72.2127 24.0551 72.1142 24.2238 71.9684 24.3453C71.8227 24.4668 71.639 24.5334 71.4492 24.5334C71.2595 24.5334 71.0758 24.4668 70.93 24.3453C70.7843 24.2238 70.6858 24.0551 70.6516 23.8684C70.6471 23.851 70.6368 23.8356 70.6224 23.8247C70.608 23.8139 70.5903 23.8083 70.5722 23.8089H69.9567C69.9456 23.8088 69.9347 23.8111 69.9246 23.8155C69.9146 23.8199 69.9055 23.8263 69.898 23.8344C69.8905 23.8425 69.8848 23.8521 69.8813 23.8625C69.8777 23.8729 69.8763 23.8839 69.8772 23.8949C69.9207 24.2814 70.1051 24.6383 70.3951 24.8975C70.685 25.1566 71.0603 25.2999 71.4492 25.2999C71.8381 25.2999 72.2134 25.1566 72.5034 24.8975C72.7934 24.6383 72.9777 24.2814 73.0212 23.8949C73.0222 23.8839 73.0208 23.8729 73.0172 23.8625C73.0136 23.8521 73.0079 23.8425 73.0004 23.8344C72.993 23.8263 72.9839 23.8199 72.9738 23.8155C72.9637 23.8111 72.9528 23.8088 72.9418 23.8089Z"
              fill="#FEFEFE"
            />
            <path
              opacity="0.55"
              d="M91.5546 21.8457H48.0514C47.5514 21.8448 47.0721 21.6458 46.7185 21.2922C46.3649 20.9387 46.1659 20.4594 46.165 19.9593V4.42791C46.165 3.39205 46.2312 2.5415 46.2312 2.5415H81.8744C83.1982 4.85814 85.0746 8.93873 91.5546 21.8457Z"
              fill="#FEFEFE"
            />
            <path opacity="0.3" d="M0.875 27.7493H96.2973L91.5813 21.8452L0.875 21.9577V27.7493Z" fill="#FEFEFE" />
          </svg>
        </MpContainer>
      </div>
    </div>
  );
};
