import { IconCheck } from "@brenger/react";
import cn from "classnames";
import React from "react";

export const CheckmarkList: React.FC<{
  items: React.ReactNode[];
  verticalAlign?: "top" | "center";
  extraSpacing?: boolean;
}> = ({ items, verticalAlign = "center", extraSpacing = true }) => {
  const alignClass = {
    top: "items-start",
    center: "items-center",
  }[verticalAlign];

  const checkmarkStyle = {
    fontSize: "1.2em",
    marginTop: verticalAlign === "top" ? "0.2em" : undefined,
  };
  return (
    <>
      {items.map((item, i) => {
        return (
          <div key={i} className={cn("flex flex-wrap gap-1", { "mb-2": extraSpacing }, alignClass)}>
            <IconCheck className={cn("text-green-400")} style={checkmarkStyle} />
            <div style={{ width: "calc(100% - 1.8rem)" }}>{item}</div>
          </div>
        );
      })}
    </>
  );
};
