import { FoParty, FoState } from "@brenger/api-client";

export const isWaitingState = ({ party, state }: { party: FoParty; state: FoState }): boolean => {
  const waitingStates: Record<FoParty, FoState[]> = {
    buyer: ["SCHEDULE_SELLER"],
    seller: ["FEASIBLE", "SCHEDULE_BUYER", "RESCHEDULE_BUYER", "NOT_FEASIBLE"],
  };
  return waitingStates[party].includes(state);
};

/**
 * From FoPrice interface to cents in as number, example: {value: "5.00", currency: "EUR"} becomes 500
 */
export const foPriceToCents = (price: string): number => {
  return Number(price.replace(".", "").replace(",", ""));
};

/**
 * From cents to price as string, example: 500 becomes "5.00"
 */
export const centsToPriceString = (cents: number): string => {
  return (cents / 100).toFixed(2);
};
