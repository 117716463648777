import { Button } from "@brenger/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslationContext } from "../../../hooks";
import { DotList } from "../../components/DotList";
import { MpContainer, ThankYouLayout } from "../../components/Layout";

export const ThankYouScheduleSeller: React.FC<{ trId?: string }> = ({ trId }) => {
  const history = useHistory();
  const { t } = useTranslationContext();

  return (
    <ThankYouLayout title={t((d) => d.fo.thank_you.schedule_seller.title)}>
      <MpContainer>
        <DotList
          items={[
            t((d) => d.fo.thank_you.schedule_seller.step_1),
            t((d) => d.fo.thank_you.schedule_seller.step_2),
            t((d) => d.fo.thank_you.schedule_seller.step_3),
          ]}
        />
      </MpContainer>
      {trId && (
        <MpContainer>
          <Button className="w-full mb-4" buttonType="secondary" onClick={() => history.push(`/${trId}`)}>
            {t((d) => d.fo.thank_you.schedule_seller.button as string)}
          </Button>
        </MpContainer>
      )}
    </ThankYouLayout>
  );
};
