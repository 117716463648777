import { Button } from "@brenger/react";
import cn from "classnames";
import React from "react";

import { Modal, ModalProps } from "../../../components";
import { useTranslationContext } from "../../../hooks";

export const CantCancelModalRegular: React.FC<ModalProps> = (props) => {
  const { t } = useTranslationContext();

  return (
    <Modal isActive={props.isActive} closeHandler={props.closeHandler}>
      <h3 className={cn("mb-4")}>{t((d) => d.cant_cancel_tr_title)}</h3>
      <div className={cn("mb-4")} dangerouslySetInnerHTML={{ __html: t((d) => d.cant_cancel_tr_text) }} />
      <Button className={"w-full"} buttonType="primary" onClick={props.closeHandler}>
        {t((d) => d.cant_cancel_tr_button)}
      </Button>
    </Modal>
  );
};
