"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLocale = exports.removeStoredLocale = exports.storeLocale = exports.sanitizeLocale = exports.LOCALE_LOCAL_STORAGE_KEY = exports.LOCALE_SEARCH_PARAM = exports.SYSTEM_DEFAULT_LOCALE = void 0;
var navigator_1 = require("./navigator");
var params_1 = require("./params");
exports.SYSTEM_DEFAULT_LOCALE = 'nl-NL';
exports.LOCALE_SEARCH_PARAM = 'locale';
exports.LOCALE_LOCAL_STORAGE_KEY = 'brenger_locale_cache';
var HYPHEN = '-';
var UNDERSCORE = '_';
// NOTE: formats locales according to JS spec.
var sanitizeLocale = function (locale) { return locale.replace(UNDERSCORE, HYPHEN); };
exports.sanitizeLocale = sanitizeLocale;
var storeLocale = function (locale) {
    try {
        localStorage.setItem(exports.LOCALE_LOCAL_STORAGE_KEY, locale);
    }
    catch (_) {
        // eslint-disable-next-line no-console
        console.log('local storage failed - not stored');
    }
};
exports.storeLocale = storeLocale;
var removeStoredLocale = function () {
    try {
        localStorage.removeItem(exports.LOCALE_LOCAL_STORAGE_KEY);
    }
    catch (_) {
        // eslint-disable-next-line no-console
        console.log('local storage failed - not removed');
    }
};
exports.removeStoredLocale = removeStoredLocale;
var getLocale = function (defaultLocale) {
    var storage = null;
    try {
        storage = localStorage.getItem(exports.LOCALE_LOCAL_STORAGE_KEY);
    }
    catch (_) {
        // eslint-disable-next-line no-console
        console.log("local storage failed - couldn't load");
    }
    var param = (0, params_1.getSearchParam)(exports.LOCALE_SEARCH_PARAM);
    var nav = (0, navigator_1.getNavigator)();
    // example return values: "en-US" or "de_DE" or "fr"
    var locale = storage || param || (nav === null || nav === void 0 ? void 0 : nav.language) || defaultLocale || exports.SYSTEM_DEFAULT_LOCALE;
    return (0, exports.sanitizeLocale)(locale);
};
exports.getLocale = getLocale;
