import { DateFormatType, formatDate } from "@brenger/utils";

import { useTranslationContext } from ".";

export const useFormatDate = (format: DateFormatType): ((date: Date | string | null) => string | null) => {
  const { i18n } = useTranslationContext();

  return (date: Date | string | null) => {
    if (!date) return null;
    return formatDate(date, format, { locale: i18n.locale, timeZone: i18n.timeZone });
  };
};
