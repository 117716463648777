import React from "react";
import { MpContainer, MpLayout } from "../../components/Layout";
import { MpFooter, Modal, MpModalContentHead, PageHead } from "../../components";
import { Button } from "@brenger/react";
import { useTranslationContext } from "../../../hooks";
import { useMutation, useQueryClient } from "react-query";
import { CacheKey, foClient } from "../../../utils";
import { FoPartyIdParams } from "@brenger/api-client";
import { useHistory } from "react-router-dom";
import { GenerateMpRoute } from "../../routes";

export const BuyerCancelPage: React.FC<FoPartyIdParams> = ({ partyId }) => {
  const { t } = useTranslationContext();
  const history = useHistory();
  const queryClient = useQueryClient();
  const cancel = useMutation([CacheKey.FO_TRIGGER_CANCEL, partyId], foClient.marktplaats.cancelDraftTr, {
    onSuccess: () => {
      queryClient.clear();
      history.push(GenerateMpRoute({ id: partyId }));
    },
  });
  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);
  return (
    <MpLayout>
      <PageHead title={t((d) => d.fo.buyer_cancel.title)} />
      <MpContainer>
        <div>{t((d) => d.fo.buyer_cancel.text)}</div>
      </MpContainer>
      <MpFooter>
        <MpContainer>
          <Button
            disabled={cancel.isLoading}
            loading={cancel.isLoading}
            className="w-full"
            buttonType="warning"
            onClick={() => setIsCancelModalOpen(true)}
          >
            {t((d) => d.fo.buyer_cancel.button)}
          </Button>
        </MpContainer>
      </MpFooter>
      <Modal
        header={t((d) => d.fo.buyer_cancel.modal?.head as string)}
        isOpen={isCancelModalOpen}
        closeHandler={() => setIsCancelModalOpen(false)}
        footer={
          <Button
            buttonType="warning"
            loading={cancel.isLoading}
            onClick={() => {
              cancel.reset();
              cancel.mutate({ partyId });
            }}
            className="w-full"
          >
            {t((d) => d.fo.buyer_cancel.modal?.button as string)}
          </Button>
        }
      >
        <MpModalContentHead title={t((d) => d.fo.buyer_cancel.modal?.title as string)} />
        {t((d) => d.fo.buyer_cancel.modal?.text as string)}
      </Modal>
    </MpLayout>
  );
};
