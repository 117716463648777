import React from "react";
import phoneNumberImage from "../assets/IlluPhoneNumber.svg";

export const VanSpeechBalloon: React.FC<{ text: string }> = ({ text }) => {
  return (
    <>
      <div className="bg-white p-3 mb-3 inline-block rounded-3xl text-blue-600 relative left-32">
        <b>{text}</b>
      </div>
      <img className={"mr-16"} src={phoneNumberImage} />
    </>
  );
};
