"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPrice = void 0;
var locale_1 = require("./locale");
var DEFAULT_UNIT_AMOUNT = 100;
var DEFAULT_CURRENCY = 'EUR';
var formatPrice = function (_a) {
    var amount = _a.amount, locale = _a.locale, _b = _a.unitAmount, unitAmount = _b === void 0 ? DEFAULT_UNIT_AMOUNT : _b, _c = _a.currency, currency = _c === void 0 ? DEFAULT_CURRENCY : _c;
    var num = amount / unitAmount;
    // Why sanitize? Bc sometimes core still returns `preferred_locale` (nl_NL) using an underscore.
    var sanitizedLocale = (0, locale_1.sanitizeLocale)(locale);
    var formatConfig = { style: 'currency', currency: currency };
    var format = new Intl.NumberFormat(sanitizedLocale, formatConfig).format;
    var formattedNumber = format(num);
    return formattedNumber;
};
exports.formatPrice = formatPrice;
