"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasFloorInstruction = exports.hasTimeInstruction = exports.hasPhoneInstruction = void 0;
/**
 * Determines if given string has phone number or call instruction, for given langs
 */
var hasPhoneInstruction = function (text, langs) {
    var _a;
    /**
     * Match groups that smell like a numeric phone number notation
     */
    var numeric = Boolean((_a = text.match(/([+\d( )\-()]{10,18})/i)) === null || _a === void 0 ? void 0 : _a.length);
    /**
     * Match groups consisting of specific words
     */
    var written = langs
        .map(function (lang) {
        var _a, _b, _c, _d;
        return {
            nl: Boolean((_a = text.match(/(bel|telefoon|nummer)/i)) === null || _a === void 0 ? void 0 : _a.length),
            en: Boolean((_b = text.match(/(call|telephone|number)/i)) === null || _b === void 0 ? void 0 : _b.length),
            de: Boolean((_c = text.match(/(Anruf|Telefon|Nummer)/i)) === null || _c === void 0 ? void 0 : _c.length),
            es: Boolean((_d = text.match(/(llama|llamar|llamame|telefono|numero)/i)) === null || _d === void 0 ? void 0 : _d.length)
        }[lang];
    })
        .some(Boolean);
    return numeric || written;
};
exports.hasPhoneInstruction = hasPhoneInstruction;
/**
 * Determines if given string has a time instruction, for given langs
 */
var hasTimeInstruction = function (text, langs) {
    var _a;
    /**
     * Match groups that smell like a numeric time notation
     */
    var numeric = Boolean((_a = text.match(/(\d{1,2}[:.][\d]{2}([^0-9]|$))/i)) === null || _a === void 0 ? void 0 : _a.length);
    /**
     * Match groups that smell like a written notation
     */
    var written = langs
        .map(function (lang) {
        var _a, _b, _c, _d;
        return {
            nl: Boolean((_a = text.match(/(Ochtend|Middag|Avond|Uur)/i)) === null || _a === void 0 ? void 0 : _a.length),
            en: Boolean((_b = text.match(/(Morning|Noon|Afternoon|Evening|Hour)/i)) === null || _b === void 0 ? void 0 : _b.length),
            de: Boolean((_c = text.match(/(Morgen|Mittag|Nachmittag|Abend|Stunde)/i)) === null || _c === void 0 ? void 0 : _c.length),
            es: Boolean((_d = text.match(/(Ma(ñ|n)ana|Mediodia|Tarde|Noche|Hora)/i)) === null || _d === void 0 ? void 0 : _d.length)
        }[lang];
    })
        .some(Boolean);
    return numeric || written;
};
exports.hasTimeInstruction = hasTimeInstruction;
/**
 * Determines if given string has floor level instruction, for given langs
 */
var hasFloorInstruction = function (text, langs) {
    return langs
        .map(function (lang) {
        var _a, _b, _c, _d;
        return {
            nl: Boolean((_a = text.match(/(-[1-9]{1}\b\s+)|(\d{1,2}(ste|e))|(eerste|tweede|derde|vierde|vijfde|zesde|verdieping|etage|kelder)/i)) === null || _a === void 0 ? void 0 : _a.length),
            en: Boolean((_b = text.match(/(-[1-9]{1}\b\s+)|(\d{1,2}(st|nd|th|e))|(first|second|third|fourth|fifth|sixth|floor|level|basement)/i)) === null || _b === void 0 ? void 0 : _b.length),
            de: Boolean((_c = text.match(/(-[1-9]{1}\b\s+)|(\d{1,2}.)|(erste|zweite|dritte|vierte|fünfte|sechste|Etage|Stufe|Keller)/i)) === null || _c === void 0 ? void 0 : _c.length),
            es: Boolean((_d = text.match(/(-[1-9]{1}\b\s+)|(\d{1,2}.)|(primero|segundo|tercero|cuarto|quinto|sexto|planta|nivel|s(ó|o)tano)/i)) === null || _d === void 0 ? void 0 : _d.length)
        }[lang];
    })
        .some(Boolean);
};
exports.hasFloorInstruction = hasFloorInstruction;
