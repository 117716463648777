"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.returnMock = void 0;
/**
 * Simple mock promise that adds a little networking latency
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var returnMock = function (result) {
    // Return mock with latency
    var min = 200;
    var max = 2000;
    var n = Math.floor(Math.random() * (max - min + 1) + min);
    return new Promise(function (resolve) { return setTimeout(function () { return resolve(result); }, n); });
};
exports.returnMock = returnMock;
