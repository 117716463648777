import { FoParty, FoState } from "@brenger/api-client";
import { FoInteraction, TrackingPriceRange } from "./eventTracking";

type ClarityCrashTag = Record<"crash", string> | Record<"crash_id", string>;
type ClarityFoPartyTag = Record<"foParty", FoParty>;
type ClarityPurchaseTag = Partial<Record<"purchase" | "upsell", TrackingPriceRange>>;
export type ClarityFoInteractionTag = Record<FoInteraction | "Fo State", string | number | FoState>;

type ClarityTag = ClarityCrashTag | ClarityFoPartyTag | ClarityPurchaseTag | ClarityFoInteractionTag;

declare global {
  function clarity(action: "set", key: string, value: string): void;
}

export const clarityTag = (tag: ClarityTag): void => {
  if (typeof clarity !== "function") return;
  for (const [key, value] of Object.entries(tag)) {
    clarity("set", key, value as string);
  }
};
