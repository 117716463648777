/* eslint-disable no-undef, @typescript-eslint/naming-convention */

export const LOCALE_TEMPLATE = "{{locale}}";

export const Config = Object.freeze({
  NODE_ENV: process.env.NODE_ENV,
  API_URL: process.env.API_URL,
  SANDBOX_API_URL: "https://sandbox.api.brenger.nl",
  USER_DATA_URL: process.env.USER_DATA_URL,
  SENTRY_DSN: process.env.SENTRY_DSN,
  GOOGLE_MAPS_KEY: process.env.GOOGLE_MAPS_KEY,
  PRICE_API_URL: process.env.PRICE_API_URL,
  CUSTOMER_URL: process.env.CUSTOMER_URL || "https://www.brenger.nl",
  ROUTE_PLANNER_URL: process.env.ROUTE_PLANNER_URL,
  EXTERNAL_API_URL: process.env.EXTERNAL_API_URL || "https://external-api.brenger.nl",
  SANDBOX_EXTERNAL_API_URL: "https://sandbox.external-api.brenger.nl",
  // NOTE: COMMIT_SHA is provided by gitlab CI
  COMMIT_SHA: process.env.CI_COMMIT_SHA,
  FRESH_CHAT_TOKEN: "0650cb2d-35de-45e3-a345-dbbf4b4a95cc",
  I18N_URL: "https://api.brenger.nl/i18n/translations",
  GOOGLE_REVIEW_URL: "https://search.google.com/local/writereview?placeid=ChIJDxcpbp4JxkcRY_DecNr1mb8",
  CUSTOMER_FAQ_URL: "https://www.brenger.nl/{{locale}}/faq",
  TRUST_PILOT_REVIEW_URL: "https://nl.trustpilot.com/evaluate/brenger.nl",
});
