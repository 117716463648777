import { FoAvailableService, FoPartyIdParams } from "@brenger/api-client";
import { useQuery } from "react-query";
import { useTranslationContext } from "../../hooks";
import { CacheKey, foClient } from "../../utils";

export interface FoPublicAvailableService extends FoAvailableService {
  public_name: string;
  pros: string[];
  isStandard: boolean;
  isFloorLevelServiceAvailable: boolean;
}

interface UseExtraServiceOptions {
  isLoading: boolean;
  isFetching: boolean;
  options: FoPublicAvailableService[];
}

export const useExtraServiceOptions = ({
  partyId,
  isEnabled,
}: FoPartyIdParams & { isEnabled?: boolean }): UseExtraServiceOptions => {
  const { t } = useTranslationContext();
  const availableServices = useQuery(
    [CacheKey.FO_RETRIEVE_AVAILABLE_SERVICES, partyId],
    () =>
      foClient.marktplaats.retrieveAvailableServices({
        partyId: partyId,
      }),
    {
      enabled: isEnabled,
    }
  );

  if (availableServices.isLoading) {
    return {
      isLoading: true,
      isFetching: true,
      options: [],
    };
  }

  const options: FoPublicAvailableService[] = (availableServices.data?.levels || []).map((o) => {
    return {
      ...o,
      public_name: t((d) => d.fo.service[o.name].name),
      pros: [t((d) => d.fo.service[o.name].text_1), t((d) => d.fo.service[o.name].text_2)],
      isStandard: o.name === "farfetchd",
      isFloorLevelServiceAvailable: o.floor_level_service !== "not_available",
    };
  });

  return {
    isLoading: false,
    isFetching: availableServices.isFetching,
    options,
  };
};
