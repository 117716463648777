import { IconClose } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { ChatModal } from ".";
import { Animation } from "@ionic/core";
import { createAnimation, IonModal } from "@ionic/react";
import "./modal.scss";
import { SwipeContainer, SwipeDirection } from "../../components";

/**
 * See docs (v7): https://ionicframework.com/docs/ja/api/modal
 * Note: Routes that use this modal should be wrapped in <IonApp>
 * Note2: There handle features somehow breaks the modal, so this is implemented manually
 */
export interface MpModalProps {
  isOpen: boolean;
  closeHandler?(): void;
  theme?: "white" | "primary" | "head-primary-light";
  header?: React.ReactNode;
  headerImage?: React.ReactNode;
  footer?: React.ReactNode;
  hasHelpButton?: boolean;
  children: React.ReactNode;
}

export const Modal: React.FC<MpModalProps> = ({
  isOpen,
  closeHandler,
  theme = "white",
  header,
  headerImage,
  footer,
  hasHelpButton,
  children,
}) => {
  const themeCls = {
    "bg-white": theme === "white",
    "bg-blue-600 text-white": theme === "primary",
    "bg-blue-100 text-blue-600": theme === "head-primary-light",
  };
  const onSwipeHandler = (swipe: SwipeDirection[]): void => {
    if (swipe.includes("DOWN")) {
      closeHandler?.();
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.body.classList.add("sheet-modal-present");
      document.documentElement.classList.add("sheet-modal-present");
    } else {
      document.body.classList.remove("sheet-modal-present");
      document.documentElement.classList.remove("sheet-modal-present");
    }
  }, [isOpen]);

  return (
    <IonModal isOpen={isOpen} enterAnimation={enterAnimation} leaveAnimation={leaveAnimation}>
      <div
        className={cn(
          "sheet-backdrop",
          "fixed",
          "inset-0",
          "bg-black/50",
          "pt-8",
          "flex",
          "lg:py-[8vh]",
          "lg:justify-center"
        )}
        onClick={closeHandler}
      >
        <SwipeContainer
          onSwipe={onSwipeHandler}
          className={cn(
            "sheet-wrapper",
            themeCls,
            "rounded-t-lg",
            "w-full",
            "lg:w-[700px]",
            "self-end",
            "lg:rounded-lg",
            "lg:self-center",
            "max-h-full",
            "flex",
            "flex-col",
            "overflow-hidden"
          )}
          onClick={(e) => e.stopPropagation()}
          style={{ transformStyle: "preserve-3d" }}
        >
          <div
            className="absolute top-2 left-1/2 w-20 -ml-10 border-t-2 lg:hidden"
            style={{ borderColor: "#CDE4FF" }}
          />
          {(closeHandler || header) && (
            <div
              className={cn(
                "flex",
                "items-center",
                "p-6",
                "lg:px-8",
                { "text-blue-600": theme !== "primary" },
                "flex-none",
                "relative"
              )}
            >
              {closeHandler && <IconClose className={"mr-auto -ml-8 relative left-8"} onClick={closeHandler} />}
              {header && (
                <div className="grow text-center">
                  <b>{header}</b>
                </div>
              )}
              {hasHelpButton && (
                <div className="absolute right-4" style={{ top: "1.1rem" }}>
                  <ChatModal />
                </div>
              )}
            </div>
          )}
          <div
            className={cn("p-6", "lg:p-8", "overflow-auto", "grow", {
              "bg-white": theme === "head-primary-light",
              "br-text-default": theme === "head-primary-light",
            })}
          >
            {headerImage && (
              <div
                style={{ height: "156px" }}
                className={cn("flex flex-col-reverse -m-6 lg:-m-8 mb-4 lg:mb-6", themeCls)}
              >
                {headerImage}
              </div>
            )}
            <div className="pt-4">{children}</div>
          </div>
          {footer && <div className={cn("p-4", "lg:p-6", "flex-none")}>{footer}</div>}
        </SwipeContainer>
      </div>
    </IonModal>
  );
};

export const MpModalContentHead: React.FC<{ title: string; sub?: string }> = ({ title, sub }) => {
  return (
    <div className="mb-6">
      <h3>{title}</h3>
      {sub && (
        <div className={"pt-2"} style={{ fontSize: 20 }}>
          {sub}
        </div>
      )}
    </div>
  );
};

const enterAnimation = (baseEl: HTMLElement): Animation => {
  const backdropAnimation = createAnimation()
    .addElement(baseEl.querySelector(".sheet-backdrop") as HTMLElement)
    .keyframes([
      { offset: 0, opacity: "0" },
      { offset: 0.5, opacity: "1" },
      { offset: 1, opacity: "1" },
    ]);

  const wrapperAnimation = createAnimation()
    .addElement(baseEl.querySelector(".sheet-wrapper") as HTMLElement)
    .easing("ease-in")
    .keyframes([
      { offset: 0, opacity: "0", transform: "translate3d(0,70vh,0)" },
      { offset: 0.5, opacity: "1", transform: "translate3d(0,10vh,0)" },
      { offset: 0.8, opacity: "1", transform: "translate3d(0,-1vh,0)" },
      { offset: 1, opacity: "1", transform: "translate3d(0,0,0)" },
    ]);

  return createAnimation().addAnimation([backdropAnimation, wrapperAnimation]).duration(300);
};

const leaveAnimation = (baseEl: HTMLElement): Animation => {
  return enterAnimation(baseEl).direction("reverse");
};
