import { useMemo } from "react";
import cn from "classnames";
import GoogleMap, { fitBounds } from "google-map-react";

import { useTransportContext, useMapContext, useTranslationContext } from "../../../hooks";
import { IconGeo, IconTruckFilled } from "../../../components";
import { Config } from "../../../config";
import { styledMapType } from "../../../utils";

import { MapLocationMarker } from "./MapLocationMarker";

const googleMapOptions = {
  disableDefaultUI: true,
  styles: styledMapType,
};

/**
 * Make NL the default center.
 */
const DEFAULT_CENTER = { lat: 52.370216, lng: 4.895168 };
const DEFAULT_ZOOM = 8;

export const Map: React.FC = () => {
  const { t } = useTranslationContext();
  const { driverLocation, stopsBeforeStop } = useMapContext();
  const { pickup, delivery, contactType, status = 0 } = useTransportContext();

  // The delivery contact should NOT SEE see the pickup adress marker on the map
  const pickupAddress = contactType === "delivery" ? undefined : pickup?.address;
  // The pickup contact should NOT SEE see the delivery adress marker on the map
  const deliveryAddress = contactType === "pickup" ? undefined : delivery?.address;

  const pickupA = typeof pickupAddress !== "string" ? pickupAddress : undefined;
  const deliveryA = typeof deliveryAddress !== "string" ? deliveryAddress : undefined;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getMapSettings = () => {
    let center = DEFAULT_CENTER;
    let zoom = DEFAULT_ZOOM;

    const lats = [pickupA?.lat, deliveryA?.lat, driverLocation?.lat].filter(Boolean) as number[];
    const lngs = [pickupA?.lng, deliveryA?.lng, driverLocation?.lng].filter(Boolean) as number[];

    if (lats.length > 1 || lngs.length > 1) {
      const bounds = {
        ne: {
          lat: Math.max(...lats),
          lng: Math.max(...lngs),
        },
        sw: {
          lat: Math.min(...lats),
          lng: Math.min(...lngs),
        },
      };
      const size = { width: 220, height: 180 };
      const { center: newCenter, zoom: newZoom } = fitBounds(bounds, size);
      center = newCenter;
      zoom = newZoom;
    }

    /**
     * When there is only ONE coord to show
     */
    if (lats.length === 1 || lngs.length === 1) {
      center = { lat: lats[0], lng: lngs[0] };
    }

    /**
     * When driver location is not in yet and the pickup + delivery location are the same.
     */
    if (!driverLocation && lats.length === 2 && lngs.length === 2 && lats[0] === lats[1] && lngs[0] === lngs[1]) {
      center = { lat: lats[0], lng: lngs[0] };
    }

    return {
      center,
      zoom,
    };
  };

  // Creating a memoized version of `getMapSettings` with the following dependencies ensures
  // that map settings are only re-calculated before/after initial state is made available.
  // After that point, it is important to avoid re-calculating, else the map will re-center constantly.
  const memoizedMapSettings = useMemo(
    () => getMapSettings(),
    [Boolean(pickupAddress), Boolean(deliveryAddress), Boolean(driverLocation)]
  );

  /**
   * Derive direction that driver is going based on driver location "heading" val.
   */
  const heading = driverLocation?.heading === -1 ? 0 : driverLocation?.heading || 0;
  const flipped = heading > 180;

  return (
    <GoogleMap
      bootstrapURLKeys={{ key: Config.GOOGLE_MAPS_KEY as string }}
      options={googleMapOptions}
      margin={[50, 50, 50, 50]}
      center={memoizedMapSettings.center}
      zoom={memoizedMapSettings.zoom}
    >
      {/* NOTE: important to put stopsBeforeStop markers before the other markers. Else,
      these markers may overlay the more important pickup/delivery/driver location markers on the map. */}
      {stopsBeforeStop
        ?.filter((stop) => {
          // NOTE: Filter out any stops that have a confirmation timestamp. These are no longer
          // interesting for the customer to see, as they have been completed and only serve to clutter the UI.
          return stop.confirmed_by_driver_at === null;
        })
        .map((stop, idx) => {
          return (
            <MapLocationMarker key={idx} lat={stop.lat} lng={stop.lng}>
              <IconGeo
                style={{
                  // Stroke makes the geo markers "pop" a bit more on the map. Without it, they sort of blend in.
                  stroke: "white",
                }}
                className={cn(
                  // Make these markers a bit smaller than the pickup/delivery circle markers (see below)
                  "w-8",
                  "h-8",
                  "text-blue-600"
                )}
              />
            </MapLocationMarker>
          );
        })}
      {pickupA?.lat && pickupA?.lng && (
        <MapLocationMarker
          text={t((d) => d.pickup)}
          // With this status, we know that the stop has been confirmed.
          isConfirmed={status > 3}
          lat={pickupA.lat}
          lng={pickupA.lng}
        >
          <div className={cn("border-blue-600", "border-4", "rounded-full", "w-6", "h-6", "bg-white")} />
        </MapLocationMarker>
      )}
      {deliveryA?.lat && deliveryA?.lng && (
        <MapLocationMarker
          text={t((d) => d.delivery)}
          // With this status, we know that the stop has been confirmed.
          isConfirmed={status > 4}
          lat={deliveryA.lat}
          lng={deliveryA.lng}
        >
          <div className={cn("bg-blue-600", "border-4", "border-blue-600", "rounded-full", "w-6", "h-6")} />
        </MapLocationMarker>
      )}
      {driverLocation && (
        <MapLocationMarker lat={driverLocation.lat} lng={driverLocation.lng}>
          <span className={cn("flex", "h-10", "w-10", "justify-center")}>
            <span
              className={cn(
                "animate-ping",
                "absolute",
                "inline-flex",
                "h-full",
                "w-full",
                "rounded-full",
                "bg-blue-600",
                "opacity-75"
              )}
            />
            <span
              className={cn(
                "absolute",
                "inline-flex",
                "h-full",
                "w-full",
                "rounded-full",
                "bg-white",
                "items-center",
                "justify-center",
                "border-double",
                "border-blue-600",
                "border-4"
              )}
            >
              <IconTruckFilled
                className={cn("h-6", "w-6", "text-blue-600")}
                style={flipped ? { transform: "scale(-1, 1)" } : undefined}
              />
            </span>
          </span>
        </MapLocationMarker>
      )}
    </GoogleMap>
  );
};
