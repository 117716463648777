import { Button } from "@brenger/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslationContext } from "../../../hooks";
import { DotList } from "../../components/DotList";
import { MpContainer, ThankYouLayout } from "../../components/Layout";
import { GenerateMpRoute } from "../../routes";

export const ScheduleBuyerExplain: React.FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslationContext();
  const history = useHistory();
  return (
    <ThankYouLayout title={t((d) => d.fo.explain_schedule_buyer.title)}>
      <MpContainer>
        <DotList
          items={[
            t((d) => d.fo.explain_schedule_buyer.step_1),
            t((d) => d.fo.explain_schedule_buyer.step_2),
            t((d) => d.fo.explain_schedule_buyer.step_3),
          ]}
        />
      </MpContainer>
      <MpContainer>
        <Button
          className="w-full mb-4"
          buttonType="secondary"
          onClick={() =>
            history.push(GenerateMpRoute({ id, page: { state: "SCHEDULE_BUYER", type: "flows", progress: 1 } }))
          }
        >
          {t((d) => d.fo.explain_schedule_seller.button)}
        </Button>
      </MpContainer>
    </ThankYouLayout>
  );
};
