import * as React from "react";

export const BrengerLogo: React.FC<React.SVGAttributes<SVGElement>> = ({ width, height, fill, ...props }) => (
  <svg
    width={String(width || 59)}
    height={String(height || 16)}
    viewBox="0 0 84 23"
    fill={fill || "currentColor"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M83.9677 5.60903C84.0476 5.45812 83.9732 5.27166 83.8114 5.21705L83.1297 4.98728L83.1277 4.98987C82.4877 4.79889 81.8415 4.71777 81.2572 4.71777C78.9683 4.71777 75.696 5.84784 75.696 10.4612V17.471C75.696 17.6268 75.8226 17.7535 75.9792 17.7535H78.4392C78.5955 17.7535 78.7227 17.6268 78.7227 17.4707V10.8697C78.7227 8.69483 79.5102 7.5486 81.2572 7.5486C81.6241 7.5486 81.9435 7.60548 82.2246 7.70824C82.225 7.70824 82.225 7.70856 82.225 7.70856L82.5408 7.81068C82.6689 7.85237 82.8084 7.79614 82.8715 7.67689L83.9677 5.60903Z" />
    <path d="M65.3103 9.92314C65.4481 8.16033 66.6065 7.25195 68.2052 7.25195C69.9978 7.25195 70.7698 8.573 70.7698 9.92314H65.3103ZM68.2052 4.71777C64.4551 4.71777 62.249 7.08617 62.249 11.3004C62.249 15.8986 64.6204 17.9645 68.4537 17.9645C70.6427 17.9645 72.0343 17.1469 72.665 16.646C72.797 16.541 72.8102 16.3526 72.7012 16.224L71.3523 14.638C71.2597 14.5287 71.0873 14.5048 70.9653 14.5791C70.3353 14.9637 69.5374 15.293 68.4537 15.293C66.6337 15.293 65.2275 14.3846 65.2275 12.4014H72.0382C73.7204 12.4014 73.7204 11.217 73.7204 10.1985C73.7204 8.29864 72.8659 4.71777 68.2052 4.71777Z" />
    <path d="M57.5079 14.17C57.5079 14.17 56.4776 15.515 54.8935 15.515C53.098 15.515 52.0681 13.6582 52.0681 11.0474C52.0681 8.46281 53.0718 7.14369 54.8935 7.14369C56.4249 7.14369 57.5079 8.17229 57.5079 9.99262V14.17ZM54.8935 4.71777C51.3287 4.71777 49.2424 6.74847 49.2424 11.0474C49.2424 15.346 51.2757 17.9416 54.6026 17.9416C55.8703 17.9416 57.1138 17.2781 57.6418 16.7507V17.7535C57.6418 19.3612 56.5307 20.4731 54.656 20.4731C53.6701 20.4731 52.7683 20.0169 52.2755 19.7121H52.2752L52.091 19.6026C51.9577 19.5228 51.785 19.5712 51.7122 19.7082L50.7392 21.5434C50.6716 21.6711 50.7159 21.8291 50.8401 21.9031L51.2867 22.1694L51.2944 22.161C52.1706 22.6305 53.2996 22.8991 54.656 22.8991C58.5643 22.8991 60.3595 20.8422 60.3595 17.2032V9.99262C60.3595 6.48478 58.0887 4.71777 54.8935 4.71777Z" />
    <path d="M41.5357 4.71777C39.2471 4.71777 35.9745 5.84784 35.9745 10.4609V17.4707C35.9745 17.6268 36.1014 17.7535 36.2577 17.7535H38.7181C38.8744 17.7535 39.0012 17.6268 39.0012 17.4707V17.2432V10.87C39.0012 8.69548 39.7891 7.5486 41.5357 7.5486C43.2829 7.5486 44.0705 8.69548 44.0705 10.87L44.0682 16.5181V17.4707C44.0682 17.6268 44.1954 17.7535 44.3514 17.7535H46.8137C46.9703 17.7535 47.0968 17.6268 47.0968 17.4707V10.4609C47.0968 5.84784 43.8246 4.71777 41.5357 4.71777Z" />
    <path d="M25.6086 9.92314C25.7467 8.16033 26.9048 7.25195 28.5038 7.25195C30.2964 7.25195 31.0684 8.573 31.0684 9.92314H25.6086ZM28.5038 4.71777C24.7534 4.71777 22.5476 7.08617 22.5476 11.3004C22.5476 15.8986 24.919 17.9645 28.7523 17.9645C30.9413 17.9645 32.3329 17.1469 32.9635 16.646C33.0956 16.541 33.1088 16.3526 32.9998 16.224L31.6508 14.638C31.5583 14.5287 31.3855 14.5048 31.2639 14.5791C30.6339 14.9637 29.836 15.293 28.7523 15.293C26.9323 15.293 25.526 14.3846 25.526 12.4014H32.3368C34.0187 12.4014 34.0187 11.217 34.0187 10.1985C34.0187 8.29864 33.1645 4.71777 28.5038 4.71777Z" />
    <path d="M22.3946 5.60903C22.4745 5.45812 22.4004 5.27166 22.2383 5.21705L21.5565 4.98728L21.5546 4.98987C20.9146 4.79889 20.2684 4.71777 19.6841 4.71777C17.3952 4.71777 14.1229 5.84784 14.1229 10.4612V17.471C14.1229 17.6268 14.2494 17.7535 14.406 17.7535H16.8664C17.0227 17.7535 17.1496 17.6268 17.1496 17.4707V10.8697C17.1496 8.69483 17.9371 7.5486 19.6841 7.5486C20.051 7.5486 20.3704 7.60548 20.6519 7.70824V7.70856L20.9677 7.81068C21.0958 7.85237 21.2356 7.79614 21.2983 7.67689L22.3946 5.60903Z" />
    <path d="M11.3389 12.5012C11.5088 12.5012 11.647 12.6472 11.6279 12.8163C11.294 15.7098 8.82807 17.9644 5.84316 17.9644C2.85794 17.9644 0.39171 15.7098 0.0584362 12.8163C0.0386986 12.6472 0.176862 12.5012 0.347058 12.5012H2.62173C2.75634 12.5012 2.87767 12.5926 2.90356 12.7245C3.17406 14.1001 4.38808 15.1417 5.84316 15.1417C7.29857 15.1417 8.51259 14.1001 8.78309 12.7245C8.80898 12.5926 8.93064 12.5012 9.06524 12.5012H11.3389ZM6.12965 4.71773C10.2131 4.71773 11.5458 7.8339 11.6604 9.85426C11.6691 10.0129 11.5384 10.1399 11.3795 10.1399H9.11229C8.96442 10.1399 8.84146 10.032 8.82593 9.88528C8.75022 9.16142 8.31696 7.14494 5.84329 7.14494C3.34115 7.14494 2.92666 9.20084 2.85807 9.90629C2.84545 10.0378 2.73738 10.1399 2.60471 10.1399H0.257559C0.11519 10.1399 0 10.0249 0 9.88237V8.7801V0.601713C0 0.269834 0.269208 0.000646313 0.601187 0.000646313L2.43484 0C2.76682 0 3.03603 0.268864 3.03603 0.60042H3.03635V5.04411V5.84069C3.59903 5.27872 4.77973 4.71773 6.12965 4.71773Z" />
  </svg>
);
