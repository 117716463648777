import React from "react";
import { Locale } from "@brenger/api-client";
import { Select } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import { useMutation } from "react-query";

import { useTranslationContext, useTransportContext, useFreshChat } from "../hooks";
import { coreClient, localeNormalizer, SUPPORTED_LOCALE_MAP } from "../utils";

export const LanguageSelect: React.FC = () => {
  const freshChat = useFreshChat();
  const { i18n } = useTranslationContext();
  // To ensure that the updated preferred locale impacts all downstream entities (eg: embedded TR customer)
  // it's easiest to simply refresh the entire page upon update success.
  const onUpdateSuccess = (): void => {
    window.location.reload();
  };
  const updateCustomer = useMutation(coreClient.customers.update, { onSuccess: onUpdateSuccess });
  const updateContact = useMutation(coreClient.contacts.update, { onSuccess: onUpdateSuccess });

  // IMPORTANT NOTE: useTransportContext is only available on some pages.
  const ctx = useTransportContext();

  const onLocaleChange = (locale: Locale): void => {
    // Update language in React context
    i18n.changeLocale?.(locale);
    // Update fresh chat language (docs show langauge instead of full locale, hence use 'parseLanguage')
    freshChat?.user.setLocale(localeNormalizer.parseLanguage(locale));

    // Update entity (if avaialble)
    const { contactType, tr, pickup, delivery } = ctx;

    // If there's no contact type, we're either loading or in a context where there is no TR context.
    if (!contactType) return;

    const args = { id: "", preferred_locale: locale };

    if (contactType === "customer") {
      args.id = getIdFromIri(tr?.customer) || "";
      updateCustomer.mutate(args);
    }

    if (contactType === "pickup") {
      args.id = getIdFromIri(pickup?.contact) || "";
      updateContact.mutate(args);
    }

    if (contactType === "delivery") {
      args.id = getIdFromIri(delivery?.contact) || "";
      updateContact.mutate(args);
    }
  };

  return (
    <Select
      value={i18n.locale}
      onChange={(nextLocale) => {
        // NOTE: Can safely type cast this to Locale, as we are directly getting these values
        // from the typed SUPPORTED_LOCALE_MAP above.
        const locale = nextLocale as Locale;
        onLocaleChange(locale);
      }}
    >
      {[...SUPPORTED_LOCALE_MAP.entries()].map(([locale, localePretty]) => {
        return (
          <option key={locale} value={locale}>
            {localePretty}
          </option>
        );
      })}
    </Select>
  );
};
