import { Button, Message } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useMutation } from "react-query";

import { Modal, ModalProps } from "../../../components";
import { useTranslationContext, useTransportContext } from "../../../hooks";
import { coreClient } from "../../../utils";
import { CoreViolation } from "@brenger/api-client";

export const CancelModalRegular: React.FC<ModalProps> = (props) => {
  const { t } = useTranslationContext();
  const { trId, refresh } = useTransportContext();

  const onCancelSuccess = (): void => {
    refresh();
    props.closeHandler();
  };

  const cancel = useMutation(coreClient.transportRequests.cancel, { onSuccess: onCancelSuccess });
  const cancelErr = (cancel.error as undefined | CoreViolation)?.message;
  return (
    <Modal isActive={props.isActive} closeHandler={props.closeHandler}>
      <h3 className={cn("mb-4")}>{t((d) => d.cancel_tr_confirm_title)}</h3>
      <div>{t((d) => d.cancel_tr_confirm_desc)}</div>
      {cancelErr && (
        <Message type="error" className={cn("mt-2")}>
          {cancelErr}
        </Message>
      )}
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4", "mt-8")}>
        <Button disabled={cancel.isLoading} buttonType="primary-outline" onClick={props.closeHandler}>
          {t((d) => d.no)}
        </Button>
        <Button loading={cancel.isLoading} buttonType="warning" onClick={() => cancel.mutate({ id: trId || "" })}>
          {t((d) => d.agree)}
        </Button>
      </div>
    </Modal>
  );
};
