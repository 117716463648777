import React from "react";
import ReactDOM from "react-dom";
import cn from "classnames";

interface Props {
  children: React.ReactNode;
  isActive: boolean;
}

export const Dialog: React.FC<Props> = ({ isActive, children }) => {
  React.useEffect(() => {
    // When the Dialog is active, it is optimal UX to freeze + hide all overflow on the body
    const body = document.querySelector("body") as HTMLBodyElement;
    if (isActive) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }

    // When dialog unmounts, release overflow on the body again.
    return (): void => {
      body.classList.remove("overflow-hidden");
    };
  }, [isActive]);

  if (isActive) {
    const dialogRoot = document.querySelector("#dialog-root") as HTMLDivElement;

    return ReactDOM.createPortal(
      <div
        id="dialog-container"
        role="dialog"
        aria-modal="true"
        className={cn(
          "fixed",
          "inset-0",
          // NOTE about zIndex: this zIndex should be the highest in the app.
          // A dialog (which is used for Modals) should be above notifications, etc.
          "z-50"
        )}
      >
        {children}
      </div>,
      dialogRoot
    );
  }

  return null;
};
