import { InstructionSuspicion } from "@brenger/api-client";
import { hasFloorInstruction, hasPhoneInstruction, hasTimeInstruction, TextScanLangs } from "@brenger/utils";

export const getSuspicions = (text: string | undefined, activeLang: TextScanLangs[]): InstructionSuspicion[] => {
  if (!text) {
    return [];
  }
  /**
   * List of suspicions
   */
  const suspicions: InstructionSuspicion[] = [];
  if (hasFloorInstruction(text, activeLang)) {
    suspicions.push("floor_level_suspected");
  }
  if (hasTimeInstruction(text, activeLang)) {
    suspicions.push("time_request_suspected");
  }
  if (hasPhoneInstruction(text, activeLang)) {
    suspicions.push("phone_number_suspected");
  }
  return suspicions;
};
