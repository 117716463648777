import React from "react";
import cn from "classnames";
import { Button } from "@brenger/react";

import { logger } from "../utils";

interface State {
  hasError: boolean;
  errorMsg: string;
  eventId: string;
}

interface Props extends React.ComponentClass {
  children: React.ReactNode;
  title?: string;
  cta?: string;
  onClick?: () => void;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorMsg: "", eventId: "" };
  }

  static defaultProps = {
    title: "Er is iets fout gegaan.",
    cta: "Herlaad Pagina",
    onClick: () => {
      window.sessionStorage.clear();
      window.localStorage.clear();
      window.location.reload();
    },
  };

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMsg: error.message, eventId: "" };
  }

  componentDidCatch(error: unknown): void {
    let eventId = undefined;

    if (error instanceof Error) {
      // NOTE: provide additional context so we can track the exact source of the exception in Sentry.
      const context = { app_context: { source: "ErrorBoundary" } };
      eventId = logger.exception(error, context);
    } else {
      eventId = logger.message("ErrorBoundary");
    }

    if (eventId) {
      this.setState({ eventId });
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className={cn("bg-blue-600", "min-h-screen", "p-6", "flex", "items-center")}>
          <div
            className={cn(
              "flex",
              "flex-col",
              "items-center",
              "container",
              "mx-auto",
              "text-white",
              "text-center",
              "mt-8"
            )}
          >
            <h1>{this.props.title}</h1>
            <div className={cn("h-6")} />
            <b>{this.state.eventId}</b>
            <div className={cn("h-6")} />
            <div className={cn("p-4", "rounded", "border")}>{this.state.errorMsg}</div>
            <div className={cn("h-6")} />
            <Button buttonType="secondary" onClick={this.props.onClick}>
              {this.props.cta}
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
