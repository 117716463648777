import React from "react";

export type SwipeDirection = "UP" | "RIGHT" | "DOWN" | "LEFT";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onSwipe(dir: SwipeDirection[]): void;
  minDistance?: number;
  children: React.ReactNode;
}

export const SwipeContainer: React.FC<Props> = ({ onSwipe, minDistance = 20, children, ...rest }) => {
  const touchStart = React.useRef<React.Touch | null>(null);
  const touchEnd = React.useRef<React.Touch | null>(null);

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
    touchEnd.current = null; // otherwise the swipe is fired even with usual touch events
    touchStart.current = e.targetTouches[0];
  };

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>): void => {
    touchEnd.current = e.targetTouches[0];
  };

  const onTouchEnd = (): void => {
    // Bail early
    if (!touchStart.current || !touchEnd.current) return;

    const distanceX = touchStart.current.clientX - touchEnd.current.clientX;
    const distanceY = touchStart.current.clientY - touchEnd.current.clientY;
    // Determine directions
    const directions: SwipeDirection[] = [];
    // x-axis - left right
    if (distanceX > minDistance) directions.push("LEFT");
    if (distanceX < -minDistance) directions.push("RIGHT");
    // y-axis - up down
    if (distanceY > minDistance) directions.push("UP");
    if (distanceY < -minDistance) directions.push("DOWN");
    // if no directions, then don't trigger
    if (!directions.length) return;
    // trigger onSwipe
    onSwipe(directions);
  };

  return (
    <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} {...rest}>
      {children}
    </div>
  );
};
