import React from "react";
import cn from "classnames";

import { Status, ContactType, getColorClassnamesForStatus } from "../utils";

import { IconTruck, IconCheckCircle, IconCalendar, IconHouse, IconExclamationCircle, IconXCircle } from ".";

interface Props extends React.SVGAttributes<SVGElement> {
  status: Status;
  contactType: ContactType;
}

type Chart = {
  [contactType in ContactType]: {
    [status in Status]: React.FC<React.SVGAttributes<SVGElement>>;
  };
};

export const StatusIcon: React.FC<Props> = ({ status, contactType, className, ...rest }) => {
  const chart: Chart = {
    pickup: {
      0: IconXCircle,
      1: IconCheckCircle,
      2: IconCalendar,
      3: IconTruck,
      4: IconHouse,
      5: IconHouse,
      6: IconExclamationCircle,
    },
    delivery: {
      0: IconXCircle,
      1: IconCheckCircle,
      2: IconCalendar,
      3: IconCalendar,
      4: IconTruck,
      5: IconHouse,
      6: IconExclamationCircle,
    },
    customer: {
      0: IconXCircle,
      1: IconCheckCircle,
      2: IconCalendar,
      3: IconTruck,
      4: IconTruck,
      5: IconHouse,
      6: IconExclamationCircle,
    },
  };

  const Icon = chart[contactType][status];

  return <Icon className={cn(getColorClassnamesForStatus(status), className)} {...rest} />;
};
