import React from "react";
import cn from "classnames";
import { Pill as PillRaw } from "@brenger/react";
import { StopDetails, ItemSetServices, FoServiceLevel } from "@brenger/api-client";

import { useTranslationContext } from "../hooks";

interface Props {
  details: StopDetails;
  services?: ItemSetServices[];
  isBusiness?: boolean;
  serviceLevel?: FoServiceLevel;
}

const Pill: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <PillRaw type="gray-outline" className={cn("m-1")}>
      {children}
    </PillRaw>
  );
};

export const StopPills: React.FC<Props> = ({ details, services, isBusiness, serviceLevel }) => {
  const { t } = useTranslationContext();
  const showFloorLevel = typeof serviceLevel === "undefined" || serviceLevel !== "farfetchd";
  return (
    <div className={cn("flex", "flex-wrap", "w-full", "-mx-1", "mt-1")}>
      {details.situation === "home" && <Pill>{t((d) => d.situation.home)}</Pill>}
      {details.situation === "auction" && <Pill>{t((d) => d.situation.auction)}</Pill>}
      {details.situation === "store" && <Pill>{t((d) => d.situation.store)}</Pill>}
      {details.floor_level === 0 && showFloorLevel && <Pill>{t((d) => d.ground_floor)}</Pill>}
      {(details.floor_level || 0) > 0 && showFloorLevel && (
        <Pill>{t((d, template) => template(d.floor_level, { floor_level: details.floor_level }))}</Pill>
      )}
      {details.elevator && showFloorLevel && <Pill>{t((d) => d.elevator)}</Pill>}
      {/* NOTE: Only communicate carrying_help when not source_flow business */}
      {!isBusiness && (
        <>
          {details.carrying_help === "needed" && <Pill>{t((d) => d.carrying_help.needed)}</Pill>}
          {details.carrying_help === "extra_driver" && <Pill>{t((d) => d.carrying_help.extra_driver)}</Pill>}
          {details.carrying_help === "equipment_tailgate" && (
            <Pill>{t((d) => d.carrying_help.equipment_tailgate)}</Pill>
          )}
          {details.carrying_help === "equipment_tailgate_pallet_jack" && (
            <Pill>{t((d) => d.carrying_help.equipment_tailgate_pallet_jack)}</Pill>
          )}
          {details.carrying_help === "equipment_provided_by_customer" && (
            <Pill>{t((d) => d.carrying_help.equipment_provided_by_customer)}</Pill>
          )}
          {/* tailgate and extra jack */}
          {details.carrying_help === "equipment_tailgate_extra_driver" && (
            <Pill>{t((d) => d.carrying_help.equipment_tailgate_extra_driver)}</Pill>
          )}
        </>
      )}
      {services?.includes("assembly") && <Pill>{t((d) => d.top_service)}</Pill>}
    </div>
  );
};
