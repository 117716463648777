import { Spacer } from "@brenger/react";
import cn from "classnames";
import React from "react";

import { PageContainer, StatusIcon, StatusMessage } from "../../components";
import { useTransportContext } from "../../hooks";
import { createAccessControl, getColorClassnamesForStatus } from "../../utils";

const rowCns = ["flex", "items-center", "py-2"];
const baseIconCns = ["w-6", "h-6", "mr-6"];

const getPerms = createAccessControl({
  pickup: {
    canViewSection: [1, 2, 3],
  },
  delivery: {
    canViewSection: [1, 2, 3, 4],
  },
  customer: {
    canViewSection: [1, 2, 3, 4],
  },
});

export const StatusOverview: React.FC = () => {
  const { contactType, status } = useTransportContext();
  if (status === undefined) return null;
  if (contactType === undefined) return null;

  const { perms } = getPerms(contactType, status);

  if (!perms?.canViewSection) return null;

  return (
    <PageContainer
      className={cn("py-6", "border-t-4", "border-b-4")}
      // @TODO add border color to tailwind config
      style={{ borderColor: "#D1E4FD" }}
    >
      <h6>Status</h6>
      <Spacer h={4} />
      <div className={cn("md:grid", "md:grid-cols-4", "md:gap-x-4")}>
        <div className={cn(rowCns, { [getColorClassnamesForStatus(status)]: status >= 1 })}>
          <StatusIcon status={1} contactType={contactType} className={cn(baseIconCns)} style={{ color: "inherit" }} />
          <div>
            <StatusMessage status={1} contactType={contactType} />
          </div>
        </div>
        <div className={cn(rowCns, { [getColorClassnamesForStatus(status)]: status >= 2 })}>
          <StatusIcon status={2} contactType={contactType} className={cn(baseIconCns)} style={{ color: "inherit" }} />
          <div>
            <StatusMessage status={2} contactType={contactType} />
          </div>
        </div>
        {/* NOTE: the first two steps in the status over view are always the same no matter the contact type. */}
        {/* Specifically: pickup contacts are informed when the driver is on the way to pickup the item from them. */}
        {contactType === "pickup" && (
          <>
            <div className={cn(rowCns, { [getColorClassnamesForStatus(status)]: status >= 3 })}>
              <StatusIcon status={3} contactType="pickup" className={cn(baseIconCns)} style={{ color: "inherit" }} />
              <div>
                <StatusMessage status={3} contactType="pickup" />
              </div>
            </div>
            <div className={cn(rowCns, { [getColorClassnamesForStatus(status)]: status >= 4 })}>
              <StatusIcon status={4} contactType="pickup" className={cn(baseIconCns)} style={{ color: "inherit" }} />
              <div>
                <StatusMessage status={4} contactType="pickup" />
              </div>
            </div>
          </>
        )}
        {/* Delivery contact and customer are only interested when the item has been picked up and delivered */}
        {(contactType === "delivery" || contactType === "customer") && (
          <>
            <div className={cn(rowCns, { [getColorClassnamesForStatus(status)]: status >= 4 })}>
              <StatusIcon status={4} contactType="delivery" style={{ color: "inherit" }} className={cn(baseIconCns)} />
              <div>
                <StatusMessage status={4} contactType="delivery" />
              </div>
            </div>
            <div className={cn(rowCns, { [getColorClassnamesForStatus(status)]: status >= 5 })}>
              <StatusIcon status={5} contactType="delivery" className={cn(baseIconCns)} style={{ color: "inherit" }} />
              <div>
                <StatusMessage status={5} contactType="delivery" />
              </div>
            </div>
          </>
        )}
      </div>
    </PageContainer>
  );
};
