import { createContext, useContext } from "react";
import { useQuery } from "react-query";
import { DriverLocation, LiveTrackingStatusStop } from "@brenger/api-client";

import { CacheKey, coreClient, createAccessControl, logger } from "../utils";

import { useTransportContext } from ".";

const getPerms = createAccessControl({
  pickup: {
    canViewDriverLocation: [3],
    canViewStopsBeforePickup: [3],
    canViewStopsBeforeDelivery: undefined,
  },
  delivery: {
    canViewDriverLocation: [4],
    canViewStopsBeforePickup: undefined,
    canViewStopsBeforeDelivery: [4],
  },
  customer: {
    canViewDriverLocation: [3, 4],
    canViewStopsBeforePickup: [3],
    canViewStopsBeforeDelivery: [4],
  },
});

type StopsBeforeStop = LiveTrackingStatusStop[] | undefined;

interface Context {
  stopsBeforeDelivery: StopsBeforeStop;
  stopsBeforePickup: StopsBeforeStop;
  // NOTE: stopsBeforeStop can display the stopsBeforePickup or stopsBeforeDelivery,
  // depending on the customer type, the TJ status and user permissions.
  stopsBeforeStop: StopsBeforeStop;
  driverLocation: DriverLocation | null;
}

const initialState = {
  stopsBeforePickup: [],
  stopsBeforeDelivery: [],
  stopsBeforeStop: [],
  driverLocation: null,
};

/**
 * Grab updated driver location every 20s by default.
 */
const DEFAULT_DELAY = 20000;

const MapContext = createContext<Context>(initialState);

/**
 * The MapProvider is distinct from the TransportProvider because it handled the polling internally.
 * By nesting this deeper in the tree, we are able to avoid re-rendering the entire tree as polling takes places.
 */
export const MapProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Provide sane default values to 'contactType' and 'status'
  const { contactType, status, trId, liveTracking } = useTransportContext();
  // Has the driver clicked the "start driving" button the in the driver app.
  // If so, it signals that the driver has confirmed that the other stop info is accurate and we can rely on ...
  // ... it when showing it to customers + calculating accurate ETAs.
  const isOtherStopInfoAvailable = liveTracking?.other_stop_info_available;

  const isDriverLocationEnabled = liveTracking?.in_live_tracking_window && liveTracking?.is_eligible;

  const { perms } = getPerms(contactType, status);

  let stopsBeforePickup: StopsBeforeStop = undefined;
  let stopsBeforeDelivery: StopsBeforeStop = undefined;
  let stopsBeforeStop: StopsBeforeStop = undefined;

  const isPickupInProgress = status === 3;
  const isDeliveryInProgress = status === 4;

  if (isOtherStopInfoAvailable && perms?.canViewStopsBeforePickup) {
    const stops = liveTracking?.stops_before_pickup;
    stopsBeforePickup = stops;
    if (isPickupInProgress) stopsBeforeStop = stops;
  }

  if (isOtherStopInfoAvailable && perms?.canViewStopsBeforeDelivery) {
    const stops = liveTracking?.stops_before_pickup;
    stopsBeforeDelivery = stops;
    if (isDeliveryInProgress) stopsBeforeStop = stops;
  }

  const driverLocation = useQuery(
    [CacheKey.RETRIEVE_DRIVER_LOCATION, trId],
    () => coreClient.driverLocations.retrieveByTransportRequest({ id: trId || "" }),
    {
      // Discontinue polling driver location when (`wait: true` will disable polling):
      // a) not enabled
      // b) no trId provided
      enabled: !!isDriverLocationEnabled && !!trId && !!perms?.canViewDriverLocation,
      refetchInterval: DEFAULT_DELAY,
    }
  );

  // Even though driver location may technically still be accessible from the API, we stop
  // communicating lat/lngs when the access control no longer exists for the contactType.
  const driverLocationData = perms?.canViewDriverLocation ? driverLocation.data : null;

  const context = {
    stopsBeforePickup,
    stopsBeforeDelivery,
    stopsBeforeStop,
    driverLocation: driverLocationData || null,
  };

  logger.dev("useMapContext", context);
  logger.setContext("Map Context", context);

  return <MapContext.Provider value={context}>{children}</MapContext.Provider>;
};

export const useMapContext = (): Context => {
  return useContext(MapContext);
};
