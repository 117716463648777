import { DateTimePeriod as IDateTimePeriod, GeoDateTimePeriod } from "@brenger/api-client";
import React from "react";

import { useFormatDate } from "../hooks";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  dateClassName?: string;
  timePeriodClassName?: string;
  dtp: IDateTimePeriod | GeoDateTimePeriod | undefined | null;
}

export const TimePeriod: React.FC<Props> = ({ dtp, ...rest }) => {
  const formatHourMinute = useFormatDate("hour-minute");

  if (!dtp) return null;

  // Some DTP periods (eg: from geo) have nullable start or end. Protect against those.
  if (!dtp.start || !dtp.end) return null;

  return (
    <div {...rest}>
      {formatHourMinute(dtp.start)} – {formatHourMinute(dtp.end)}
    </div>
  );
};

export const DateTimePeriod: React.FC<Props> = ({ dtp, dateClassName, timePeriodClassName, ...rest }) => {
  const formatDateShort = useFormatDate("date-short");

  if (!dtp) return null;

  // Some DTP periods (eg: from geo) have nullable start or end. Protect against those.
  if (!dtp.start || !dtp.end) return null;

  return (
    <span {...rest}>
      <div className={dateClassName}>{formatDateShort(dtp.start)}</div>
      <TimePeriod className={timePeriodClassName} dtp={dtp} />
    </span>
  );
};
