import { IconArrowLeft, IconArrowRight, IconCheckCircleFilled, IconLoader } from "@brenger/react";
import { parseApiDate } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { IconLeaf } from "../../components";
import { useFormatDate, useTranslationContext } from "../../hooks";
import { trackAndTag } from "../../utils/eventTracking";
import { MpFooterCta, PageHead, TimeDisplay } from "../components";
import { MpCard, MpContainer, MpFooter } from "../components/Layout";
import { useBuyerDates } from "../hooks/useBuyerDates";
import { useDraftTr } from "../hooks/useDraftTr";
import { useScrollToError } from "../hooks/useScrollToError";
import { MpRouteComponentProps } from "../routes";

const MIN_DAYS = 3;

export const SharedBuyerDatesPage: React.FC<MpRouteComponentProps> = (props) => {
  const { t } = useTranslationContext();
  const formatDateWML = useFormatDate("weekday-month-long");
  const formatDateDMS = useFormatDate("day-month-short");
  const formatDateWS = useFormatDate("weekday-short");
  const partyId = props.match.params.id;
  const buyerDates = useBuyerDates({ partyId });
  const datesContainer = React.useRef<HTMLDivElement>(null);
  const { errorsVisible, errorVisibleAndScrollTo, errorAttr } = useScrollToError();
  const [hideScrollBtn, setHideScrollBtn] = React.useState<"LEFT" | "RIGHT" | null>("LEFT");

  const selectedLength = buyerDates.selected.length;
  const formattedSelectedDates: (string | null)[] = Array.from({ length: selectedLength > 3 ? selectedLength : 3 }).map(
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    (_, i) => {
      const selectedDate = buyerDates.selected[i];
      return selectedDate ? formatDateWML(parseApiDate(selectedDate)) : null;
    }
  );
  const draftTr = useDraftTr({ partyId });

  const isReScheduleBuyer = draftTr.data?.state === "RESCHEDULE_BUYER";

  const optionWidth = 160 * 4;

  const c = datesContainer.current;

  const triggerScroll = (dir: "left" | "right"): void => {
    if (!c) return;
    const left = dir === "right" ? c.scrollLeft + optionWidth : c.scrollLeft - optionWidth;
    c.scrollTo({ left, behavior: "smooth" });
    checkBtnVisibility(left);
  };

  const checkBtnVisibility = (left?: number): void => {
    if (!c) return;
    const width = c.scrollWidth - optionWidth;
    const hideRight = width < (left || c.scrollLeft);
    const hideLeft = (left || c.scrollLeft) <= 0;
    if (hideRight) setHideScrollBtn("RIGHT");
    if (hideLeft) setHideScrollBtn("LEFT");
    if (!hideLeft && !hideRight) setHideScrollBtn(null);
  };

  React.useEffect(checkBtnVisibility, [buyerDates.options.length, c]);

  // When "more dates" come in, we would like scroll to the new ones
  const listenToDateChange = React.useRef(false);
  const getMoreDates = (): void => {
    listenToDateChange.current = true;
    buyerDates.getMore();
  };
  // Listen for date length change and trigger scroll
  React.useEffect(() => {
    if (!listenToDateChange.current) return;
    triggerScroll("right");
    listenToDateChange.current = false;
    trackAndTag({
      event: "FO Interaction",
      type: isReScheduleBuyer ? "More dates - reschedule" : "More dates",
      data: buyerDates.options.length,
      partyId,
    });
  }, [buyerDates.options.length]);

  const btnWrapCls = "hidden absolute top-0 bottom-0 p-2 bg-white items-center";
  const isLoading = buyerDates.isFetching || buyerDates.isSubmitting;
  const isError = selectedLength < MIN_DAYS;
  const isDisabled = isLoading || isError;
  const dateBtnCls = "w-[159px] h-28 flex flex-col items-center justify-center !p-4";
  return (
    <>
      <PageHead
        title={t((d) => d.fo.schedule_buyer.dates.title)}
        sub={t((d, template) => template(d.fo.schedule_buyer.dates.sub_title_dynamic, { min_days: MIN_DAYS }))}
      />
      <MpContainer fullWithBelowBreak="md">
        <div className="relative h-32 overflow-hidden">
          {buyerDates.isLoading && (
            <div className="h-32 flex items-center">
              <IconLoader />
            </div>
          )}
          {!buyerDates.isLoading && (
            <>
              <div
                className="absolute top-2 right-0 left-1/2 flex gap-2 items-center overflow-x-auto md:overflow-hidden ml-[-50%] pl-6 md:pl-0 md:left-0 md:right-0 md:ml-0"
                ref={datesContainer}
              >
                {buyerDates.options.map(({ date, kind }) => {
                  const pd = parseApiDate(date);
                  return (
                    <button key={date} onClick={() => buyerDates.toggleDate(date)}>
                      <MpCard
                        style={{ fontSize: "20px" }}
                        className={cn(dateBtnCls, {
                          "!border-blue-600 bg-blue-100 text-blue-600": buyerDates.selected.includes(date),
                        })}
                      >
                        <div className="flex items-center justify-between gap-2">
                          {kind === "ECO_FRIENDLY" && (
                            <IconLeaf className="text-green-400" style={{ fontSize: "15px" }} />
                          )}
                          <b className="first-letter:uppercase">
                            {formatDateWS(pd)} {formatDateDMS(pd)}.
                          </b>
                        </div>
                        <div>08:00 - 18:00</div>
                      </MpCard>
                    </button>
                  );
                })}
                <button type="button" onClick={getMoreDates}>
                  {!buyerDates.isLoading && (
                    <MpCard className={cn(dateBtnCls, "!border-blue-600", "mr-4")}>
                      <small className="text-blue-600">
                        <b>{t((d) => d.fo.schedule_buyer.dates.show_more)}</b>
                      </small>
                    </MpCard>
                  )}
                </button>
              </div>
              <div className={cn(btnWrapCls, "left-0", { "md:flex": hideScrollBtn !== "LEFT" })}>
                <button
                  type="button"
                  onClick={() => triggerScroll("left")}
                  className={cn("rounded-full !bg-blue-600 text-white p-3")}
                >
                  <IconArrowLeft />
                </button>
              </div>
              <div className={cn(btnWrapCls, "right-0", { "md:flex": hideScrollBtn !== "RIGHT" })}>
                <button
                  type="button"
                  onClick={() => triggerScroll("right")}
                  className={cn("rounded-full !bg-blue-600 text-white p-3")}
                >
                  <IconArrowRight />
                </button>
              </div>
              {buyerDates.isFetching && <div className="absolute inset-0 bg-white/50" />}
            </>
          )}
        </div>
      </MpContainer>
      <MpContainer className="pt-0 md:pt-4 md:!-mt-4">
        {isError && errorsVisible && (
          <div {...errorAttr} className="text-red-400 mt-2 md:mt-4 mb-4">
            {t((d, template) => template(d.fo.schedule_buyer.dates.error, { min_days: MIN_DAYS }))}
          </div>
        )}
        <TimeDisplay />
      </MpContainer>
      <MpContainer>
        <h3>{t((d) => d.fo.schedule_buyer.dates.your_selection)}</h3>
        {formattedSelectedDates.map((date, i) => {
          return (
            <div key={i} className="flex items-center gap-2 mt-3">
              <IconCheckCircleFilled
                style={{ marginTop: ".1em", color: !date ? "#CDE4FF" : undefined }}
                className={cn({
                  "text-green-400": !!date,
                })}
              />
              <span className="first-letter:uppercase">{date ? `${date} 08:00 - 18:00` : "-"}</span>
            </div>
          );
        })}
      </MpContainer>
      <MpFooter>
        <MpContainer>
          <MpFooterCta
            loading={isLoading}
            disabled={isDisabled}
            onClick={buyerDates.update}
            onClickDisabled={errorVisibleAndScrollTo}
          >
            {isReScheduleBuyer && t((d) => d.fo.reschedule_buyer.dates.proceed_button)}
            {!isReScheduleBuyer && t((d) => d.fo.schedule_buyer.dates.proceed_button)}
          </MpFooterCta>
        </MpContainer>
      </MpFooter>
    </>
  );
};
