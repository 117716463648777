import { Button, IconLoader } from "@brenger/react";
import { parseApiDate } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { IconLeaf } from "../../../components";
import { useFormatDate, useTranslationContext } from "../../../hooks";
import { CacheKey, foClient } from "../../../utils";
import { trackAndTag } from "../../../utils/eventTracking";
import { Modal, MpFooterCta, MpModalContentHead, PageHead, TimeDisplay } from "../../components";
import { MpCard, MpContainer, MpFooter } from "../../components/Layout";
import { useScrollToError } from "../../hooks/useScrollToError";
import { useSellerDates } from "../../hooks/useSellerDates";
import { GenerateMpRoute, MpRouteComponentProps } from "../../routes";

export const ScheduleSellerSelectDate: React.FC<MpRouteComponentProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslationContext();
  const partyId = props.match.params.id;
  const dates = useSellerDates({ partyId });
  const formatDate = useFormatDate("weekday-month-long");
  const [isMoreDatesOpen, setIsMoreDatesOpen] = React.useState(false);
  const requestNewDates = useMutation([CacheKey.FO_REQUEST_MORE_DATES, partyId], foClient.marktplaats.requestNewDates, {
    onSuccess: () => {
      trackAndTag({
        event: "FO Interaction",
        type: "Seller requested new dates",
        data: dates.options.some((d) => d.kind === "UNAVAILABLE") ? "some expired" : "none expired",
        partyId,
      });
      history.push(GenerateMpRoute({ id: partyId, page: { type: "thank_you", state: "SCHEDULE_SELLER_NEW_DATES" } }));
    },
  });
  const isError = !dates.selected;
  const { errorsVisible, errorVisibleAndScrollTo, errorAttr } = useScrollToError();
  return (
    <>
      <PageHead title={t((d) => d.fo.schedule_seller.title)} sub={t((d) => d.fo.schedule_seller.sub_title)} />
      <MpContainer>
        {!dates.options.length && <IconLoader />}
        {!!dates.options.length &&
          dates.options.map((o) => {
            return (
              <button
                key={o.date}
                disabled={o.kind === "UNAVAILABLE"}
                className={"w-full disabled:text-gray-600"}
                onClick={() => dates.toggleDate(o.date)}
              >
                <MpCard
                  className={cn("flex gap-2 items-center !mb-2 !p-4", {
                    "!border-blue-600 text-blue-600 bg-blue-100": dates.selected === o.date,
                  })}
                >
                  <b className="first-letter:uppercase">{formatDate(parseApiDate(o.date))}</b>
                  {o.kind === "ECO_FRIENDLY" && <IconLeaf className="text-green-400" />}
                  {o.kind === "UNAVAILABLE" && (
                    <div className="ml-auto">{t((d) => d.fo.schedule_seller.date_option_expired)}</div>
                  )}
                  {o.kind !== "UNAVAILABLE" && <div className="ml-auto">08:00 - 18:00</div>}
                </MpCard>
              </button>
            );
          })}
        {isError && errorsVisible && (
          <div {...errorAttr} className="text-red-400 mb-4">
            {t((d) => d.fo.schedule_seller.date_error)}
          </div>
        )}
        <TimeDisplay />
        <button
          type="button"
          className="!bg-transparent text-blue-600 mt-2 mb-6"
          onClick={() => setIsMoreDatesOpen(true)}
        >
          {t((d) => d.fo.schedule_seller.request_more_dates)}
        </button>
      </MpContainer>
      <MpFooter>
        <MpContainer>
          <MpFooterCta
            disabled={isError || dates.isFetching || dates.isSubmitting}
            loading={dates.isFetching || dates.isSubmitting}
            onClickDisabled={errorVisibleAndScrollTo}
            onClick={() =>
              history.push(
                GenerateMpRoute({
                  id: props.match.params.id,
                  page: { type: "flows", state: "SCHEDULE_SELLER", progress: 2 },
                })
              )
            }
          >
            {t((d) => d.fo.schedule_seller.button)}
          </MpFooterCta>
        </MpContainer>
      </MpFooter>
      <Modal
        header={t((d) => d.fo.more_dates_modal.header as string)}
        isOpen={isMoreDatesOpen}
        closeHandler={() => setIsMoreDatesOpen(false)}
        theme="primary"
        footer={
          <div className="flex justify-center">
            <Button
              buttonType="secondary"
              loading={requestNewDates.isLoading}
              onClick={() => {
                requestNewDates.reset();
                requestNewDates.mutate({ partyId });
              }}
              className="w-full lg:w-auto"
            >
              {t((d) => d.fo.more_dates_modal.button)}
            </Button>
          </div>
        }
      >
        <MpModalContentHead title={t((d) => d.fo.more_dates_modal.title)} />
        {t((d) => d.fo.more_dates_modal.text)}
      </Modal>
    </>
  );
};
