import { TransportRequest } from "@brenger/api-client";
import { Small, Strong, Timeline } from "@brenger/react";
import { formatPrice, getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { Address, Card, DateTimePeriod, StatusIcon } from "../../components";
import { useTranslationContext } from "../../hooks";
import { getColorClassnamesForStatus, getStatus, getTypedStopsFromTr } from "../../utils";

interface Props {
  tr: TransportRequest;
}

export const CardForCustomer: React.FC<Props> = ({ tr }) => {
  const { i18n } = useTranslationContext();
  const { pickup, delivery } = getTypedStopsFromTr(tr);

  const title = tr.item_sets?.map((is) => is.title).join(", ");

  const pickupDtp = pickup.commitments.length > 0 ? pickup.commitments[0].committed_datetime_period : undefined;
  const deliveryDtp = delivery.commitments.length > 0 ? delivery.commitments[0].committed_datetime_period : undefined;

  const status = getStatus(tr);

  if (status === undefined) return null;

  return (
    <Link target="_blank" to={`/${getIdFromIri(tr["@id"])}`}>
      <Card
        header={
          <>
            <div className={cn("grid", "grid-cols-6", "gap-4")}>
              <div className={cn("col-span-5")}>
                <div className={cn("text-blue-600")}>
                  <Strong>{title}</Strong>
                </div>
              </div>
              <div className={cn("col-span-1", "flex", "justify-end")}>
                <StatusIcon
                  status={status}
                  contactType="customer"
                  className={cn("w-6", "h-6", getColorClassnamesForStatus(status))}
                />
              </div>
            </div>
            {tr.price && (
              // NOTE: SHOULD NOT BE UNDEFINED BUT IS SOMETIMES...
              <div>
                {typeof tr.price.amount === "number"
                  ? formatPrice({ amount: tr.price.amount, locale: i18n.locale, currency: tr.price.currency })
                  : "--"}
              </div>
            )}
          </>
        }
        body={
          <div className={cn("flex", "flex-col")}>
            <Timeline isFirst={true}>
              <div className={cn("grid", "grid-cols-3", "gap-4", "pb-1")}>
                <Small className={cn("col-span-2")}>
                  <Address address={pickup.address} />
                </Small>
                {pickupDtp && (
                  <Small className={cn("text-right")}>
                    <DateTimePeriod dtp={pickupDtp} />
                  </Small>
                )}
              </div>
            </Timeline>
            <Timeline isLast={true}>
              <div className={cn("grid", "grid-cols-3", "pt-1")}>
                <Small className={cn("col-span-2")}>
                  <Address address={delivery.address} />
                </Small>
                {deliveryDtp && (
                  <Small className={cn("text-right")}>
                    <DateTimePeriod dtp={deliveryDtp} />
                  </Small>
                )}
              </div>
            </Timeline>
          </div>
        }
        footer={<Small className={cn("text-gray-700", "uppercase")}>ID {tr.short_id}</Small>}
      />
    </Link>
  );
};
