import React from "react";
import { useTranslationContext } from "../../../hooks";
import { DotList } from "../../components/DotList";
import { MpContainer, ThankYouLayout } from "../../components/Layout";

export const ThankYouRescheduleBuyer: React.FC<{ id: string }> = () => {
  const { t } = useTranslationContext();

  return (
    <ThankYouLayout title={t((d) => d.fo.thank_you.schedule_buyer.title)}>
      <MpContainer>
        <DotList
          items={[
            t((d) => d.fo.thank_you.schedule_buyer.step_1),
            t((d) => d.fo.thank_you.schedule_buyer.step_2),
            t((d) => d.fo.thank_you.schedule_buyer.step_3),
          ]}
        />
      </MpContainer>
    </ThankYouLayout>
  );
};
