import { FoServiceLevel } from "@brenger/api-client";
import { Button, IconCheck, IconLoader } from "@brenger/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslationContext } from "../../../hooks";
import { FloorLevelModal, FloorText, MpFooterCta, PageHead, Price } from "../../components";
import { MpCard, MpContainer, MpFooter } from "../../components/Layout";
import { useExtraService } from "../../hooks/useExtraService";
import { FoPublicAvailableService } from "../../hooks/useExtraServiceOptions";
import { GenerateMpRoute, MpRouteComponentProps } from "../../routes";
import { CheckmarkList } from "../../../components";
import cn from "classnames";

export const ScheduleBuyerSelectService: React.FC<MpRouteComponentProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslationContext();
  const partyId = props.match.params.id;
  const extraService = useExtraService({ partyId });
  const [floorModalLevel, setFloorModalLevel] = React.useState<FoServiceLevel | null>(null);

  const isDisabled = extraService.isSubmitting || !extraService.selected || !extraService.selected?.service_level_name;
  const isLoading = extraService.isLoading || extraService.isSubmitting;

  const getFloorText = ({
    isSelected,
    onlyGroundLevel,
  }: {
    isSelected: boolean;
    onlyGroundLevel: boolean;
  }): React.ReactNode => {
    if (onlyGroundLevel) return t((d) => d.fo.service.only_ground_level);
    const floor = extraService.selected?.floor_level;
    if (!isSelected || typeof floor !== "number") return <u>{t((d) => d.fo.schedule_buyer.services.select_floor)}</u>;
    return (
      <u>
        <FloorText elevator={extraService.selected?.elevator} level={floor} />
      </u>
    );
  };

  const getPriceText = (service: FoPublicAvailableService): React.ReactNode => {
    const basePrice = service.base_price.value;
    const selected = service.name === extraService.selected?.service_level_name;
    if (basePrice === "0.00" && !service.isFloorLevelServiceAvailable) {
      return (
        <div
          className={cn({ "text-gray-600": !selected, "text-blue-600": selected, "br-text-bold": selected })}
          style={{ fontSize: "20px", lineHeight: "20px" }}
        >
          {t((d) => d.fo.price.free)}
        </div>
      );
    }
    if (selected && extraService.isSubmitting) {
      return <IconLoader />;
    }
    if (!selected) {
      return (
        <div className={"text-gray-600"} style={{ fontSize: "20px", lineHeight: "24px" }}>
          {t((d) => d.fo.price.from)} <Price price={basePrice} />
        </div>
      );
    }
    return (
      <b className={"text-blue-600"} style={{ fontSize: "24px", lineHeight: "24px" }}>
        <Price price={extraService.outcome?.price.value} />
      </b>
    );
  };

  return (
    <>
      <PageHead
        title={t((d) => d.fo.schedule_buyer.services.title)}
        sub={t((d) => d.fo.schedule_buyer.services.sub_title)}
      />
      <MpContainer className="relative z-0">
        {extraService.options.length === 0 && <IconLoader />}
        {extraService.options.map((helpOption, i) => {
          const isSelected = helpOption.name === extraService.selected?.service_level_name;
          return (
            <MpCard
              key={helpOption.name}
              style={{ boxShadow: "0px 8px 24px 0px rgba(124, 144, 168, 0.20)", zIndex: i }}
              className="!border-white"
              onClick={() => {
                if (!helpOption.isFloorLevelServiceAvailable) {
                  extraService.setService({
                    service_level_name: helpOption.name,
                    floor_level: 0,
                    elevator: false,
                  });
                  return;
                }
                setFloorModalLevel(helpOption.name);
              }}
            >
              <div className="flex justify-between pb-1">
                {helpOption.isStandard && <b className="text-blue-600">{t((d) => d.fo.service.standard)}</b>}
                {!helpOption.isStandard && (
                  <b className="text-green-400">{t((d) => d.fo.service.extra_service as string)}</b>
                )}
                {getPriceText(helpOption)}
              </div>
              <h5 className="pb-2 block">{helpOption.public_name}</h5>
              <CheckmarkList verticalAlign="top" items={helpOption.pros} />
              <div className="text-blue-600 mt-2">
                {getFloorText({
                  isSelected,
                  onlyGroundLevel: !helpOption.isFloorLevelServiceAvailable && helpOption.name !== "farfetchd",
                })}
              </div>
              <Button className="mt-4 mb-2 w-full" buttonType={isSelected ? "primary" : "primary-outline"}>
                <div className="flex items-center gap-1 justify-center">
                  {isSelected && <IconCheck className="h-5 w-5" />}{" "}
                  {t((d) => d.fo.schedule_buyer.services[isSelected ? "selected" : "select"])}
                </div>
              </Button>
            </MpCard>
          );
        })}
      </MpContainer>
      <MpFooter>
        <MpContainer>
          <MpFooterCta
            loading={isLoading}
            disabled={isDisabled}
            onClick={() =>
              history.push(
                GenerateMpRoute({
                  id: props.match.params.id,
                  page: { type: "flows", state: "SCHEDULE_BUYER", progress: 4 },
                })
              )
            }
          >
            {t((d) => d.fo.button.label.go_to_overview)}
          </MpFooterCta>
        </MpContainer>
      </MpFooter>
      <FloorLevelModal
        isOpen={floorModalLevel !== null}
        closeHandler={() => setFloorModalLevel(null)}
        selected={extraService.options.find((o) => o.name === floorModalLevel)}
        isLoading={extraService.isLoading}
        setService={extraService.setService}
      />
    </>
  );
};
