import { Button, Spacer } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { IconExclamationCircle, Page, PageContainer } from "../components";
import { useTranslationContext } from "../hooks";
import { Routes } from "../utils";

const NotFound: React.FC = () => {
  const { t } = useTranslationContext();

  return (
    <Page>
      <PageContainer className={cn("bg-gray-100", "py-8")}>
        <IconExclamationCircle className={cn("h-8", "w-8", "text-red-600", "mx-auto")} />
        <Spacer h={8} />
        <div>{t((d) => d.invalid_tracking_number)}</div>
        <Spacer h={8} />
        <Link to={Routes.ROOT}>
          <Button className={cn("mx-auto")}>{t((d) => d.try_again)}</Button>
        </Link>
      </PageContainer>
    </Page>
  );
};

export default NotFound;
