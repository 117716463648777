import React from "react";
import cn from "classnames";
import { getAbbrvForAdminArea } from "@brenger/utils";
import { Address as IAddress } from "@brenger/api-client";

interface Props {
  address: IAddress | undefined;
}

export const Address: React.FC<Props> = (props) => {
  if (!props.address) return null;

  const {
    line1,
    postal_code: postalCode,
    locality,
    administrative_area: adminArea,
    country_code: countryCode,
  } = props.address;

  return (
    <span>
      <span className={cn("block")}>
        {line1 && `${line1},`} {postalCode && `${postalCode}`}
      </span>
      <span className={cn("block")}>
        {locality && `${locality},`} {adminArea && `${getAbbrvForAdminArea(adminArea)},`} {countryCode}
      </span>
    </span>
  );
};
