import React from "react";
import cn from "classnames";

import { Status, ContactType } from "../utils";
import { useTranslationContext } from "../hooks";
interface Props {
  status: Status;
  contactType: ContactType;
}

type Chart = {
  [contactType in ContactType]: {
    [status in Status]: string;
  };
};

export const StatusMessage: React.FC<Props> = ({ status, contactType }) => {
  const { t } = useTranslationContext();

  const chart: Chart = {
    pickup: {
      0: t((d) => d.status.cancelled),
      1: t((d) => d.status.submitted),
      2: t((d) => d.status.scheduled),
      3: t((d) => d.status.on_the_way),
      4: t((d) => d.status.picked_up),
      5: t((d) => d.status.picked_up),
      6: t((d) => d.status.in_dispute),
    },
    delivery: {
      0: t((d) => d.status.cancelled),
      1: t((d) => d.status.submitted),
      2: t((d) => d.status.scheduled),
      3: t((d) => d.status.scheduled),
      4: t((d) => d.status.picked_up),
      5: t((d) => d.status.delivered),
      6: t((d) => d.status.in_dispute),
    },
    customer: {
      0: t((d) => d.status.cancelled),
      1: t((d) => d.status.submitted),
      2: t((d) => d.status.scheduled),
      3: t((d) => d.status.on_the_way),
      4: t((d) => d.status.picked_up),
      5: t((d) => d.status.delivered),
      6: t((d) => d.status.in_dispute),
    },
  };

  // Do not return in an HTML element - only the consumer of this component should be opinionated
  // about what type of text styling to apply.
  return <>{chart[contactType][status] || <span className={cn("invisible")}>--</span>}</>;
};
