import { Message } from "@brenger/react";
import React from "react";
import { MpContainer, MpLayout } from "../components/Layout";
import { logger } from "../../utils";
import { FoDraftTrState } from "@brenger/api-client";

export const ErrorPage: React.FC<{ partyId?: string; draftTr?: FoDraftTrState }> = ({ partyId, draftTr }) => {
  React.useEffect(() => {
    if (!partyId && !draftTr) return;
    if (partyId) {
      logger.exception(new Error("Draft TR did not load - error page shown"), { context: { partyId } });
    }
    if (draftTr) {
      // If we show this page
      logger.exception(new Error("Draft TR is in invalid state - error page shown"), { context: { draftTr } });
    }
  }, []);
  return (
    <MpLayout>
      <MpContainer>
        <Message type={"error"}>Something went wrong on our end, please contact support</Message>
      </MpContainer>
    </MpLayout>
  );
};
