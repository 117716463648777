import React from "react";
import { Redirect, Route, Switch, matchPath, useLocation } from "react-router-dom";
import { MpLayout } from "../../components/Layout";
import { MpRoutes } from "../../routes";
import { SharedContactPage } from "../SharedContactPage";
import { SharedBuyerDatesPage } from "../SharedBuyerDatesPage";
import { ScheduleBuyerSelectService } from "./SelectService";
import { ScheduleBuyerSummary } from "./Summary";
import { ScheduleBuyerExplain } from "./ScheduleBuyerExplain";
import { useDraftTr } from "../../hooks/useDraftTr";

export const ScheduleBuyer: React.FC = () => {
  const location = useLocation();
  const match = matchPath<{ id: string; page: string }>(location.pathname, {
    path: MpRoutes.FLOWS_SCHEDULE_BUYER,
    exact: true,
  });
  const page = Number(match?.params.page);
  const partyId = String(match?.params.id);
  const draftTr = useDraftTr({ partyId });
  // If phase is not defined, we should assume "step", so check if "bike";
  const isBikePhase = draftTr.data?.phase === "bike";
  /**
   * We want to show an explainer first in the Bike phase
   * This has a thank you layout, so we this should not be loaded trough the switch below because of the MP layout component
   */
  if (page === 0 && partyId) {
    return <ScheduleBuyerExplain id={partyId} />;
  }
  return (
    <MpLayout hasBack={page !== 1} progress={{ current: page, total: 4 }}>
      <Switch>
        <Route exact path={MpRoutes.FLOWS_SCHEDULE_BUYER_1} component={SharedBuyerDatesPage} />
        <Route exact path={MpRoutes.FLOWS_SCHEDULE_BUYER_2} component={SharedContactPage} />
        <Route exact path={MpRoutes.FLOWS_SCHEDULE_BUYER_3} component={ScheduleBuyerSelectService} />
        <Route exact path={MpRoutes.FLOWS_SCHEDULE_BUYER_4} component={ScheduleBuyerSummary} />
        <Redirect to={isBikePhase ? MpRoutes.FLOWS_SCHEDULE_BUYER_0 : MpRoutes.FLOWS_SCHEDULE_BUYER_1} />
      </Switch>
    </MpLayout>
  );
};
