import { FoServiceLevel } from "@brenger/api-client";
import { Button, IconLoader, InputToggle, Label } from "@brenger/react";
import { parseApiDate } from "@brenger/utils";
import React from "react";
import { CheckmarkList } from "../../../components";
import { useFormatDate, useTranslationContext } from "../../../hooks";
import { EmailMessage, FloorText, MpFooterCta, PageHead, TrDetails } from "../../components";
import { MpContainer, MpFooter } from "../../components/Layout";
import { Price } from "../../components/Price";
import { useBuyerDates } from "../../hooks/useBuyerDates";
import { useContact } from "../../hooks/useContact";
import { useDraftTr } from "../../hooks/useDraftTr";
import { useExtraService } from "../../hooks/useExtraService";
import { GenerateMpRoute, MpRouteComponentProps } from "../../routes";
import { Link } from "react-router-dom";

export const ScheduleBuyerSummary: React.FC<MpRouteComponentProps> = (props) => {
  const { t } = useTranslationContext();

  const partyId = props.match.params.id;
  const dates = useBuyerDates({ partyId });
  const formatDate = useFormatDate("weekday-month-long");
  const extraService = useExtraService({ partyId });
  const contact = useContact({ partyId });
  const paymentRedirect = extraService.outcome?.redirect_url;
  const draftTr = useDraftTr({ partyId });
  const isLoading = contact.isLoading || extraService.isLoading || dates.isLoading;

  const serviceText = extraService.selected
    ? t((d) => d.fo.service[extraService.selected?.service_level_name as FoServiceLevel].name)
    : "";

  const selectedService = extraService.options.find((o) => o.name === extraService.selected?.service_level_name);

  const floorLevelAvailable = selectedService && selectedService.isFloorLevelServiceAvailable;

  const editText = t((d) => d.fo.summary.edit).toLowerCase();
  return (
    <>
      <PageHead
        title={t((d) => d.fo.schedule_buyer.summary.title)}
        sub={t((d) => d.fo.schedule_buyer.summary.sub_title)}
      />
      {isLoading && (
        <MpContainer>
          <IconLoader />
        </MpContainer>
      )}
      {!isLoading && (
        <>
          <MpContainer>
            <h5 className="pb-3 flex justify-between">
              {t((d) => d.fo.schedule_buyer.summary.dates_title)}
              <Link
                to={GenerateMpRoute({ id: partyId, page: { type: "flows", state: "SCHEDULE_BUYER", progress: 1 } })}
              >
                <small className="text-blue-600 br-font">{editText}</small>
              </Link>
            </h5>
            <ul className="list-disc list-inside">
              {dates.selected.map((d) => (
                <li key={d} className="first-letter:uppercase">
                  {formatDate(parseApiDate(d))} 08:00 - 18:00
                </li>
              ))}
            </ul>
          </MpContainer>
          <MpContainer>
            <h5 className="pb-3 flex justify-between">
              {t((d) => d.fo.schedule_buyer.summary.contact_title)}{" "}
              <Link
                to={GenerateMpRoute({ id: partyId, page: { type: "flows", state: "SCHEDULE_BUYER", progress: 2 } })}
              >
                <small className="text-blue-600 br-font">{editText}</small>
              </Link>
            </h5>
            <div>{contact.data?.phone_number}</div>
            <div>{contact.data?.email}</div>
            {contact.data?.instructions && <div>{contact.data?.instructions}</div>}
          </MpContainer>
          <MpContainer>
            <h5 className="pb-3 flex justify-between">
              {t((d) => d.fo.schedule_buyer.summary.service_title)}
              <Link
                to={GenerateMpRoute({ id: partyId, page: { type: "flows", state: "SCHEDULE_BUYER", progress: 3 } })}
              >
                <small className="text-blue-600 br-font">{editText}</small>
              </Link>
            </h5>
            <div className="flex mb-2">
              <div>
                {extraService.outcome?.service_level_name === "farfetchd" && t((d) => d.fo.service.standard)}{" "}
                {serviceText}
              </div>
              {floorLevelAvailable && (
                <>
                  {", "}
                  <FloorText elevator={extraService.selected?.elevator} level={extraService.selected?.floor_level} />
                </>
              )}
            </div>
            <CheckmarkList items={selectedService?.pros || []} />
          </MpContainer>

          <MpContainer>
            <TrDetails partyId={partyId} />
            <h4 className="flex justify-between">
              <div>{t((d) => d.fo.price.total)}</div>
              <Price price={extraService.outcome?.price.value || "0.00"} />
            </h4>
          </MpContainer>
          {draftTr.data?.phase === "bike" && (
            <MpContainer>
              <div className="mb-2">
                <Label text={t((d) => d.fo.terms.news_letter_signup)} position={"right"}>
                  <InputToggle
                    checked={extraService.optIn}
                    onChange={() => extraService.setOptIn(!extraService.optIn)}
                  />
                </Label>
              </div>
            </MpContainer>
          )}
          <MpContainer>
            <EmailMessage text={t((d) => d.fo.schedule_buyer.availability_message)} />
          </MpContainer>
        </>
      )}
      <MpFooter>
        <MpContainer>
          {paymentRedirect && (
            <MpFooterCta
              loading={isLoading || extraService.isSubmitting}
              disabled={isLoading}
              onClick={extraService.scheduleComplete}
            >
              <div>
                {t((d) => d.fo.price.pay)} <Price price={extraService.outcome?.price.value} />
              </div>
            </MpFooterCta>
          )}
          {!paymentRedirect && (
            <Button
              className="w-full"
              loading={isLoading || extraService.isSubmitting}
              disabled={isLoading || extraService.isSubmitting}
              buttonType="secondary"
              onClick={extraService.scheduleComplete}
            >
              {t((d) => d.fo.schedule_buyer.summary.button)}
            </Button>
          )}
        </MpContainer>
      </MpFooter>
    </>
  );
};
