import { useState } from "react";
import { ModalProps } from "../../../components";
import { ReviewModalStep1 as ReviewModalStep1 } from "./ReviewModalStep1";
import { ReviewModalStep2 } from "./ReviewModalStep2";
import { ReviewModalStep3 } from "./ReviewModalstep3";

export type StepType = 1 | 2 | 3;

export interface ReviewFirstPart {
  neat_vehicle: null | boolean;
  moving_supplies: null | boolean;
}

/**
 * This component manages the "routing" between the two sections of the reivew modal.
 */
export const ReviewModalRoot: React.FC<ModalProps> = (props) => {
  const [reviewId, setReviewId] = useState<string>("");
  const [reviewStep, setReviewStep] = useState<StepType>(1);
  const [reviewFirstPart, setReviewFirstPart] = useState<Partial<ReviewFirstPart>>();

  // NOTE: create an "intelligent" close handler. When a review has been created,
  // reload the entire page so that the data can be re-fetched. When no reviewId exists,
  // can safely just close the modal.
  const closeHandler = (): void => {
    if (reviewId) {
      window.location.reload();
    } else {
      props.closeHandler();
    }
  };

  if (reviewStep === 1) {
    return (
      <ReviewModalStep1
        closeHandler={closeHandler}
        isActive={props.isActive}
        setReviewPart={(part: ReviewFirstPart) => setReviewFirstPart(part)}
        setStep={(step: StepType) => setReviewStep(step)}
      />
    );
  }
  if (reviewStep === 2) {
    return (
      <ReviewModalStep2
        closeHandler={closeHandler}
        isActive={props.isActive}
        reviewFirstPart={reviewFirstPart as ReviewFirstPart}
        setReviewId={(id: string) => setReviewId(id)}
        setStep={(step: StepType) => setReviewStep(step)}
      />
    );
  }
  return (
    <ReviewModalStep3
      closeHandler={closeHandler}
      isActive={props.isActive}
      reviewId={reviewId}
      setStep={(step: StepType) => setReviewStep(step)}
    />
  );
};
