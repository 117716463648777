import cn from "classnames";
import React from "react";

interface ContainerProps {
  withDivider: boolean;
}

export const ColumnContainer: React.FC<ContainerProps & React.HTMLAttributes<HTMLElement>> = ({
  withDivider,
  children,
  className,
  ...rest
}) => {
  return (
    <>
      <div
        className={cn("container", "px-4", "mx-auto", "py-6", "xl:py-10", "xl:w-full", "xl:px-0", className)}
        {...rest}
      >
        {children}
      </div>
      {withDivider && (
        <div
          className={cn("border-t-4", "xl:hidden")}
          // @TODO add border color to tailwind config
          style={{ borderColor: "#D1E4FD" }}
        />
      )}
    </>
  );
};
