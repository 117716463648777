import cn from "classnames";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Marktplaats } from "./Marktplaats";
import { SandboxModeNotification } from "./components";
import * as Pages from "./pages";
import { Routes, isSandboxMode } from "./utils";

export const App: React.FC = () => {
  return (
    <div style={{ minHeight: "100vh" }} className={cn("flex", "flex-col")}>
      {isSandboxMode() && <SandboxModeNotification />}
      <Switch>
        <Route exact path={Routes.NOT_FOUND} component={Pages.NotFound} />
        <Route exact path={Routes.TRANSPORT_LIST} component={Pages.TransportList} />
        <Route path={Routes.MARKTPLAATS} component={Marktplaats} />
        {/* ROOT page must NOT go last because it matches EXACTLY on `/` -- anything else falls through to next one below */}
        <Route exact path={Routes.ROOT} component={Pages.Root} />
        <Route path={Routes.LOGIN} component={Pages.Login} />
        <Route path={Routes.LOGOUT} component={Pages.Logout} />
        {/* TRANSPORT_DETAILS page must go last in Switch because matches on any and all other paths */}
        <Route path={Routes.TRANSPORT_DETAILS} component={Pages.TransportDetails} />
      </Switch>
    </div>
  );
};
