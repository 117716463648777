import cn from "classnames";
import React from "react";

import { PageContainer } from ".";
import { Config, LOCALE_TEMPLATE } from "../config";
import { useFreshChat, useTranslationContext } from "../hooks";

export const Footer: React.FC = () => {
  const { t, i18n } = useTranslationContext();
  const freshChat = useFreshChat();

  return (
    <PageContainer className={cn("py-6", "bg-gray-100")}>
      {/*
        NOTE: use w-3/4 to account for the FreshChat widget in the bottom right on mobile views.
        This prevents the widget from covering the footer text.
      */}
      <div className={cn("text-blue-600", "w-3/4")}>
        {t((d) => d.questions)} {t((d) => d.footer.click_on_the)}{" "}
        <button className={cn("underline")} onClick={() => freshChat?.open()}>
          {t((d) => d.chat_icon)}
        </button>{" "}
        {t((d) => d.footer.or_view_the)}{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href={Config.CUSTOMER_FAQ_URL.replace(LOCALE_TEMPLATE, i18n.locale)}
          className={cn("underline")}
        >
          {t((d) => d.faq)}
        </a>
      </div>
    </PageContainer>
  );
};
