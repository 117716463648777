import { createContext, useContext } from "react";
import axios, { CancelTokenSource } from "axios";
import { Redirect } from "react-router-dom";
import { useDebounce, useForm, UseForm } from "@brenger/react";
import {
  Address,
  TransportRequest,
  TransportRequestListParams,
  CorePaginationControls,
  List,
  parseCorePaginationControls,
} from "@brenger/api-client";
import { useQuery } from "react-query";

import { useAuth } from ".";
import { CacheKey, coreClient, Routes } from "../utils";
import { Page } from "../components";
import { getIdFromIri, isInvalidDate } from "@brenger/utils";

const INPUT_DEBOUNCE = 250;

let source: CancelTokenSource | undefined = undefined;

// NOTE: the TR list endpoint is used to search and quickly filter on items. This means that the user
// may trigger many requests in a short period of time, which introduces a race condition as requests
// get resolved and returned from the backend. To eliminate the possibility of earlier-in-time requests
// over-writing later-in-time requests, it's good practice to cancel requests that are in-flight.
const transportRequestList = (params: TransportRequestListParams): Promise<List<TransportRequest>> => {
  if (source !== undefined) source.cancel();
  source = axios.CancelToken.source();
  return coreClient.transportRequests.list({ ...(params || {}), cancelToken: source.token });
};

const fetchAddresses = (addresses: { id: string }[]): Promise<Address[]> => {
  return Promise.all(addresses.map((address) => coreClient.addresses.retrieve(address)));
};

const initialFormState: TransportRequestListParams = {
  page: 1,
  search: "",
  delivery_day: undefined,
  presentation: undefined,
  business_order_type: undefined,
  "stop_address[line1]": undefined,
  "stop_address[postal_code]": undefined,
  "stop_address[locality]": undefined,
};
interface Context {
  filters: UseForm.Form<TransportRequestListParams> | undefined;
  trList: TransportRequest[];
  paginationControls: CorePaginationControls | undefined;
  addressList: Address[];
  isLoading: boolean;
}

const initialContextState: Context = {
  trList: [],
  addressList: [],
  paginationControls: undefined,
  filters: undefined,
  isLoading: false,
};

const TransportListContext = createContext(initialContextState);

export const TransportListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useAuth();

  const filters = useForm({
    initialState: initialFormState,
    validators: {
      // NOTE: the native browser date input does not need a validator UNLESS the user
      // is on a desktop safari browser, in which case the browser renders a normal
      // text input.
      delivery_day: isInvalidDate,
    },
  });

  // NOTE: the following fields come from text inputs, which means they need to be debounced in order
  // to avoid slamming the server with a new request on each key stroke.
  const debouncedSearch = useDebounce(filters.data.search?.value || "", INPUT_DEBOUNCE);
  const debouncedLine1 = useDebounce(filters.data["stop_address[line1]"]?.value || "", INPUT_DEBOUNCE);
  const debouncedPostalCode = useDebounce(filters.data["stop_address[postal_code]"]?.value || "", INPUT_DEBOUNCE);
  const debouncedLocality = useDebounce(filters.data["stop_address[locality]"]?.value || "", INPUT_DEBOUNCE);
  const params = {
    // NOTE: includes `|| undefined` in order to remove the param when the search is an empty string.
    search: debouncedSearch || undefined,
    "stop_address[line1]": debouncedLine1 || undefined,
    "stop_address[postal_code]": debouncedPostalCode || undefined,
    "stop_address[locality]": debouncedLocality || undefined,
    delivery_day: filters.data.delivery_day?.value || undefined,
    page: filters.data.page?.value,
    presentation: filters.data.presentation?.value,
    business_order_type: filters.data.business_order_type?.value,
  };
  const trs = useQuery([CacheKey.RETRIEVE_TR_LIST, JSON.stringify(params)], () => transportRequestList(params), {
    enabled: !!auth.user && !filters.hasErrors,
  });

  const trList = trs.data?.["hydra:member"] || [];
  const paginationControls = trs.data ? parseCorePaginationControls(trs.data) : undefined;

  const accountAddresses = ((auth.account?.addresses as string[] | undefined) || []).map((iri: string) => {
    return {
      id: getIdFromIri(iri) || "",
    };
  });
  const addresses = useQuery(
    [CacheKey.RETRIEVE_ADDRESS, ...accountAddresses.map((a) => a.id)],
    () => fetchAddresses(accountAddresses),
    {
      enabled: !!accountAddresses.length,
    }
  );
  const addressList = addresses.data || [];

  if (auth.loading) return <Page loading={true} />;

  if (!auth.user) return <Redirect to={Routes.ROOT} />;

  return (
    <TransportListContext.Provider
      value={{ isLoading: trs.isLoading, addressList, trList, filters, paginationControls }}
    >
      {children}
    </TransportListContext.Provider>
  );
};

export const useTransportListContext = (): Context => {
  return useContext(TransportListContext);
};
