"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAbbrvForAdminArea = void 0;
var getAbbrvForAdminArea = function (adminArea) {
    var map = {
        drenthe: 'DR',
        flevoland: 'FL',
        friesland: 'FR',
        gelderland: 'GE',
        groningen: 'GR',
        limburg: 'LI',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'noord-brabant': 'NB',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'noord-holland': 'NH',
        overijssel: 'OV',
        utrecht: 'UT',
        zeeland: 'ZE',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'zuid-holland': 'ZH'
    };
    return map[adminArea === null || adminArea === void 0 ? void 0 : adminArea.toLocaleLowerCase()] || adminArea;
};
exports.getAbbrvForAdminArea = getAbbrvForAdminArea;
