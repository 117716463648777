import React from "react";

export const IconCalendar: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.9375 1.25H12.5V0.5C12.5 0.367392 12.4473 0.240215 12.3536 0.146447C12.2598 0.0526784 12.1326 0 12 0C11.8674 0 11.7402 0.0526784 11.6464 0.146447C11.5527 0.240215 11.5 0.367392 11.5 0.5V1.25H4.5V0.5C4.5 0.367392 4.44732 0.240215 4.35355 0.146447C4.25979 0.0526784 4.13261 0 4 0C3.86739 0 3.74021 0.0526784 3.64645 0.146447C3.55268 0.240215 3.5 0.367392 3.5 0.5V1.25H2.0625C1.51569 1.25066 0.991473 1.46817 0.604823 1.85482C0.218173 2.24147 0.000661593 2.76569 0 3.3125L0 13.9375C0.000661593 14.4843 0.218173 15.0085 0.604823 15.3952C0.991473 15.7818 1.51569 15.9993 2.0625 16H13.9375C14.4843 15.9993 15.0085 15.7818 15.3952 15.3952C15.7818 15.0085 15.9993 14.4843 16 13.9375V3.3125C15.9993 2.76569 15.7818 2.24147 15.3952 1.85482C15.0085 1.46817 14.4843 1.25066 13.9375 1.25ZM2.0625 2.25H3.5V2.75C3.5 2.88261 3.55268 3.00979 3.64645 3.10355C3.74021 3.19732 3.86739 3.25 4 3.25C4.13261 3.25 4.25979 3.19732 4.35355 3.10355C4.44732 3.00979 4.5 2.88261 4.5 2.75V2.25H11.5V2.75C11.5 2.88261 11.5527 3.00979 11.6464 3.10355C11.7402 3.19732 11.8674 3.25 12 3.25C12.1326 3.25 12.2598 3.19732 12.3536 3.10355C12.4473 3.00979 12.5 2.88261 12.5 2.75V2.25H13.9375C14.2193 2.25 14.4895 2.36194 14.6888 2.5612C14.8881 2.76046 15 3.03071 15 3.3125V4.5H1V3.3125C1 3.03071 1.11194 2.76046 1.3112 2.5612C1.51046 2.36194 1.78071 2.25 2.0625 2.25ZM13.9375 15H2.0625C1.78071 15 1.51046 14.8881 1.3112 14.6888C1.11194 14.4895 1 14.2193 1 13.9375V5.5H15V13.9375C15 14.2193 14.8881 14.4895 14.6888 14.6888C14.4895 14.8881 14.2193 15 13.9375 15Z" />
      <path d="M11.9734 7.82495H4.02623C3.73214 7.82495 3.49373 8.06336 3.49373 8.35745C3.49373 8.65154 3.73214 8.88995 4.02623 8.88995H11.9734C12.2675 8.88995 12.5059 8.65154 12.5059 8.35745C12.5059 8.06336 12.2675 7.82495 11.9734 7.82495Z" />
      <path d="M11.9785 10.8403H4.03128C3.73719 10.8403 3.49878 11.0787 3.49878 11.3728C3.49878 11.6669 3.73719 11.9053 4.03128 11.9053H11.9785C12.2726 11.9053 12.511 11.6669 12.511 11.3728C12.511 11.0787 12.2726 10.8403 11.9785 10.8403Z" />
    </svg>
  );
};
