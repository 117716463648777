import { FoDraftTrState } from "@brenger/api-client";
import React from "react";
import { Redirect } from "react-router-dom";
import { TR_ACTION_CANCEL, TR_ACTION_PARAM } from "../pages/TransportDetails/Cancel/CancelCTA";
import { BuyerCancelPage } from "./Pages/Cancel/BuyerCancelPage";
import { SellerCancelPage } from "./Pages/Cancel/SellerCancelPage";
import { MpLoader } from "./components";
import { useDraftTr } from "./hooks/useDraftTr";
import { GenerateMpRoute, MpRouteComponentProps } from "./routes";

export const Cancel: React.FC<MpRouteComponentProps> = (props) => {
  const partyId = props.match.params.id;
  const draftTr = useDraftTr({ partyId });
  return (
    <>
      <MpLoader partyId={partyId} />
      {draftTr.data && <PartyStateGate draftTr={draftTr.data} partyId={props.match.params.id} />}
    </>
  );
};

const PartyStateGate: React.FC<{ draftTr: FoDraftTrState; partyId: string }> = ({ draftTr, partyId }) => {
  const { state, party, party_tracking_id: partyTrId } = draftTr;
  /**
   * If is cancelled already, show the cancelled page
   */
  if (state === "CANCELLED") return <Redirect to={GenerateMpRoute({ id: partyId, isCancelled: true })} />;

  /**
   * Seller is not allowed, which will be told on this page
   */
  if (party === "seller") {
    return <SellerCancelPage />;
  }

  /**
   * Exception: if we reached state === 'COMPLETED' then we just want to redirect
   * For this redirect the party_tracking_id has to be present
   * We need to handle it further at the tracking page so we indicate this with a param
   */
  if (state === "COMPLETED" && partyTrId) {
    const url = new URL(`/${partyTrId}`, window.location.origin);
    url.searchParams.append(TR_ACTION_PARAM, TR_ACTION_CANCEL);
    return <Redirect to={url.pathname + url.search} />;
  }

  /**
   * Proceed to cancel
   */
  return <BuyerCancelPage partyId={partyId} />;
};
