import { IconCheck, IconClose, Textarea } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useTranslationContext } from "../../hooks";

interface Props {
  isBuyer: boolean;
  value: string | undefined;
  setValue(v: string | undefined): void;
}

export const EditInstructionsField: React.FC<React.HtmlHTMLAttributes<HTMLDivElement> & Props> = ({
  value,
  setValue,
  className,
  isBuyer,
  ...rest
}) => {
  const { t } = useTranslationContext();
  const [showHint, setShowHint] = React.useState(false);
  return (
    <div className={cn("relative", className)} {...rest}>
      <small
        className={cn("block absolute bg-white p-4 pb-5 border rounded-xl shadow-lg", { hidden: !showHint })}
        style={{ bottom: "calc(100% + 2.5rem)" }}
      >
        <div className="flex items-center gap-1">
          <IconCheck className="text-green-400 w-4 h-4 mt-1" />{" "}
          {t((d) => d.fo.contact_modal.fields.instructions.allowed_1)}
        </div>
        <div className="flex items-start gap-1">
          <IconClose className="text-red-600 w-4 h-4 mt-1" style={{ padding: "0 .1em" }} />
          {t((d) => d.fo.contact_modal.fields.instructions.not_allowed_1)}
        </div>
        <div className="flex items-start gap-1">
          <IconClose className="text-red-600 w-4 h-4 mt-1" style={{ padding: "0 .1em" }} />
          {t((d) => d.fo.contact_modal.fields.instructions.not_allowed_2)}
        </div>
        <div className="flex items-start gap-1">
          <IconClose className="text-red-600 w-4 h-4 mt-1" style={{ padding: "0 .1em" }} />
          {t((d) => d.fo.contact_modal.fields.instructions.not_allowed_3)}
          {isBuyer && <> {t((d) => d.fo.contact_modal.fields.instructions.see_next_step)}</>}
        </div>
        <div
          className="w-4 h-4 bg-white border-t border-r absolute"
          style={{ bottom: "-.5rem", left: "calc(50% - 2rem)", transform: "rotate(135deg)" }}
        />
      </small>
      <Textarea
        className={cn("w-full", "px-4 py-3", "text-lg", "border-gray-400")}
        style={{ height: "9rem" }}
        placeholder={t((d) => d.fo.contact_page.fields.instructions.placeholder)}
        onChange={(v) => {
          setValue(v);
        }}
        value={value}
        onFocus={() => setShowHint(true)}
        onBlur={() => setShowHint(false)}
      />
    </div>
  );
};
