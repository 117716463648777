"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCO2SavingsForKilometers = void 0;
var getCO2SavingsForKilometers = function (kilometers) {
    // return ride when done by the customer
    var nonBrengerDistance = kilometers * 2;
    // assumption: percentage that a brenger needs to detour to accomplish TR
    var brengerDistance = kilometers * 1.2;
    // CO2 savings with an average of 0.275 CO2 kg per KM
    var savedCO2Emission = (nonBrengerDistance - brengerDistance) * 0.275;
    // One hamburger is equal to 1.8 kg
    var hamburgers = savedCO2Emission / 1.8;
    // One shower turn on average is 9 minutes * 0.06 kg per minute
    var showerTurns = savedCO2Emission / (9 * 0.06);
    return {
        inKg: parseFloat(savedCO2Emission.toFixed(1)),
        inHamburgers: parseFloat(hamburgers.toFixed(1)),
        inShowerTurns: parseFloat(showerTurns.toFixed(1))
    };
};
exports.getCO2SavingsForKilometers = getCO2SavingsForKilometers;
