"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWhatsAppUrl = void 0;
var WA_BASE_URL = 'https://api.whatsapp.com/send';
var PREFIX_LOCAL_TO_INTERNATIONAL = [
    { from: '06', to: '316' },
    { from: '04', to: '324' },
    { from: '3106', to: '316' },
    { from: '3204', to: '324' }
];
var WHITELISTED_PREFIXES = [
    // Dutch
    '316',
    // Belgium
    '324',
    // Germany
    '49'
];
var localToInternational = function (input) {
    var prefix = PREFIX_LOCAL_TO_INTERNATIONAL.find(function (_a) {
        var from = _a.from;
        return input.startsWith(from);
    });
    if (prefix) {
        return input.replace(prefix.from, prefix.to);
    }
    return input;
};
var removePrecedingPlus = function (input) {
    if (input.startsWith('+')) {
        return input.replace('+', '');
    }
    return input;
};
var removeSpace = function (input) {
    return input.replace(/\s/g, '');
};
var shouldCreateWhatsAppLink = function (number) {
    return WHITELISTED_PREFIXES.some(function (prefix) { return number.startsWith(prefix); });
};
var cleanPhoneNumber = function (rawNumber) {
    var cleanNumber = [rawNumber].map(removeSpace).map(removePrecedingPlus).map(localToInternational)[0];
    return cleanNumber;
};
var createWhatsAppPrefix = function (number, text) {
    var params = new URLSearchParams();
    params.append('phone', number);
    if (text) {
        params.append('text', text);
    }
    return "".concat(WA_BASE_URL, "?").concat(params.toString());
};
var createWhatsAppUrl = function (number, text) {
    if (!number)
        return '';
    var cleanedNumber = cleanPhoneNumber(number);
    return shouldCreateWhatsAppLink(cleanedNumber) ? createWhatsAppPrefix(cleanedNumber, text) : '';
};
exports.createWhatsAppUrl = createWhatsAppUrl;
