import { Item } from "@brenger/api-client";
import { IconInfo, Spacer } from "@brenger/react";
import { formatPrice } from "@brenger/utils";
import cn from "classnames";
import { Fragment } from "react";

import { ColumnContainer, ProductSelectionSpecs } from "../../components";
import { useTranslationContext, useTransportContext } from "../../hooks";
import { createAccessControl } from "../../utils";

const getPerms = createAccessControl({
  pickup: {
    canSeePriceOnBusinessOrders: undefined,
    canSeePriceOnMarktplaatsOrders: undefined,
  },
  delivery: {
    canSeePriceOnBusinessOrders: undefined,
    canSeePriceOnMarktplaatsOrders: undefined,
  },
  customer: {
    canSeePriceOnBusinessOrders: [0, 1, 2, 3, 4, 5, 6],
    canSeePriceOnMarktplaatsOrders: [0, 1, 2, 3, 4, 5, 6],
  },
});

export const OrderOverview: React.FC = () => {
  const { t, i18n } = useTranslationContext();
  const ctx = useTransportContext();

  if (!ctx.tr) return null;

  const { price } = ctx.tr;
  const isVatExcluded = !price.vat.amount;
  const clientReferences = ctx.tr?.item_sets.map((is) => is.client_reference).filter(Boolean);

  const { perms } = getPerms(ctx.contactType, ctx.status);

  let showPrice = true;

  // NOTE: check cases where prices should be hidden
  // First, look at internal attributes.
  if (ctx.tr?.internal_attributes.hide_prices) showPrice = false;
  // Second, look at source flow and perms.
  const isBusinessOrder = ctx.tr?.source_flow === "Business";
  if (isBusinessOrder && !perms?.canSeePriceOnBusinessOrders) showPrice = false;
  const isMarktplaatsOrder = ctx.tr?.source_flow === "Marktplaats";
  if (isMarktplaatsOrder && !perms?.canSeePriceOnMarktplaatsOrders) showPrice = false;

  const formattedPrice = formatPrice({ amount: price.amount, currency: price.currency, locale: i18n.locale });
  const vatMessage = isVatExcluded ? t((d) => d.vat.excluded) : "";
  const priceString = `${formattedPrice} ${vatMessage}`.trim();
  return (
    <ColumnContainer withDivider={true}>
      <h6>{t((d) => d.order_overview)}</h6>
      <Spacer h={2} />
      <div className={cn("grid", "grid-cols-4")}>
        {ctx.tr?.item_sets.map((itemSet) => {
          const items = itemSet.items as Item[];

          return items.map((item, idx) => {
            return (
              <Fragment key={idx}>
                <div className={cn("col-span-4")}>
                  {/* Try to display item title, but if not available show itemSet title */}
                  {item.title || itemSet.title} {item.count > 1 ? `(${item.count}x)` : null}{" "}
                  <ProductSelectionSpecs item={item} />
                </div>
                <div className={cn("col-span-4 mb-2")}>
                  {item.length} &times; {item.width} &times; {item.height} cm
                </div>
              </Fragment>
            );
          });
        })}
        {clientReferences?.map((ref) => {
          return (
            <div className={cn("col-span-4 mb-2")} key={ref}>
              {t((d) => d.order_number)}: {ref}
            </div>
          );
        })}
      </div>
      {showPrice && price.amount > 0 && (
        <h6 className="flex justify-between">
          <div className={cn("col-span-3")}>{t((d) => d.total)}</div>
          <div className={cn("col-span-1", "text-right")}>{priceString}</div>
        </h6>
      )}
      {isMarktplaatsOrder && !perms?.canSeePriceOnMarktplaatsOrders && (
        <div className="flex gap-2 items-center text-blue-600">
          <IconInfo className="flex-none" /> {t((d) => d.order_overview_info_paid_by_buyer)}
        </div>
      )}
    </ColumnContainer>
  );
};
