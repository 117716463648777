import { FoDraftTrState } from "@brenger/api-client";
import React from "react";
import { Redirect, Route, Switch, matchPath, useLocation } from "react-router-dom";
import { MpContainer, MpLayout } from "../../components/Layout";
import { MpRoutes } from "../../routes";
import { ScheduleSellerSelectDate } from "./SelectDate";
import { ScheduleSellerSummary } from "./Summary";
import { SharedContactPage } from "../SharedContactPage";
import { useSellerDates } from "../../hooks/useSellerDates";
import { IconLoader } from "@brenger/react";
import { ScheduleSellerExpired } from "./Expired";
import { trackAndTag } from "../../../utils/eventTracking";

export const ScheduleSeller: React.FC<{ draftTr: FoDraftTrState }> = () => {
  const location = useLocation();
  const match = matchPath<{ page?: string; id: string }>(location.pathname, {
    path: [MpRoutes.WAITING, MpRoutes.FLOWS, MpRoutes.FLOWS_SCHEDULE_SELLER],
    exact: true,
  });
  const page = Number(match?.params.page) || -1;
  const partyId = match?.params.id as string;
  const dates = useSellerDates({ partyId });
  const hasAvailableOptions = !!dates.options.filter((o) => o.kind !== "UNAVAILABLE").length;

  // Track and tag unavailable options
  React.useEffect(() => {
    if (!dates.options.length) return;
    const hasUnavailableOptions = dates.options.some((o) => o.kind === "UNAVAILABLE");
    const onlyUnavailableOptions = dates.options.every((o) => o.kind === "UNAVAILABLE");
    if (hasUnavailableOptions) {
      trackAndTag({
        event: "FO Interaction",
        type: "Seller sees expired dates",
        data: onlyUnavailableOptions ? "all expired" : "some expired",
        partyId,
      });
    }
  }, [dates.options.length]);

  return (
    <MpLayout
      hasBack={hasAvailableOptions && page !== 1}
      progress={hasAvailableOptions ? { current: page, total: 3 } : undefined}
    >
      {dates.isLoading && (
        <MpContainer>
          <IconLoader />
        </MpContainer>
      )}
      {!dates.isLoading && hasAvailableOptions && (
        <Switch>
          <Route exact path={MpRoutes.FLOWS_SCHEDULE_SELLER_1} component={ScheduleSellerSelectDate} />
          <Route exact path={MpRoutes.FLOWS_SCHEDULE_SELLER_2} component={SharedContactPage} />
          <Route exact path={MpRoutes.FLOWS_SCHEDULE_SELLER_3} component={ScheduleSellerSummary} />
          <Redirect to={MpRoutes.FLOWS_SCHEDULE_SELLER_1} />
        </Switch>
      )}
      {!dates.isLoading && !hasAvailableOptions && (
        <Switch>
          <Route exact path={MpRoutes.FLOWS_SCHEDULE_SELLER_EXPIRED} component={ScheduleSellerExpired} />
          <Redirect to={MpRoutes.FLOWS_SCHEDULE_SELLER_EXPIRED} />
        </Switch>
      )}
    </MpLayout>
  );
};
