import { IconLoader } from "@brenger/react";
import React from "react";
import { useQuery } from "react-query";
import { useTranslationContext } from "../hooks";
import { SupportedLocales } from "../utils";
import "../assets/styles/wordpress.scss";
import cn from "classnames";

type OptionalLangs = Exclude<SupportedLocales, "en-NL" | "nl-NL">;
type RequiredLangs = Exclude<SupportedLocales, "de-DE" | "nl-BE">;

interface WordPressPostIds extends Record<RequiredLangs, number>, Partial<Record<OptionalLangs, number>> {}

type ContentName = "terms-general" | "terms-privacy" | "terms-transport";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  startFetching?: boolean;
  content: ContentName;
}

export const WordPressContent: React.FC<Props> = ({ content, startFetching, className, ...rest }) => {
  const { i18n } = useTranslationContext();
  const locale = i18n.locale as SupportedLocales;
  const ids = contentIds[content];
  const postId = ids[locale] || ids["en-NL"];
  const wordpressPost = useQuery(["WORDPRESS_CONTENT", locale, postId], () => wpContentFetcher(locale, postId), {
    enabled: startFetching,
  });
  if (!wordpressPost.data) return <IconLoader />;
  return (
    <div
      className={cn("wp-content", className)}
      {...rest}
      dangerouslySetInnerHTML={{ __html: wordpressPost.data.content.rendered }}
    />
  );
};

const wpContentFetcher = async (
  locale: SupportedLocales,
  id: number
): Promise<{
  content: {
    rendered: string;
  };
}> => {
  const baseUrl = {
    "nl-NL": "https://www.brenger.nl/nl/wp-json/wp/v2",
    "en-NL": "https://www.brenger.nl/en-nl/wp-json/wp/v2",
    "nl-BE": "https://www.brenger.be/nl-be/wp-json/wp/v2",
    "de-DE": "https://www.brengertransport.de/de-de/wp-json/wp/v2",
  }[locale];
  const endpoint = `/pages/${id}`;

  const content = await fetch(`${baseUrl}${endpoint}`, { method: "GET" });
  return content.json();
};

const contentIds: Record<ContentName, WordPressPostIds> = {
  "terms-general": {
    "nl-NL": 1683,
    "en-NL": 320,
    "nl-BE": 646,
    "de-DE": 400,
  },
  "terms-privacy": {
    "nl-NL": 1077,
    "en-NL": 300,
    "nl-BE": 555,
    "de-DE": 390,
  },
  "terms-transport": {
    "nl-NL": 1075,
    "en-NL": 298,
    "nl-BE": 553,
    "de-DE": 388,
  },
};
