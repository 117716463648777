import React from "react";
import { MpContainer } from "./Layout";

export const PageHead: React.FC<{ title: string; sub?: string }> = ({ title, sub }) => {
  return (
    <MpContainer>
      <h1>{title}</h1>
      {sub && (
        <div className={"pt-2"} style={{ fontSize: 20 }}>
          {sub}
        </div>
      )}
    </MpContainer>
  );
};
