import cn from "classnames";
import React from "react";
import { PageContainer, StatusBar, StatusIcon, StatusMessage } from "../../components";
import { useTransportContext } from "../../hooks";
import { getColorClassnamesForStatus } from "../../utils";

export const Status: React.FC = () => {
  const { status, contactType } = useTransportContext();

  if (status === undefined) return null;
  if (contactType === undefined) return null;

  return (
    <PageContainer>
      <div className={cn("grid", "grid-cols-4", "gap-4", "w-full", "mb-4")}>
        <div className={cn("col-span-3")}>
          <div className={cn("flex", "flex-col")}>
            <h1 className={cn("h5", "text-blue-600")}>
              <StatusMessage status={status} contactType={contactType} />
            </h1>
            <StatusBar status={status} contactType={contactType} />
          </div>
        </div>
        <div className={cn("col-span-1")}>
          <div className={cn("flex", "justify-end", "items-center")}>
            <StatusIcon
              status={status}
              contactType={contactType}
              className={cn("mt-2", "sm:w-10", "sm:h-10", "w-8", "h-8", getColorClassnamesForStatus(status))}
            />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};
