import { Button } from "@brenger/react";
import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslationContext } from "../../../hooks";
import { CacheKey, foClient } from "../../../utils";
import { PageHead } from "../../components";
import { MpContainer, MpFooter } from "../../components/Layout";
import { GenerateMpRoute, MpRouteComponentProps } from "../../routes";
import { trackAndTag } from "../../../utils/eventTracking";

export const ScheduleSellerExpired: React.FC<MpRouteComponentProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslationContext();
  const partyId = props.match.params.id;
  const requestNewDates = useMutation([CacheKey.FO_REQUEST_MORE_DATES, partyId], foClient.marktplaats.requestNewDates, {
    onSuccess: () => {
      trackAndTag({ event: "FO Interaction", type: "Seller requested new dates", data: "all expired", partyId });
      history.push(GenerateMpRoute({ id: partyId, page: { type: "thank_you", state: "SCHEDULE_SELLER_NEW_DATES" } }));
    },
  });
  return (
    <>
      <PageHead title={t((d) => d.fo.schedule_seller.expired.title)} />
      <MpContainer>{t((d) => d.fo.schedule_seller.expired.explain)}</MpContainer>
      <MpFooter>
        <MpContainer>
          <Button
            loading={requestNewDates.isLoading}
            className="w-full"
            buttonType="secondary"
            onClick={() => {
              requestNewDates.reset();
              requestNewDates.mutate({ partyId });
            }}
          >
            {t((d) => d.fo.schedule_seller.expired_request_more_dates)}
          </Button>
        </MpContainer>
      </MpFooter>
    </>
  );
};
