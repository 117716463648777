import { FoDate, FoParty, FoPartyIdParams } from "@brenger/api-client";
import { useDebounce } from "@brenger/react";
import { sortBy } from "lodash";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { CacheKey, foClient } from "../../utils";
import { useDraftTr } from "./useDraftTr";

interface UseSellerDates {
  /**
   * Selected date as string
   * yyyy-mm-dd
   */
  selected: string | null;
  /**
   * Date options
   */
  options: FoDate[];
  /**
   * Toggle date
   */
  toggleDate(d: string): void;
  /**
   * Only on initial
   */
  isLoading: boolean;
  /**
   * Everytime resources are fetched
   */
  isFetching: boolean;
  /**
   * When select mutation is loading
   */
  isSubmitting: boolean;
}

export const useSellerDates = ({ partyId }: FoPartyIdParams): UseSellerDates => {
  const draftTr = useDraftTr({ partyId });
  const party = draftTr.data?.party;
  /**
   * Three main api operations
   * - fetch available dates
   * - fetch prev selected
   * - setup mutation to update
   *
   * NOTE: Decided to exclude setup mutation for more dates. It is definitely a date operation,
   * but it is also a one off operation which can be easily include with the component that executes it.
   */

  const availableDates = useQuery(
    [CacheKey.FO_RETRIEVE_AVAILABLE_DATES, party, partyId],
    () =>
      foClient.marktplaats.retrieveAvailableDates({
        // Can be safely casted because is enabled dep
        party: party as FoParty,
        partyId,
      }),
    {
      enabled: !!party,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setOptions(sortBy(data.available_dates, "date"));
      },
    }
  );
  /**
   * NOTE: We do a fetch available dates earlier to figure out if need to show the expired dates page.
   * The cache is shared and we want to use it in the "options" state, this should be after useQuery:
   * useState initializes async, so you see some quick updates and the last one is a empty array.
   */
  const [options, setOptions] = React.useState<FoDate[]>(sortBy(availableDates.data?.available_dates, "date") || []);

  const selectedDates = useQuery(
    [CacheKey.FO_RETRIEVE_CONFIRMED_DATE, partyId],
    () =>
      foClient.marktplaats.retrieveConfirmedDate({
        partyId,
      }),
    {
      /**
       * Non paralel, because then we can verify if the option is still available
       */
      enabled: !!options.length,
    }
  );

  const update = useMutation([CacheKey.FO_CONFIRM_DATE, partyId], foClient.marktplaats.confirmDate, {
    onSuccess: () => selectedDates.remove(),
  });

  /**
   * This is state only exists so selecting is direct, value will be debounced to an endpoint API
   */
  const [selected, setSelected] = React.useState<string | null>(null);

  /**
   * When selected date changes, only set it as state if the date is still available.
   */
  React.useEffect(() => {
    if (!selectedDates.data?.date) return;
    const option = options.find((o) => o.date === selectedDates.data?.date && o.kind !== "UNAVAILABLE");
    if (!option) return;
    setSelected(selectedDates.data?.date);
  }, [selectedDates.data]);

  /**
   * We debounce the date, no need to flood the api when rage clicking
   */
  const debouncedDates = useDebounce(JSON.stringify(selected), 250);

  /**
   * Act on debounced value => mutate
   */
  React.useEffect(() => {
    if (!selected) return;
    update.reset();
    update.mutate({
      partyId,
      date: selected,
    });
  }, [debouncedDates]);

  /**
   * Select / deselect a date
   */
  const toggleDate = (d: string): void => {
    setSelected(selected === d ? null : d);
  };

  return {
    selected: selected || update.data?.date || null,
    options,
    toggleDate,
    // Wait for party, so queries can be executed above
    isLoading: !party || availableDates.isLoading || selectedDates.isLoading,
    isFetching: availableDates.isLoading || availableDates.isFetching || selectedDates.isFetching,
    isSubmitting: update.isLoading,
  };
};
