import { Button, InputText, Label, Spacer, Strong, useForm } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { Link, Redirect, useHistory } from "react-router-dom";

import { Page } from "../components";
import { useAuth, useTranslationContext } from "../hooks";
import { Routes, isUUID } from "../utils";

const Root: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const { t } = useTranslationContext();

  const form = useForm({
    initialState: { uuid: "" },
    validators: { uuid: (val) => !isUUID(val) },
  });

  if (auth.loading) {
    return <Page loading={true} />;
  }

  // When users goes to root page + is logged in, we redirect them to the transport list view.
  if (auth.user) {
    return <Redirect to={Routes.TRANSPORT_LIST} />;
  }

  return (
    <Page>
      <div className={cn("bg-gray-100", "p-8")}>
        <div className={cn("mx-auto", "max-w-md")}>
          <Label text={<Strong>{t((d) => d.tracking_number)}</Strong>}>
            <InputText
              placeholder="06cde129-dc09-4c39-912c-cbd62c9de49c"
              className={cn("w-full")}
              value={form.data.uuid.value}
              onChange={(uuid) => form.set({ uuid })}
            />
          </Label>
          <Spacer h={2} />
          <Button
            disabled={form.hasErrors}
            className={cn("w-full")}
            onClick={() => {
              history.push(`/${form.data.uuid.value}`);
            }}
          >
            {t((d) => d.track_cta)}
          </Button>
        </div>
      </div>
      <div className={cn("px-8", "py-4")}>
        <div className={cn("mx-auto", "max-w-md")}>
          <div className={cn("text-blue-600")}>
            <span>{t((d) => d.has_account)}</span>
            <Link to={Routes.LOGIN} className={cn("underline", "ml-1")}>
              {t((d) => d.login)}
            </Link>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Root;
