import { Button, Label, Spacer, StarRating, Strong, Textarea, useForm } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { Modal, ModalProps } from "../../../components";
import { useTranslationContext, useTransportContext } from "../../../hooks";
import { coreClient } from "../../../utils";
import { ReviewFirstPart, StepType } from "./ReviewModalRoot";

enum Field {
  PUNCTUALITY = "punctuation",
  COMMUNICATION = "communication",
  FRIENDLINESS = "friendliness",
  NOTE = "private_note",
}

const initialState = {
  [Field.PUNCTUALITY]: 0,
  [Field.COMMUNICATION]: 0,
  [Field.FRIENDLINESS]: 0,
  [Field.NOTE]: "",
};

const validators = {
  [Field.PUNCTUALITY]: (val: number) => !val,
  [Field.COMMUNICATION]: (val: number) => !val,
  [Field.FRIENDLINESS]: (val: number) => !val,
};

interface Props extends ModalProps {
  setReviewId: (step: string) => void;
  setStep: (step: StepType) => void;
  reviewFirstPart: ReviewFirstPart;
}

export const ReviewModalStep2: React.FC<Props> = (props) => {
  const { t } = useTranslationContext();

  const form = useForm({ initialState, validators });
  const { contactType, tr } = useTransportContext();

  const updateReview = useMutation(coreClient.reviews.create, {
    onSuccess: (createdReview) => {
      // set review id
      props.setReviewId(createdReview.id);
      // proceed to next review step
      props.setStep(3);
    },
  });

  return (
    <Modal {...props}>
      <h3>{t((d) => d.public_review_title)}</h3>
      <Spacer h={4} />
      <div>{t((d) => d.public_review_desc)}</div>
      <Spacer h={6} />
      {/* PUNCTUALITY */}
      <div>
        <h5>{t((d) => d.public_review_punctuality_title)}</h5>
        <div>{t((d) => d.public_review_punctuality_desc)}</div>
        <StarRating rating={form.data.punctuation.value} onChange={(punctuation) => form.set({ punctuation })} />
      </div>
      <Spacer h={4} />
      {/* COMMUNICATION */}
      <div>
        <h5>{t((d) => d.public_review_communication_title)}</h5>
        <div>{t((d) => d.public_review_communication_desc)}</div>
        <StarRating rating={form.data.communication.value} onChange={(communication) => form.set({ communication })} />
      </div>
      <Spacer h={4} />
      {/* FRIENDLINESS */}
      <div>
        <h5>{t((d) => d.public_review_friendliness_title)}</h5>
        <div>{t((d) => d.public_review_friendliness_desc)} </div>
        <StarRating rating={form.data.friendliness.value} onChange={(friendliness) => form.set({ friendliness })} />
      </div>
      <Spacer h={4} />
      {/* PRIVATE NOTE */}
      <Label
        text={
          <span className="br-text-lg sm:br-text-xl">
            <Strong>{t((d) => d.public_review_comment)}</Strong>
          </span>
        }
      >
        <Textarea
          rows={3}
          value={form.data.private_note.value}
          onChange={(private_note) => form.set({ private_note })}
        />
      </Label>
      <Spacer h={8} />
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-3", "mt-3")}>
        <Button buttonType="primary-outline" disabled={updateReview.isLoading} onClick={props.closeHandler}>
          {t((d) => d.no)}
        </Button>
        <Button
          disabled={form.hasErrors}
          loading={updateReview.isLoading}
          onClick={() => {
            updateReview.reset();
            updateReview.mutate({
              // coming from context
              customer: contactType === "customer" ? tr?.customer["@id"] : undefined,
              delivery: contactType === "delivery" ? tr?.deliveries[0]["@id"] : undefined,
              // coming from form state
              communication: form.data[Field.COMMUNICATION].value,
              friendliness: form.data[Field.FRIENDLINESS].value,
              punctuality: form.data[Field.PUNCTUALITY].value,
              body: form.data[Field.NOTE].value || "",
              // coming from prev step
              neat_vehicle: props.reviewFirstPart.neat_vehicle,
              moving_supplies: props.reviewFirstPart.moving_supplies,
            });
          }}
        >
          {t((d) => d.submit)}
        </Button>
      </div>
    </Modal>
  );
};
