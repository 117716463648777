import React from "react";
import { MpContainer } from "./Layout";

export const Progress: React.FC<{ current: number; total: number }> = ({ current, total }) => {
  const rightPos = 100 - (current / total) * 100;
  const marks = Array.from({ length: total - 1 });
  return (
    <MpContainer className="!pb-2">
      <div className="z-0 relative h-1" style={{ backgroundColor: "#CDE4FF", maxWidth: "108px" }}>
        <div
          className="bg-green-400 h-1 absolute top-0 left-0 transition-[right]"
          style={{ right: rightPos + "%" }}
        ></div>
        {marks.map(
          // eslint-disable-next-line @typescript-eslint/naming-convention
          (_, i) => {
            const pos = ((i + 1) / total) * 100;
            return (
              <div
                key={i}
                className="bg-white h-2 w-1 absolute top-0"
                style={{ left: pos + "%", marginLeft: "-.25rem" }}
              ></div>
            );
          }
        )}
      </div>
    </MpContainer>
  );
};
