import { FoContact, FoParty, FoPartyIdParams } from "@brenger/api-client";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { CacheKey, foClient } from "../../utils";
import { GenerateMpRoute } from "../routes";
import { useDraftTr } from "./useDraftTr";

export interface UseContact {
  /**
   * FIX ME: Partial service field comes from not knowing what defaults we save
   */
  data?: FoContact;
  update(c: FoContact): void;
  /**
   * Only on initial
   */
  isLoading: boolean;
  /**
   * Everytime resources are fetched
   */
  isFetching: boolean;
  /**
   * When select service is loading
   */
  isSubmitting: boolean;
}

export const useContact = ({ partyId }: FoPartyIdParams): UseContact => {
  const draftTr = useDraftTr({ partyId });
  const party = draftTr.data?.party;
  const history = useHistory();
  const queryClient = useQueryClient();
  /**
   * Two main operations
   * - fetch contact details
   * - setup mutation to update contact
   */
  const contact = useQuery(
    [CacheKey.FO_RETRIEVE_CONTACT, party, partyId],
    () =>
      foClient.marktplaats.retrieveContact({
        // Can be safely casted because is enabled dep
        party: party as FoParty,
        partyId,
        // mock: FoContactMock,
      }),
    {
      enabled: !!party,
    }
  );

  const updateContact = useMutation([CacheKey.FO_UPDATE_CONTACT, party, partyId], foClient.marktplaats.updateContact, {
    onSuccess: () => {
      queryClient.removeQueries([CacheKey.FO_RETRIEVE_CONTACT]);
      history.push(
        GenerateMpRoute({
          id: partyId,
          page: { type: "flows", state: party === "buyer" ? "SCHEDULE_BUYER" : "SCHEDULE_SELLER", progress: 3 },
        })
      );
    },
  });

  /**
   * We need to merge all contact details:
   * - first you have potentially the most out of date
   * - Latest the most "optimistic", basically the ui state, that potentially still needs to be synced
   */
  const mergedContact = contact.data
    ? {
        ...(contact.data || {}),
        ...(updateContact.data || {}),
      }
    : undefined;

  const update = async (c: FoContact): Promise<void> => {
    if (!party) return;
    updateContact.reset();
    await updateContact.mutate({ party, partyId, ...c });
  };

  return {
    data: mergedContact,
    update,
    // Wait for party, so queries can be executed above
    isLoading: !party || contact.isLoading,
    isFetching: contact.isFetching,
    isSubmitting: updateContact.isLoading,
  };
};
